// cSpell:ignore Swipeable
import { makeStyles, SwipeableDrawer, Menu } from "@material-ui/core";
import { Fragment, ReactNode, useState } from "react";
import { useIsMobile } from "../../../../hooks/ui";
import { useSelector } from "react-redux";
import { getIsLoadingDownload } from "../../../../state";
import classNames from "classnames";
import { CompanyDonationDateFilteredDownload } from "./CompanyDonationDateFilteredDownload";
const MENU_WIDTH = 200;
const BUTTON_WIDTH = 160;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
  refreshData: () => void;
};
export function CompanyDonationActionsMenu(props: Props) {
  const { isOpen, onClose, anchorEl } = props;
  const classes = styles();
  const [showSales, setShowSales] = useState(false);
  const [showShipstation, setShowShipstation] = useState(false);
  const isLoading = useSelector(getIsLoadingDownload);

  return (
    <Fragment>
      <ResponsiveMenu isOpen={isOpen} onClose={onClose} anchorEl={anchorEl}>
        {/* `loading` styles are not used now because all action open modals, but keep in case we add some */}
        <div
          className={classNames(
            classes.container,
            isLoading && classes.loading,
          )}
        >
          {isLoading && <div className={classes.loadingOverlay} />}

          <div
            className={classes.row}
            onClick={() => {
              setShowSales(true);
              onClose();
            }}
          >
            Download sales
          </div>
          <div
            className={classes.row}
            onClick={() => {
              setShowShipstation(true);
              onClose();
            }}
          >
            STH ShipStation
          </div>
        </div>
      </ResponsiveMenu>

      {showSales && (
        <CompanyDonationDateFilteredDownload
          onClose={() => setShowSales(false)}
        />
      )}
      {showShipstation && (
        <CompanyDonationDateFilteredDownload
          onClose={() => setShowShipstation(false)}
          isStsShipstation
        />
      )}
    </Fragment>
  );
}

type ResponsiveProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
};
export function ResponsiveMenu({
  children,
  isOpen,
  onClose,
  anchorEl,
}: ResponsiveProps) {
  const classes = styles();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={isOpen}
        onClose={onClose}
        onOpen={() => {}}
        classes={{ paper: classes.drawer }}
        transitionDuration={500}
        anchor="bottom"
      >
        <div className={classes.drawerWrapper}>{children}</div>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuPaper }}
        className={classes.menu}
        open={isOpen}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: MENU_WIDTH - BUTTON_WIDTH,
        }}
      >
        {children}
      </Menu>
    );
  }
}

const styles = makeStyles(theme => ({
  drawer: {
    top: "auto",
    maxHeight: "unset",
  },
  drawerWrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
  },
  menu: {
    padding: 0,
  },
  menuPaper: {
    borderRadius: 8,
  },
  container: {
    width: MENU_WIDTH,
    position: "relative",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      width: "100%",
    },
  },
  row: {
    width: "100%",
    height: 36,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    fontSize: 16,
    letterSpacing: 0.15,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
  },
  loading: {
    opacity: 0.4,
  },
  loadingOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 999,
  },
}));
