import { getUserTimezone, store } from "../../../lib";
import { setToast } from "../../state";
import {
  fetchAndDownloadCsv,
  fetchAndDownloadPdf,
  fetchAndDownloadXml,
  fetchAndDownloadExcel,
} from "../fetchAndDownloadMethods";

export async function STHShipstationDownload(campaignId: any, CB?: () => void) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/reports/sth-shipstation-report?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(url, "STH ShipStation Report", CB);
}

export async function STSShipstationDownload(campaignId: any, CB?: () => void) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/reports/shipstation-ship-to-school?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(url, "STS ShipStation Report", CB);
}

export async function pullSheetDownload(campaignId: any, CB?: () => void) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/pdf/ship-to-school-pull-sheet?campaign_id=${campaignId}`;
  await fetchAndDownloadPdf(url, "ship-to-school-pull-sheet", CB);
}

export async function studentLabelsDownload(campaignId: any, CB?: () => void) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/pdf/student-labels?campaign_id=${campaignId}`;
  await fetchAndDownloadPdf(url, "student-labels", CB);
}

export async function studentPickSlipsDownload(
  campaignId: any,
  excludeBrochures: boolean,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/pdf/student-pick-slips?campaign_id=${campaignId}&exclude_brochures=${excludeBrochures}`;
  await fetchAndDownloadPdf(url, "student-pick-slips", CB);
}

export async function mrsFieldsOrderXmlDownload(
  campaignId: any,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/pdf/mrs-fields-order-aggregates-xml?campaign_id=${campaignId}`;
  await fetchAndDownloadXml(url, "campaign-orders", CB);
}

export async function dinnerReportDownload(campaignId: any, CB?: () => void) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const timezone = getUserTimezone();
  const url = `/reports/dinner-report?campaign_id=${campaignId}&timezone=${timezone}`;
  await fetchAndDownloadCsv(url, "Dinner Reservations", CB);
}

export async function donationDedicationsDownload(
  campaignId: any,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/campaign_donation_group_item_donations/campaign_donations_export?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(url, "Campaign Donations With Dedications", CB);
}

export async function STSStudentProductsDownload(
  campaignId: any,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/reports/ship-to-school-student-products?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(url, "Ship To School Student Products", CB);
}

export async function neighborsReportDownload(
  campaignId: any,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/reports/neighbors?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(url, "Neighbors", CB);
}

export async function personalizationReportDownload(
  campaignId: any,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/reports/personalization-export?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(url, "Personalization Report", CB);
}

export async function topCampaignProductsDownload(
  campaignId: any,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/xlsx_reports/big_kahuna_top_campaign_products?campaign_id=${campaignId}`;
  await fetchAndDownloadExcel(url, "TOP SELLER REPORT", CB);
}
