import { ReactAdminTabbedShowNewAppWrapper } from "../../../components/general/ReactAdminTabbedShowNewAppWrapper";
import { Donations } from "./Donations";

export function AdminCampaignDonationList() {
  return (
    <ReactAdminTabbedShowNewAppWrapper>
      <Donations forAdminView />
    </ReactAdminTabbedShowNewAppWrapper>
  );
}
