import { store } from "../../../lib";
import { setToast } from "../../state";
import {
  fetchAndDownloadCsv,
  fetchAndDownloadPdf,
} from "../fetchAndDownloadMethods";

export async function schoolathonTShirtDownload(
  campaignId: any,
  orgName: string,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/donation_products/schoolathon-t-shirt-pdf?campaign_id=${campaignId}`;
  await fetchAndDownloadPdf(
    url,
    `${orgName} - Campaign ${campaignId} T-Shirt-Report`,
    CB,
  );
}

export async function schoolathonTShirtPrizePicksDownload(
  campaignId: any,
  orgName: string,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/volunteer_campaign_prize_picks/schoolathon_t_shirt_picks
?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(
    url,
    `${orgName} - Campaign ${campaignId} T-Shirt Selections`,
    CB,
  );
}
