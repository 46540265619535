import { useEffect, useState, useRef, ChangeEvent, Fragment } from "react";
import {
  makeStyles,
  Button,
  IconButton,
  TextField as MuiTextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Form, Field } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import { APIAuthClient, formatMoney } from "../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../types";
import { getCampaignId, setToast } from "../../state";
import { ResponsiveModal } from "../ui/ResponsiveModal";
import { useDrawerTransition, useIsMobile } from "../../hooks/ui";
import { CircularLoader } from "../ui/CircularLoader";

type Props = {
  callBack: (actionBoolean?: boolean) => void;
  fromAddOrderForm?: boolean;
  volunteer_id: any;
};

export function PrizeModal({
  callBack,
  fromAddOrderForm,
  volunteer_id,
}: Props) {
  const campaign_id = useSelector(getCampaignId);
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const addOrderFormRef = useRef(false);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [campaignPrizes, setCampaignPrizes] = useState([]);
  const [activeProducts, setActiveProducts] = useState<GenericObject[]>([]);
  const [data, setData] = useState<GenericObject>({});
  const { isOpen, onClose: _onClose } = useDrawerTransition(() => {});

  useEffect(() => {
    if (campaign_id) {
      fetchVolunteerPrizeData();
      fetchCampaignPrizes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const onClose = (refresh = false) => {
    _onClose();
    if (fromAddOrderForm) callBack(addOrderFormRef.current);
    else callBack(refresh);
  };

  const fetchCampaignPrizes = async () => {
    const url = `/campaign_prize_programs/product_autocomplete?campaign_id=${campaign_id}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setCampaignPrizes(data);
  };

  const fetchVolunteerPrizeData = async () => {
    const url = `/volunteer_campaign_prizes/prize_data?campaign_id=${campaign_id}&volunteer_id=${volunteer_id}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setData(data);
    setInitialValues({ ...data });
  };

  const onSubmit = async ({ prizes = [] }, { getState }) => {
    if (activeProducts.length) {
      return dispatch(
        setToast("You have selected Prizes which weren't added yet!"),
      );
    }
    const { dirty } = getState();
    if (!dirty) return dispatch(setToast("There are no changes to save!"));

    const formattedPrizes = prizes.map(({ qty, product_id }) => {
      return { product_id, qty: Number(qty) };
    });

    const data = { campaign_id, volunteer_id, prizes: formattedPrizes };
    const url = "/volunteer_campaign_prizes/update_prizes";
    const res = await APIAuthClient.put<any, APIRes>(url, data);
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose(true);
  };

  const handleQtyChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    input: any,
  ) => {
    const value = e.target.value;
    if (!value || (value && /^[1-9][0-9]*$/.test(value))) {
      input.onChange(value);
    }
  };

  const addProducts = (fields: any) => {
    if (!fields || !Array.isArray(fields.value)) {
      return dispatch(setToast("Please refresh your page and try again"));
    }
    let hasDuplicates = false;
    const formatted = activeProducts.map(p => {
      const product_id = p.product_id;
      const exists = fields.value.some((e: any) => e.product_id === product_id);
      if (exists) hasDuplicates = true;
      return { ...p, qty: 1, fulfilled_qty: 0 };
    });

    if (hasDuplicates) {
      return dispatch(setToast("This prize is already added, adjust the qty"));
    }
    formatted.forEach(e => fields.push(e));
    setActiveProducts([]);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div>
            <div>Assign prizes</div>
            <div className={classes.name}>
              {data.first_name || ""} {data.last_name || ""}
            </div>
          </div>
          <IconButton size="small" onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </div>

        <CircularLoader show={!data.id} customClass={classes.loader} />

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <form
                className={classnames(
                  classes.form,
                  !data.id && classes.hideForm,
                )}
                onSubmit={handleSubmit}
              >
                {!isMobile && (
                  <div className={classes.infoContainer}>
                    <div className={classes.infoRow}>
                      <div className={classes.infoSection}>
                        <div className={classes.infoLabel}>Student ID</div>
                        <div className={classes.infoValue}>{data.id}</div>
                      </div>
                      <div className={classes.infoSection}>
                        <div className={classes.rightInfoLabel}>
                          Number of sales
                        </div>
                        <div className={classes.infoValue}>
                          {data.NUMBER_OF_SALES}
                        </div>
                      </div>
                    </div>

                    <div className={classes.infoRow}>
                      <div className={classes.infoSection}>
                        <div className={classes.infoLabel}>Amount raised</div>
                        <div className={classes.infoValue}>
                          {formatMoney(data.DOLLAR_AMOUNT_RAISED)}
                        </div>
                      </div>
                      <div className={classes.infoSection}>
                        <div className={classes.rightInfoLabel}>Contacts</div>
                        <div className={classes.infoValue}>
                          {data.NUMBER_OF_CONTACTS}
                        </div>
                      </div>
                    </div>

                    <div className={classes.infoRow}>
                      <div className={classes.infoSection}>
                        <div className={classes.infoLabel}>Items sold</div>
                        <div className={classes.infoValue}>
                          {data.NUMBER_OF_ITEMS_SOLD}
                        </div>
                      </div>
                      <div className={classes.infoSection}>
                        <div className={classes.rightInfoLabel}>
                          Contacts with sent emails
                        </div>
                        <div className={classes.infoValue}>
                          {data.NUMBER_OF_CONTACTS_WITH_SENT_EMAILS}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isMobile && (
                  <div className={classes.infoContainer}>
                    <div className={classes.infoSection}>
                      <div className={classes.infoLabel}>Student ID</div>
                      <div className={classes.infoValue}>{data.id}</div>
                    </div>
                    <div className={classes.infoSection}>
                      <div className={classes.infoLabel}>Amount raised</div>
                      <div className={classes.infoValue}>
                        {formatMoney(data.DOLLAR_AMOUNT_RAISED)}
                      </div>
                    </div>
                    <div className={classes.infoSection}>
                      <div className={classes.infoLabel}>Items sold</div>
                      <div className={classes.infoValue}>
                        {data.NUMBER_OF_ITEMS_SOLD}
                      </div>
                    </div>
                    <div className={classes.infoSection}>
                      <div className={classes.rightInfoLabel}>
                        Number of sales
                      </div>
                      <div className={classes.infoValue}>
                        {data.NUMBER_OF_SALES}
                      </div>
                    </div>
                    <div className={classes.infoSection}>
                      <div className={classes.rightInfoLabel}>Contacts</div>
                      <div className={classes.infoValue}>
                        {data.NUMBER_OF_CONTACTS}
                      </div>
                    </div>
                    <div className={classes.infoSection}>
                      <div className={classes.rightInfoLabel}>
                        Contacts with sent emails
                      </div>
                      <div className={classes.infoValue}>
                        {data.NUMBER_OF_CONTACTS_WITH_SENT_EMAILS}
                      </div>
                    </div>
                  </div>
                )}

                {/* ASSIGN PRIZES */}
                <div className={classes.subheader}>Assign prizes</div>
                <FieldArray<GenericObject> name="prizes">
                  {({ fields }) => {
                    const itemsAdded = Boolean(fields.length);
                    return (
                      <Fragment>
                        {(itemsAdded || !isMobile) && (
                          <div className={classes.products}>
                            {!isMobile && (
                              <div
                                className={classnames(
                                  classes.row,
                                  classes.headerRow,
                                )}
                              >
                                <div>Image</div>
                                <div>Sku</div>
                                <div>Name</div>
                                <div>Quantity</div>
                                <div>Fulfilled quantity</div>
                                <div />
                              </div>
                            )}

                            {fields.map((row, index) => {
                              const length = fields.length ? fields.length : 0;
                              const lastRow = index === length - 1;
                              const {
                                sku,
                                product_name,
                                fulfilled_qty,
                                thumbnail_image,
                              } = fields.value[index];
                              return (
                                <div key={index} className={classes.row}>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Image
                                      </div>
                                    )}
                                    <div className={classes.imgAndDelete}>
                                      <img
                                        className={classes.thumbnail}
                                        alt="product"
                                        src={thumbnail_image as string}
                                      />
                                      {isMobile && (
                                        <IconButton
                                          onClick={() => fields.remove(index)}
                                          size="small"
                                        >
                                          <DeleteIcon color="primary" />
                                        </IconButton>
                                      )}
                                    </div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Sku
                                      </div>
                                    )}
                                    <div>{sku}</div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Name
                                      </div>
                                    )}
                                    <div>{product_name}</div>
                                  </div>
                                  <div className={classes.productLineItem}>
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Quantity
                                      </div>
                                    )}
                                    <div>
                                      <Field
                                        variant="standard"
                                        type="number"
                                        name={`${row}.qty`}
                                        render={({
                                          input,
                                          meta: _rm,
                                          ...rest
                                        }) => {
                                          return (
                                            <MuiTextField
                                              {...rest}
                                              {...input}
                                              value={input.value}
                                              onFocus={e => e.target.select()}
                                              onChange={e =>
                                                handleQtyChange(e, input)
                                              }
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={classnames(
                                      classes.productLineItem,
                                      classes.lastLineItem,
                                      lastRow && classes.lastRow,
                                    )}
                                  >
                                    {isMobile && (
                                      <div className={classes.lineItemLabel}>
                                        Fulfilled quantity
                                      </div>
                                    )}
                                    <div>{fulfilled_qty}</div>
                                  </div>

                                  {!isMobile && (
                                    <div>
                                      <IconButton
                                        onClick={() => fields.remove(index)}
                                        size="small"
                                      >
                                        <DeleteIcon color="primary" />
                                      </IconButton>
                                    </div>
                                  )}
                                </div>
                              );
                            })}

                            {!isMobile && !itemsAdded && (
                              <div className={classes.noItems}>
                                No prizes added yet. Add prizes below.
                              </div>
                            )}
                          </div>
                        )}

                        <div className={classes.selectorContainer}>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            value={activeProducts as any}
                            className={classes.selector}
                            onChange={(_, newValue) => {
                              setActiveProducts(newValue);
                            }}
                            getOptionLabel={({ product_name, sku }) =>
                              `${product_name} | ${sku}`
                            }
                            options={campaignPrizes}
                            renderInput={params => (
                              <MuiTextField
                                {...params}
                                label=""
                                autoFocus
                                placeholder="Enter or select prizes to add"
                              />
                            )}
                          />

                          <div className={classes.qty}>
                            <Button
                              color="primary"
                              disabled={!activeProducts.length}
                              onClick={() => addProducts(fields)}
                              className={classes.add}
                              size="large"
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </Fragment>
                    );
                  }}
                </FieldArray>

                {/* SELECTED PRIZES */}
                {data.allow_student_picks && (
                  <Fragment>
                    <div className={classes.subheader}>Selected prizes</div>
                    <FieldArray<GenericObject> name="prizePicks">
                      {({ fields }) => {
                        fields.value;
                        const itemsAdded = Boolean(fields.length);
                        return (
                          <Fragment>
                            {(itemsAdded || !isMobile) && (
                              <div className={classes.products}>
                                {!isMobile && (
                                  <div
                                    className={classnames(
                                      classes.row,
                                      classes.selectedRow,
                                      classes.headerRow,
                                    )}
                                  >
                                    <div>Tier</div>
                                    <div>Image</div>
                                    <div>Sku</div>
                                    <div>ID</div>
                                    <div>Name</div>
                                  </div>
                                )}

                                {fields.map((_row, index) => {
                                  const length = fields.length
                                    ? fields.length
                                    : 0;
                                  const lastRow = index === length - 1;
                                  const {
                                    product_id,
                                    product_name,
                                    sku,
                                    thumbnail_image,
                                    tier_name,
                                  } = fields.value[index];
                                  return (
                                    <div
                                      key={index}
                                      className={classnames(
                                        classes.row,
                                        classes.selectedRow,
                                      )}
                                    >
                                      <div className={classes.productLineItem}>
                                        {isMobile && (
                                          <div
                                            className={classes.lineItemLabel}
                                          >
                                            Tier
                                          </div>
                                        )}
                                        <div>{tier_name}</div>
                                      </div>
                                      <div className={classes.productLineItem}>
                                        {isMobile && (
                                          <div
                                            className={classes.lineItemLabel}
                                          >
                                            Image
                                          </div>
                                        )}
                                        <img
                                          className={classes.thumbnail}
                                          alt="product"
                                          src={thumbnail_image as string}
                                        />
                                      </div>
                                      <div className={classes.productLineItem}>
                                        {isMobile && (
                                          <div
                                            className={classes.lineItemLabel}
                                          >
                                            Sku
                                          </div>
                                        )}
                                        <div>{sku}</div>
                                      </div>
                                      <div className={classes.productLineItem}>
                                        {isMobile && (
                                          <div
                                            className={classes.lineItemLabel}
                                          >
                                            Student ID
                                          </div>
                                        )}
                                        <div>{product_id}</div>
                                      </div>
                                      <div
                                        className={classnames(
                                          classes.productLineItem,
                                          classes.lastLineItem,
                                          lastRow && classes.lastRow,
                                        )}
                                      >
                                        {isMobile && (
                                          <div
                                            className={classes.lineItemLabel}
                                          >
                                            Name
                                          </div>
                                        )}
                                        <div>{product_name}</div>
                                      </div>
                                    </div>
                                  );
                                })}

                                {!isMobile && !itemsAdded && (
                                  <div className={classes.noItems}>
                                    No prizes selected.
                                  </div>
                                )}
                              </div>
                            )}
                            {!itemsAdded && isMobile && (
                              <div className={classes.noSelected}>
                                No prizes selected
                              </div>
                            )}
                          </Fragment>
                        );
                      }}
                    </FieldArray>
                  </Fragment>
                )}

                <div className={classes.saveButtons}>
                  <Button
                    type="button"
                    disabled={submitting}
                    className={classes.btn}
                    color="primary"
                    onClick={() => {
                      addOrderFormRef.current = false;
                      form.submit();
                    }}
                  >
                    Save
                  </Button>
                  {fromAddOrderForm && (
                    <Button
                      type="button"
                      disabled={submitting}
                      className={classes.startNewBtn}
                      color="secondary"
                      onClick={() => {
                        addOrderFormRef.current = true;
                        form.submit();
                      }}
                    >
                      {`Save & enter new order form`}
                    </Button>
                  )}
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 800,
    maxWidth: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flex: 1,
    },
  },
  top: {
    position: "sticky",
    borderBottom: "1px solid #DBDEEE",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 999,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 12px 16px 24px",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  name: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 8,
  },
  infoContainer: {
    paddingBottom: 18,
  },
  infoRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  infoSection: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    minWidth: "50%",
    maxWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      width: "100%",
      minWidth: "100%",
      maxWidth: "100%",
    },
  },
  infoLabel: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: 0.34,
    width: 152,
    minWidth: 152,
    [theme.breakpoints.down("sm")]: {
      width: 230,
      minWidth: 230,
    },
  },
  rightInfoLabel: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: 0.34,
    width: 230,
    minWidth: 230,
  },
  infoValue: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: 0.17,
  },
  loader: {
    marginTop: 50,
    marginBottom: 100,
  },
  form: {
    flex: 1,
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
      paddingBottom: 0,
    },
  },
  hideForm: { display: "none" },
  subheader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    color: theme.palette.primary.main,
    marginTop: 6,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  products: {
    width: "100%",
    border: "1px solid #C9CDDE",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "68px .6fr 1fr 96px 88px 54px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0 8px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    borderTop: "1px solid #C9CDDE",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "18px",
      height: "unset",
      display: "block",
      padding: "8px 0 0 0",
      borderTop: "none",
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  selectedRow: {
    gridTemplateColumns: ".4fr 72px .6fr 90px 1fr",
  },
  headerRow: {
    borderTop: "none",
    minHeight: 56,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  productLineItem: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      minHeight: 32,
      padding: "8px 16px",
      borderBottom: "1px solid #EAEBF3",
    },
  },
  lastLineItem: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 16,
      borderBottom: "1px solid #C9CDDE",
    },
  },
  lastRow: {
    borderBottom: "none !important",
  },
  lineItemLabel: {
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.15,
    width: 85,
    minWidth: 85,
  },
  imgAndDelete: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  selectorContainer: {
    width: "100%",
    margin: "24px 0 32px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: 0,
      paddingBottom: 16,
      paddingTop: 8,
    },
  },
  selector: {
    width: "calc(100% - 106px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  qty: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  add: {
    width: 90,
    height: 40,
    borderRadius: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  thumbnail: {
    maxHeight: 36,
    maxWidth: 36,
  },
  noItems: {
    height: 52,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    color: theme.palette.text.secondary2,
    borderTop: "1px solid #C9CDDE",
  },
  noSelected: {
    fontSize: 14,
    letterSpacing: 0.13,
    color: theme.palette.text.secondary2,
  },
  saveButtons: {
    flex: 1,
    marginTop: 40,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 24,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      position: "sticky",
      bottom: 0,
      backgroundColor: "#FFFFFF",
      zIndex: 999,
      padding: "16px 0",
      marginTop: 18,
    },
  },
  btn: {
    minWidth: 120,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  startNewBtn: {
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 16,
      width: "100%",
    },
  },
}));
