import { useEffect, useState, useRef } from "react";
import { useNotify, Notification, useRedirect } from "react-admin";
import {
  Card,
  CardContent,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";
import {
  APIClient,
  generateAuthHeader,
  campaignTypeIds,
  isProductCampaign,
} from "../../lib";
import queryString from "query-string";
import { CampaignForm } from "./CampaignForm";
import { styles } from "./Campaign.styles";

export default function CampaignCreate(props) {
  const location = props.location;
  const classes = styles();
  const notify = useNotify();
  const redirect = useRedirect();
  const initialValues = useRef({});
  const [initialValuesState, setInitialValuesState] = useState({});
  const [defaultData, setDefaultData] = useState({});
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [campaignTypeId, setCampaignTypeId] = useState("");
  const [productSuppliers, setProductSuppliers] = useState([]);
  const [productSupplierId, setProductSupplierId] = useState("");
  const [productGroups, setProductGroups] = useState({});
  const [productGroupId, setProductGroupId] = useState("");
  const [prizePrograms, setPrizePrograms] = useState([]);
  const [prizeProgramId, setPrizeProgramId] = useState("");
  const [donationGroups, setDonationGroups] = useState([]);
  const [donationGroupId, setDonationGroupId] = useState("");
  const [materialBundles, setMaterialBundles] = useState([]);
  const [materialBundleId, setMaterialBundleId] = useState("");
  const [communicationBundles, setCommunicationBundles] = useState([]);
  const [communicationBundleId, setCommunicationBundleId] = useState("");
  const [showStartButton, setShowStartButton] = useState(false);
  const [showCampaignForm, setShowCampaignForm] = useState(false);
  const isRaffle = campaignTypeId === campaignTypeIds.RAFFLE;
  const isMatching = campaignTypeId === campaignTypeIds.MATCH;
  const isDonation = campaignTypeId === campaignTypeIds.DONATION;
  const isProduct = campaignTypeId === campaignTypeIds.PRODUCT;
  const { org_id: queryOrgId, campaign_reservation_id } = queryString.parse(
    location.search,
  );

  useEffect(() => window.scrollTo(0, 0));

  useEffect(() => {
    const org_id = queryOrgId
      ? parseInt(queryOrgId)
      : location.state
      ? parseInt(location.state.org_id)
      : "";

    if (!org_id) {
      return notify(
        "we can't find your Organization ID, please start again",
        "warning",
      );
    }

    changeInitialValues({
      org_id,
      campaign_reservation_id: campaign_reservation_id
        ? Number(campaign_reservation_id)
        : null,
    });
    if (campaign_reservation_id) {
      fetchCampaignReservation(campaign_reservation_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/campaign_types/company_authorized", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      const {
        campaignTypes,
        productSuppliersWithPGs,
        productGroups,
        donationGroups,
        prizePrograms,
        materialBundles: _MBS,
        communicationBundles: _CBS,
      } = data;
      setCampaignTypes(campaignTypes);
      setProductSuppliers(productSuppliersWithPGs);
      setProductGroups(productGroups);
      setDonationGroups(donationGroups);
      setPrizePrograms(prizePrograms);
      setMaterialBundles(_MBS);
      if (_MBS.length === 1) {
        setMaterialBundleId(_MBS[0].id);
      }
      setCommunicationBundles(_CBS);
      if (_CBS.length === 1) {
        setCommunicationBundleId(_CBS[0].id);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCampaignReservation = async id => {
    const res = await APIClient.get(`/campaign_reservations/${id}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    const {
      campaign_type_id,
      product_supplier_id,
      supplier_product_group_id,
    } = data;
    const isProduct = isProductCampaign(campaign_type_id);
    if (campaign_type_id) handleTypeChange(undefined, campaign_type_id);
    if (campaign_type_id && isProduct && product_supplier_id) {
      handleSupplierChange(undefined, product_supplier_id);
    }
    if (
      campaign_type_id &&
      isProduct &&
      product_supplier_id &&
      supplier_product_group_id
    ) {
      handleGroupChange(undefined, supplier_product_group_id);
    }
  };

  const handleTypeChange = (e, val) => {
    const typeId = val ? val : e.target.value;
    setProductSupplierId("");
    setProductGroupId("");
    setDonationGroupId("");
    setCampaignTypeId(typeId);
    if (typeId !== campaignTypeIds.PRODUCT) {
      setShowStartButton(true);
    } else {
      setShowStartButton(false);
    }
  };

  const handleSupplierChange = (e, val) => {
    const supplierId = val ? val : e.target.value;
    setProductSupplierId(supplierId);
    setShowStartButton(false);
    setProductGroupId("");
  };

  const handleGroupChange = (e, val) => {
    const groupId = val ? val : e.target.value;
    setProductGroupId(groupId);
    setShowStartButton(true);
  };

  const handleStartClick = () => {
    fetchDefaultData();
    changeInitialValues({
      campaign_type_id: campaignTypeId,
      product_supplier_id: productSupplierId ? productSupplierId : null,
      supplier_product_group_id: productGroupId ? productGroupId : null,
      donation_group_id: donationGroupId ? donationGroupId : null,
      prize_program_id: prizeProgramId ? prizeProgramId : null,
      material_bundle_id: materialBundleId ? materialBundleId : null,
      communication_bundle_id: communicationBundleId
        ? communicationBundleId
        : null,
    });
  };

  const fetchDefaultData = async () => {
    const orgId = initialValues.current.org_id;
    const CRId = campaign_reservation_id ? campaign_reservation_id : "";
    const res = await APIClient.get(
      `/campaign_defaults/company_default?org_id=${orgId}&campaign_type_id=${campaignTypeId}&supplier_id=${productSupplierId}&supplier_product_group_id=${productGroupId}&donation_group_id=${donationGroupId}&campaign_reservation_id=${CRId}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    changeInitialValues(getInitialValues(data));
    setDefaultData(data);
    setShowCampaignForm(true);
  };

  const changeInitialValues = newData => {
    initialValues.current = { ...initialValues.current, ...newData };
    setInitialValuesState(initialValues.current);
  };

  const formProps = {
    ...props,
    title: "Create Campaign",
    onSuccess: ({ data }) => {
      notify("Campaign created successfully");
      redirect("show", "/campaigns", data.id);
    },
  };

  if (showCampaignForm) {
    return (
      <CampaignForm
        formProps={formProps}
        defaultData={defaultData}
        initialValues={initialValuesState}
        isDonation={isDonation}
        isMatching={isMatching}
        isRaffle={isRaffle}
        isProduct={isProduct}
      />
    );
  }

  return (
    <Card elevation={3} className={classes.typeSelectCard}>
      <CardContent>
        <TextField
          className={classes.selectInput}
          select
          label="Select a campaign type"
          value={campaignTypes.length ? campaignTypeId : ""}
          onChange={e => handleTypeChange(e)}
        >
          {campaignTypes.map(ct => (
            <MenuItem key={ct.id} value={ct.id}>
              {ct.name}
            </MenuItem>
          ))}
        </TextField>

        {campaignTypeId === campaignTypeIds.DONATION &&
          donationGroups.length > 0 && (
            <div className={classes.selectWrapper}>
              <TextField
                className={classes.selectInput}
                select
                label="Select a Donation Group (optional)"
                value={donationGroupId}
                onChange={e => setDonationGroupId(e.target.value)}
              >
                <MenuItem value="">No Group</MenuItem>
                {donationGroups.map(dg => (
                  <MenuItem key={dg.id} value={dg.id}>
                    {dg.group_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}

        {campaignTypeId === campaignTypeIds.PRODUCT && (
          <div className={classes.selectWrapper}>
            <TextField
              className={classes.selectInput}
              select
              label="Select a Product Supplier"
              value={productSuppliers.length ? productSupplierId : ""}
              onChange={e => handleSupplierChange(e)}
            >
              {productSuppliers.map(ps => (
                <MenuItem key={ps.id} value={ps.id}>
                  {ps.supplier_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        {productSupplierId && (
          <div className={classes.selectWrapper}>
            <TextField
              className={classes.selectInput}
              select
              label="Select a Product Group"
              value={productGroups[productSupplierId] ? productGroupId : ""}
              onChange={e => handleGroupChange(e)}
            >
              {(productGroups[productSupplierId] || []).map(pg => (
                <MenuItem key={pg.id} value={pg.id}>
                  {pg.group_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        {showStartButton && prizePrograms.length > 0 && (
          <div className={classes.selectWrapper}>
            <TextField
              className={classes.selectInput}
              select
              label="Select a Prize Program (optional)"
              value={prizeProgramId}
              onChange={e => setPrizeProgramId(e.target.value)}
            >
              <MenuItem value="">No Prize Program</MenuItem>
              {prizePrograms.map(({ id, program_name }) => (
                <MenuItem key={id} value={id}>
                  {program_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        {showStartButton && communicationBundles.length > 0 && (
          <div className={classes.selectWrapper}>
            <TextField
              className={classes.selectInput}
              select
              label="Select a Communication Bundle (optional)"
              value={communicationBundleId}
              onChange={e => setCommunicationBundleId(e.target.value)}
            >
              <MenuItem value="">No Communication Bundle</MenuItem>
              {communicationBundles.map(({ id, bundle_name }) => (
                <MenuItem key={id} value={id}>
                  {bundle_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        {showStartButton && materialBundles.length > 0 && (
          <div className={classes.selectWrapper}>
            <TextField
              className={classes.selectInput}
              select
              label="Select a Material Bundle (optional)"
              value={materialBundleId}
              onChange={e => setMaterialBundleId(e.target.value)}
            >
              <MenuItem value="">No Material Bundle</MenuItem>
              {materialBundles.map(({ id, bundle_name }) => (
                <MenuItem key={id} value={id}>
                  {bundle_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}

        {showStartButton && (
          <div className={classes.startButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartClick}
            >
              start
            </Button>
          </div>
        )}
      </CardContent>
      <Notification />
    </Card>
  );
}

function getInitialValues(data) {
  const fields = Object.keys(data)
    .filter(k => {
      if (k.includes("_action")) return false;

      const actionKey = `${k}_action`;
      if (data[actionKey] === "remove") return false;
      return true;
    })
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});
  return fields;
}
