import { Fragment, useState } from "react";
import { format } from "date-fns";
import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import DonationTagIcon from "@material-ui/icons/ReportProblemOutlined";
import { GenericObject, ListProps } from "../../../../types";
import { formatMoney } from "../../../../lib";
import { useIsDesktop, useIsMobile } from "../../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../../components";
import { ListPagination } from "../../../../components/list/ListPagination";
import { DonationDetails } from "../../components/DonationDetails";
const MIN_WIDTH = 1400;

type Props = { listProps: ListProps };
export function CompanyDonationRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Order ID"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Date"
                fieldName="created_at"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Org"
                fieldName="orgName"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Campaign ID"
                fieldName="campaign_id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Campaign"
                fieldName="campaignName"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="First name"
                fieldName="first_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Last name"
                fieldName="last_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Email"
                fieldName="email"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Amount"
                fieldName="total_amount"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Seller"
                fieldName="volunteerName"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Shipping method"
                fieldName="ship_to_school"
                params={params}
                setParams={setParams}
              />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              refreshList={refreshList}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Sales per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
};
function Row({ row, lastRow, refreshList }: RowProps) {
  const classes = styles();
  const [showDetails, setShowDetails] = useState(false);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const {
    id,
    first_name,
    last_name,
    email,
    ship_to_school: STS,
    created_at,
    total_amount,
    campaign_id,
    campaignName,
    orgName,
    volunteerName,
    post_closeout,
    post_cutoff,
    item_count,
  } = row;

  const tagText = getTagText({ post_cutoff, post_closeout });
  const onCloseDetails = () => setShowDetails(false);

  return (
    <ListRowContainer
      clickable
      lastRow={lastRow}
      onClick={isDesktop ? () => setShowDetails(true) : undefined}
    >
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div className={classes.id}>
              {`#${id}`}
              {tagText && (
                <Tooltip title={tagText} onClick={e => e.stopPropagation()}>
                  <DonationTagIcon className={classes.donationTag} />
                </Tooltip>
              )}
            </div>
            <div>{format(new Date(created_at as string), "M/d/y, p")}</div>
            <div>{orgName}</div>
            <div>{campaign_id}</div>
            <div>{campaignName}</div>
            <div>{first_name}</div>
            <div>{last_name}</div>
            <div>{email}</div>
            <div>{formatMoney(total_amount)}</div>
            <div>{volunteerName}</div>
            <div className={classes.shipping}>
              {(item_count as number) > 0 && (
                <Fragment>
                  {STS ? <SchoolIcon /> : <HomeIcon />}
                  <div className={classes.shippingMethod}>
                    {STS ? "Ship to school" : "Ship to home"}
                  </div>
                </Fragment>
              )}
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileWrapper}>
              <div className={classes.mobileRow}>
                <div className={classes.id}>
                  {`#${id}`}
                  {tagText && (
                    <Tooltip title={tagText}>
                      <DonationTagIcon className={classes.donationTag} />
                    </Tooltip>
                  )}
                </div>
                <div className={classes.date}>
                  {format(new Date(created_at as string), "M/d/y, p")}
                </div>
              </div>
              <div className={classes.mobileRow}>
                <div className={classes.name}>
                  {`${first_name} ${last_name}`}
                </div>
                <div className={classes.amount}>
                  {formatMoney(total_amount)}
                </div>
              </div>

              <div className={classes.campaignWrapper}>
                <div className={classes.campaignTxt}>
                  {orgName} #{campaign_id}
                </div>
              </div>
            </div>

            <div className={classes.mobileChevron}>
              <IconButton onClick={() => setShowDetails(true)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>

      {showDetails && (
        <DonationDetails
          id={id as number}
          onClose={onCloseDetails}
          refreshList={refreshList}
          isCompanyDonation
        />
      )}
    </ListRowContainer>
  );
}

function getTagText({ post_cutoff, post_closeout }) {
  if (!post_cutoff && !post_closeout) return null;
  let txt = "";
  if (post_cutoff) txt = "Post-cutoff";
  const divider = post_cutoff ? ", " : "";
  if (post_closeout) txt = `${txt}${divider}Post-closeout`;
  return txt;
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns:
      "130px 166px 1fr 130px 1fr .9fr .9fr 1fr 130px 1fr 150px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  id: {
    display: "flex",
    alignItems: "center",
  },
  donationTag: {
    color: "#ffbd20",
    marginLeft: 6,
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 5,
      fontSize: 18,
    },
  },
  shipping: {
    display: "flex",
    alignItems: "center",
  },
  shippingMethod: {
    paddingLeft: 6,
  },
  mobileWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    letterSpacing: 0.1,
    fontSize: 14,
  },
  mobileRow: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  mobileChevron: {
    display: "flex",
    height: "100%",
    minWidth: 54,
    width: 54,
    padding: "0 8px 0 16px",
    alignItems: "center",
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      letterSpacing: 0.1,
      paddingTop: 8,
      paddingRight: 12,
    },
  },
  date: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      letterSpacing: 0.47,
      color: theme.palette.text.secondary2,
    },
  },
  amount: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.13,
      color: theme.palette.primary.main,
      paddingTop: 8,
      minWidth: "fit-content",
    },
  },
  campaignWrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    marginTop: 8,
    borderRadius: 12,
    backgroundColor: "#EFEFEF",
    height: 24,
    maxWidth: "fit-content",
    padding: "0 8px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  campaignTxt: {
    fontSize: 13,
    letterSpacing: 0.15,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
  },
}));
