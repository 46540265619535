import { useState, Fragment, useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Form } from "react-final-form";
import { FormApi } from "final-form";
import { APIAuthClient, convertFileToB64 } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";
import { ButtonSpinner, SingleFileDropzoneField } from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";

type Props = { campaign_id: any };
export function PODArtifacts({ campaign_id }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [podGroups, setPodGroups] = useState<GenericObject[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const url = `/campaign_product_pod_groups?campaign_id=${campaign_id}`;
      const res = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = res;
      if (error) return dispatch(setToast(errorMessage));
      setPodGroups(data);
    };
    if (campaign_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  return (
    <div className={classes.wrapper}>
      {podGroups.map((group, index) => {
        return (
          <Group
            key={index}
            group={group as GenericObject}
            setPodGroups={setPodGroups}
          />
        );
      })}
    </div>
  );
}

type GroupProps = {
  group: GenericObject;
  setPodGroups: React.Dispatch<React.SetStateAction<GenericObject[]>>;
};
function Group({ group, setPodGroups }: GroupProps) {
  const classes = styles();
  const dispatch = useDispatch();

  const onSubmit = async (values: any, form: FormApi) => {
    const {
      base_round_artBase64,
      base_horizontal_artBase64,
      base_logo_artBase64,
      base_embroidery_artBase64,
      embroidery_dst_fileBase64,
      embroidery_pdf_fileBase64,
      digital_fileBase64,
    } = values;
    const uploads = {
      base_round_artBase64,
      base_horizontal_artBase64,
      base_logo_artBase64,
      base_embroidery_artBase64,
      embroidery_dst_fileBase64,
      embroidery_pdf_fileBase64,
      digital_fileBase64,
    };

    const update: GenericObject = {};
    for (const k in uploads) {
      if (uploads[k]) {
        update[k] = await convertFileToB64(uploads[k] as File);
      }
    }
    if (!Object.keys(update).length) {
      return dispatch(setToast("Nothing to update!"));
    }

    const url = `/campaign_product_pod_groups/${group.id}`;
    const res = await APIAuthClient.put<any, APIRes>(
      url,
      { ...update },
      { timeout: 180000 }, // TODO: this is a long task so for now we are upping the timeout - there is probably a better way to do this - it's a one time per POD campaign setup so for now we are leaving as is...
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setPodGroups(data);
    form.restart();
    dispatch(setToast("Changes saved", ToastTypes.success));
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={group}
      render={({ handleSubmit, form, submitting, values }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.group}>
            <div className={classes.groupHeader}>{values.pod_group_name}</div>
            {/* SECTION 1 */}
            <div className={classes.section}>
              <div className={classes.sectionNum}>1.</div>
              <div className={classes.sectionContent}>
                <div className={classes.sectionHeader}>Round art</div>
                <ImageDropzoneField
                  fieldName="base_round_artBase64"
                  currImage={values.base_round_art}
                  noDelete
                />
              </div>
            </div>

            {/* SECTION 2 */}
            <div className={classes.section}>
              <div className={classes.sectionNum}>2.</div>
              <div className={classes.sectionContent}>
                <div className={classes.sectionHeader}>Horizontal art</div>
                <ImageDropzoneField
                  fieldName="base_horizontal_artBase64"
                  currImage={values.base_horizontal_art}
                  noDelete
                />
              </div>
            </div>

            {/* SECTION 3 */}
            <div className={classes.section}>
              <div className={classes.sectionNum}>3.</div>
              <div className={classes.sectionContent}>
                <div className={classes.sectionHeader}>Logo art</div>
                <ImageDropzoneField
                  fieldName="base_logo_artBase64"
                  currImage={values.base_logo_art}
                  noDelete
                />
              </div>
            </div>

            {/* SECTION 4 */}
            <div className={classes.section}>
              <div className={classes.sectionNum}>4.</div>
              <div className={classes.sectionContent}>
                <div className={classes.sectionHeader}>Embroidery art</div>
                <ImageDropzoneField
                  fieldName="base_embroidery_artBase64"
                  currImage={values.base_embroidery_art}
                  noDelete
                />
              </div>
            </div>

            {/* SECTION 5 */}
            <div className={classes.section}>
              <div className={classes.sectionNum}>5.</div>
              <div className={classes.sectionContent}>
                <div className={classes.sectionHeader}>Digitalized file</div>
                <SingleFileDropzoneField
                  fieldName="digital_fileBase64"
                  currentFileName={values.digital_file}
                  accept={["application/postscript", ".eps"]}
                />
              </div>
            </div>

            {/* SECTION 6 */}
            <div className={classes.section}>
              <div className={classes.sectionNum}>6.</div>
              <div className={classes.sectionContent}>
                <div className={classes.sectionHeader}>Embroidery DST file</div>
                <SingleFileDropzoneField
                  fieldName="embroidery_dst_fileBase64"
                  currentFileName={values.embroidery_dst_file}
                  accept={["application/x-dst", ".dst"]}
                />
              </div>
            </div>

            {/* SECTION 7 */}
            <div className={classes.section}>
              <div className={classes.sectionNum}>7.</div>
              <div className={classes.sectionContent}>
                <div className={classes.sectionHeader}>Embroidery PDF file</div>
                <SingleFileDropzoneField
                  fieldName="embroidery_pdf_fileBase64"
                  currentFileName={values.embroidery_pdf_file}
                  accept={["application/pdf"]}
                />
              </div>
            </div>

            <div className={classes.bottom}>
              <Fragment>
                <div>
                  <Button
                    variant="text"
                    disabled={submitting}
                    className={classes.cancel}
                    onClick={() => form.restart()}
                  >
                    CANCEL
                  </Button>
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </Fragment>
            </div>
          </form>
        );
      }}
    />
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 12px",
    },
  },
  group: {
    maxWidth: 500,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    marginBottom: 24,
    paddingBottom: 20,
  },
  groupHeader: {
    color: theme.palette.primary.main,
    fontSize: 22,
    paddingBottom: 24,
    fontWeight: 500,
    textAlign: "center",
    paddingTop: 24,
  },
  section: {
    display: "flex",
    paddingBottom: 32,
  },
  sectionNum: {
    width: 20,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  sectionContent: {
    width: "100%",
  },
  sectionHeader: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
    marginBottom: 12,
  },
  bottom: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: 12,
  },
  save: {
    marginLeft: 16,
    width: 120,
  },
  cancel: {
    color: theme.palette.text.secondary,
  },
}));
