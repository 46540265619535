import { useState, useEffect, Fragment } from "react";
import { List, arrayMove } from "react-movable";
import { Button, TextField, MenuItem, makeStyles } from "@material-ui/core";
import { APIAuthClient } from "../../../lib";
import {
  APIRes,
  GenericObject,
  ObjectOfArrays,
  ToastTypes,
} from "../../../types";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";

type Props = { campaign_id: any };
export function ProductOrdering({ campaign_id }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [originalCategories, setOriginalCategories] = useState<GenericObject[]>(
    [],
  );
  const [originalProducts, setOriginalProducts] = useState<ObjectOfArrays>({});

  useEffect(() => {
    const fetch = async () => {
      const url = `/campaign_products/store?campaign_id=${campaign_id}`;
      const res = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = res;
      if (error) return dispatch(setToast(errorMessage));
      setOriginalCategories(data.categories);
      setOriginalProducts(data.categorizedProducts);
    };
    if (campaign_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  return (
    <div className={classes.container}>
      <div className={classes.directions}>
        Drag & Drop the Chips to reorder the Categories and Products then press
        save
      </div>

      <Categories
        originalCategories={originalCategories}
        campaign_id={campaign_id}
      />

      <hr className={classes.hr} />

      <Products
        originalCategories={originalCategories}
        originalProducts={originalProducts}
        campaign_id={campaign_id}
      />
    </div>
  );
}

type CategoryProps = { campaign_id: any; originalCategories: GenericObject[] };
function Categories({ originalCategories, campaign_id }: CategoryProps) {
  const classes = styles();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<GenericObject[]>([]);

  useEffect(() => {
    setCategories([...originalCategories]);
  }, [originalCategories]);

  const updateOrder = async () => {
    const url = `/campaign_products/campaigns/${campaign_id}/update_display_order`;
    const update = {
      type: "categories",
      categoryOrder: categories.map(c => c.id),
    };
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Changes saved", ToastTypes.success));
  };

  return (
    <Fragment>
      <div className={classes.subHeader}>categories</div>

      <List
        values={categories}
        lockVertically={true}
        onChange={({ oldIndex, newIndex }) => {
          setCategories(arrayMove(categories, oldIndex, newIndex));
        }}
        renderList={({ children, props, isDragged }) => (
          <ul
            {...props}
            style={{ cursor: isDragged ? "grabbing" : undefined }}
            className={classes.list}
          >
            {children}
          </ul>
        )}
        renderItem={({ value, props, isDragged, isSelected }) => {
          return (
            <li
              key={value.id as number}
              {...props}
              style={{
                ...props.style,
                cursor: isDragged ? "grabbing" : "grab",
                backgroundColor:
                  isDragged || isSelected ? "#A9A9A9" : "#FFFFFF",
              }}
              className={classes.item}
            >
              {value.name}
            </li>
          );
        }}
      />
      <Button onClick={updateOrder} color="primary">
        Save changes
      </Button>
    </Fragment>
  );
}

type ProductProps = {
  campaign_id: any;
  originalCategories: GenericObject[];
  originalProducts: ObjectOfArrays;
};
function Products({
  campaign_id,
  originalCategories,
  originalProducts,
}: ProductProps) {
  const classes = styles();
  const dispatch = useDispatch();
  const [products, setProducts] = useState<ObjectOfArrays>({});
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    setProducts({ ...originalProducts });
  }, [originalProducts]);

  const productChange = (newOrder: any, categoryId: any) => {
    setProducts({ ...products, [categoryId]: newOrder });
  };

  const updateOrder = async () => {
    const productOrder = {};
    Object.keys(products).forEach(catId => {
      productOrder[catId] = products[catId].map(p => p.id);
    });
    const update = { type: "products", productOrder };
    const url = `/campaign_products/campaigns/${campaign_id}/update_display_order`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("changes saved", ToastTypes.success));
  };

  return (
    <Fragment>
      <div className={classes.subHeader}>products</div>
      <div>
        <TextField
          select
          label="Select a category to sort"
          value={categoryId}
          className={classes.categorySelect}
          onChange={({ target }) => setCategoryId(target.value)}
        >
          {originalCategories.map(({ id, name }) => (
            <MenuItem key={id as number} value={id as number}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {categoryId && Array.isArray(products[categoryId]) && (
        <div key={categoryId} className={classes.categoryProducts}>
          <List
            values={products[categoryId]}
            lockVertically={true}
            onChange={({ oldIndex, newIndex }) => {
              productChange(
                arrayMove(products[categoryId], oldIndex, newIndex),
                categoryId,
              );
            }}
            renderList={({ children, props, isDragged }) => (
              <ul
                {...props}
                style={{ cursor: isDragged ? "grabbing" : undefined }}
                className={classes.list}
              >
                {children}
              </ul>
            )}
            renderItem={({ value, props, isDragged, isSelected }) => {
              const { id, product_name, thumbnail_image } = value;
              return (
                <li
                  key={id as number}
                  {...props}
                  style={{
                    ...props.style,
                    cursor: isDragged ? "grabbing" : "grab",
                    backgroundColor:
                      isDragged || isSelected ? "#A9A9A9" : "#FFFFFF",
                  }}
                  className={classes.item}
                >
                  <div className={classes.productInfo}>
                    <img
                      className={classes.thumbnail}
                      src={thumbnail_image as string}
                      alt="thumbnail"
                    />
                    <div> {product_name}</div>
                  </div>
                </li>
              );
            }}
          />
        </div>
      )}
      <Button onClick={updateOrder} color="primary">
        Save changes
      </Button>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    backgroundColor: "#FFFFFF",
  },
  directions: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "24px",
    },
  },
  subHeader: {
    color: theme.palette.primary.main,
    fontSize: "24px",
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "24px 0",
    textAlign: "center",
  },
  hr: {
    margin: "24px 0",
  },
  list: {
    padding: 0,
    width: "fit-content",
    marginBottom: "24px !important",
  },
  item: {
    padding: "4px 8px",
    margin: "6px 0",
    listStyleType: "none",
    border: "1px solid #A9A9A9",
    borderRadius: 8,
    zIndex: 99999,
    width: "fit-content",
  },
  categorySelect: {
    minWidth: 250,
    marginBottom: 24,
  },
  categoryProducts: {
    maxWidth: "100%",
  },
  productInfo: {
    display: "flex",
    alignItems: "center",
  },
  thumbnail: {
    maxWidth: 30,
    maxHeight: 30,
    marginRight: 12,
  },
}));
