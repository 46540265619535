import { useState, useEffect, Fragment } from "react";
import { useField, useForm } from "react-final-form";
import { Button, makeStyles } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import ReplaceIcon from "@material-ui/icons/PublishOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { RedButton } from "./RedButton";
import { useIsMobile } from "../../hooks/ui";
import { requiredField } from "../../lib";
import classNames from "classnames";

type Props = {
  fieldName: string;
  deleteFieldName?: string;
  currImage?: string | null;
  marginBottom?: number;
  noDelete?: boolean;
  noReplace?: boolean;
  required?: boolean;
};

export function ImageDropzoneField(props: Props) {
  const {
    fieldName,
    deleteFieldName,
    currImage,
    marginBottom,
    noDelete,
    noReplace,
    required,
  } = props;
  const classes = styles();
  const field = useField(fieldName, {
    validate: required ? requiredField : undefined,
  });
  const input = field.input;
  const { submitFailed, invalid } = field.meta;
  const isMobile = useIsMobile();
  const form = useForm();
  const { pristine } = form.getState();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const deleteInput = deleteFieldName ? useField(deleteFieldName).input : null;

  const [preview, setPreview] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    // if form is `restarted` clear out preview and put back original image if there was one
    if (pristine) setPreview(currImage ? currImage : "");
  }, [pristine, currImage]);

  useEffect(() => {
    if (currImage) setPreview(currImage);
    else setPreview("");
  }, [currImage]);

  useEffect(() => {
    if (required && submitFailed && invalid) setShowError(true);
    else setShowError(false);
  }, [required, submitFailed, invalid]);

  const onDrop = (fileArr: File[]) => {
    if (Array.isArray(fileArr) && fileArr.length) {
      setPreview(URL.createObjectURL(fileArr[0]));
      input.onChange(fileArr[0]);
      if (deleteInput) deleteInput.onChange(false);
    }
  };

  const onDelete = () => {
    setPreview("");
    input.onChange(null);
    if (deleteInput) deleteInput.onChange(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    multiple: false,
    onDrop,
  });

  return (
    <Fragment>
      {preview && (
        <div className={classes.previewContainer}>
          <img src={preview} className={classes.image} />
          <div className={classes.buttons}>
            {!noReplace && (
              <div className={classes.replaceContainer}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    startIcon={<ReplaceIcon color="primary" />}
                    color="primary"
                    className={classes.button}
                    variant="text"
                  >
                    REPLACE
                  </Button>
                </div>
              </div>
            )}
            {!noDelete && (
              <RedButton
                startIcon={<DeleteIcon />}
                className={classes.button}
                onClick={onDelete}
                variant="text"
              >
                DELETE
              </RedButton>
            )}
          </div>
        </div>
      )}

      {!preview && (
        <div
          className={classNames(
            classes.uploadContainer,
            showError && classes.error,
          )}
        >
          <div {...getRootProps()} className={classes.uploadTxt}>
            <input {...getInputProps()} />
            {!isMobile && (
              <div>
                <span className={classes.click}>Click to upload</span>or drag
                and drop a photo here
              </div>
            )}
            {isMobile && (
              <div>
                <div>
                  <span className={classes.click}>Click to upload</span>or drag
                  and
                </div>
                <div>drop a photo here</div>
              </div>
            )}
          </div>
        </div>
      )}
      {marginBottom && <div style={{ height: marginBottom }} />}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  uploadContainer: {
    height: 104,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    cursor: "pointer",
    fontSize: 16,
    letterSpacing: 0.11,
    [theme.breakpoints.down("sm")]: {
      height: 88,
    },
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  uploadTxt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    textAlign: "center",
    lineHeight: "24px",
  },
  click: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginRight: 6,
  },
  previewContainer: {
    width: 264,
    maxWidth: 264,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  buttons: {
    marginTop: 8,
    display: "flex",
    // [theme.breakpoints.down("xs")]: {
    //   justifyContent: "center",
    // },
  },
  button: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
  },
  image: {
    // width: "100%",
    maxWidth: "100%",
  },
  replaceContainer: {
    marginRight: 16,
  },
}));
