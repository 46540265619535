import { useEffect, useState } from "react";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDownOutlined";
import { useIsMobile, useScrollToTop } from "../../../hooks/ui";
import {
  FilterChips,
  FilterDrawerButton,
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
} from "../../../components";
import { useList } from "../../../hooks/list";
import { CampaignProductRows } from "./CampaignProductRows";
import { CampaignProductFilters } from "./CampaignProductFilters";
import { NoResultsSVG } from "../../../assets";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";
import { APIAuthClient } from "../../../lib";
import {
  APIRes,
  CampaignProductAutocomplete,
  CampaignProductFields,
  campaignProductSearchFields,
  campaignProductSortFields,
} from "../../../types";
import { BulkActions } from "./BulkActions";

type Props = { campaign_id: any; softDeleted: boolean };
export function CampaignProductList({ campaign_id, softDeleted }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [categories, setCategories] = useState<CampaignProductAutocomplete>([]);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [anchorEl, setAnchorEl] = useState<undefined | Element>(undefined);
  const isMobile = useIsMobile();
  useScrollToTop();

  const listProps = useList<any>({
    baseUrl: "/campaign_products",
    isReady: true,
    initialState: {
      campaign_id,
      soft_deleted: softDeleted,
      sort: "product_name",
      order: "asc",
    },
    filterFields: CampaignProductFields,
  });
  const {
    listIsFiltered,
    total,
    noResults,
    showRows,
    filterDrawerActive,
    hasSelectedRows,
  } = listProps;

  useEffect(() => {
    const fetch = async () => {
      const url = `/campaign_products/category_autocomplete?campaign_id=${campaign_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setCategories(data);
    };
    if (campaign_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const onBulkActionsClick = (e: any) => {
    setAnchorEl(e.currentTarget);
    setShowBulkActions(true);
  };

  return (
    <ListContainer listProps={listProps} topOffset={294} noPadding>
      {isMobile && (
        <div className={classes.mobileTop}>
          <div className={classes.count}>
            {total} Product
            {total !== 1 ? "s" : ""}
          </div>
          <div>
            <IconButton onClick={() => setShowSort(true)}>
              <SwapVertIcon />
            </IconButton>
            <span className={classes.buttonSpacer} />
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
          </div>
        </div>
      )}
      <div className={classes.searchRow}>
        <MultiSearch
          listProps={listProps}
          fields={campaignProductSearchFields}
        />
        {!isMobile && (
          <div>
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
            <Button
              color="secondary"
              endIcon={<ArrowDropDownIcon />}
              className={classes.bulkButton}
              onClick={e => onBulkActionsClick(e)}
              disabled={!hasSelectedRows}
            >
              Bulk actions
            </Button>
          </div>
        )}
      </div>

      <FilterChips listProps={listProps} />

      {noResults && (
        <ListNoResults
          SVG={NoResultsSVG}
          listIsFiltered={listIsFiltered}
          label="products"
        />
      )}

      {showRows && (
        <CampaignProductRows listProps={listProps} softDeleted={softDeleted} />
      )}
      {showFilter && (
        <CampaignProductFilters
          onClose={() => setShowFilter(false)}
          listProps={listProps}
          categories={categories}
        />
      )}
      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={campaignProductSortFields}
        />
      )}
      <BulkActions
        isOpen={showBulkActions}
        onClose={() => setShowBulkActions(false)}
        anchorEl={anchorEl}
        listProps={listProps}
        softDeleted={softDeleted}
      />
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  mobileTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  buttonSpacer: {
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
    },
  },
  bulkButton: {
    width: 146,
    marginLeft: 16,
  },
}));
