import { SearchFields } from "./list";

export const campaignProductSearchFields: SearchFields = [
  { label: "Product Name", field: "q", placeholder: "Product name" },
  {
    label: "ID",
    field: "product_id",
    placeholder: "ID",
    isNumber: true,
  },
];

export enum CampaignProductFields {
  exclude_variants = "exclude_variants",
  category_id = "category_id",
}

export const campaignProductSortFields = [
  { field: "id", label: "ID" },
  { field: "sku", label: "Sku" },
  { field: "product_name", label: "Name" },
];

export type CampaignProductAutocomplete = {
  id: number;
  category_name: string;
}[];
