import { createElement, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  MenuItemLink,
  getResources,
  DashboardMenuItem,
  useNotify,
} from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import DonationIcon from "@material-ui/icons/AttachMoney";
import BuildIcon from "@material-ui/icons/Build";
import InfoIcon from "@material-ui/icons/Info";
import GetAppIcon from "@material-ui/icons/GetApp";
import DraftsIcon from "@material-ui/icons/Drafts";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MaterialIcon from "@material-ui/icons/FileCopyOutlined";
import CommunicationIcon from "@material-ui/icons/ForumOutlined";
import { MenuItem, TextField, useMediaQuery } from "@material-ui/core";
import {
  isSuperAdmin,
  isCompanyOrCompanyAdmin,
  APIClient,
  generateAuthHeader,
  isRep,
  isJMSCompany,
  isCompanyOrCompanyAdminOrRep,
  isCompanyDataEntry,
} from "../lib";
import { styles } from "./Layout.styles";
import {
  changeSeasonId,
  getIsAnyCompanyPositionOrRepUser,
  getSeasonId,
  setCompanySeasonOptions,
} from "../customState";
import { UserMenu } from "./UserMenu";
import { companyActions } from "../newApp/state/company/actions";

const Menu = props => {
  const { role, supplier_id, allowDiscountCards, companyId } = props;
  const classes = styles();
  const notify = useNotify();
  const season_id = useSelector(getSeasonId);
  const companyUser = useSelector(getIsAnyCompanyPositionOrRepUser);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const resources = useSelector(getResources);
  const dispatch = useDispatch();
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [fetchedSeasons, setFetchedSeasons] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/company_seasons/autocomplete", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setSeasonOptions(data);
      setFetchedSeasons(true);
      dispatch(setCompanySeasonOptions(data));
    };
    if (companyUser) {
      fetch();
      dispatch(companyActions.fetchCompanyConfigs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUser]);

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
    if (isMobile) {
      dispatch({ type: "RA/SET_SIDEBAR_VISIBILITY", payload: false });
    }
  };

  return (
    <div className={classes.menu}>
      {companyUser && fetchedSeasons && (
        <div className={classes.selectContainer}>
          <TextField
            select
            variant="outlined"
            label=""
            value={season_id}
            fullWidth
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            onChange={({ target }) => dispatch(changeSeasonId(target.value))}
          >
            <MenuItem value="">All Seasons</MenuItem>
            {seasonOptions.map(({ id, season_name }) => {
              return (
                <MenuItem key={id} value={id}>
                  {season_name}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
      )}

      <DashboardMenuItem primaryText="Dashboard" />
      {(isCompanyOrCompanyAdminOrRep(role) || isCompanyDataEntry(role)) && (
        <MenuItemLink
          key="company_donations"
          to="/company_donations"
          primaryText="Sales"
          leftIcon={<DonationIcon />}
        />
      )}

      {resources
        .filter(r => {
          const { options: { hide } = {}, hasList } = r;
          return Boolean(hasList && !hide);
        })
        .map(resource => {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={getLinkTxt(resource)}
              leftIcon={createElement(resource.icon)}
            />
          );
        })}
      {isSuperAdmin(role) && (
        <MenuItemLink
          key="super-admin-company"
          to={"/authenticate-super-admin-company"}
          target="_blank"
          primaryText="JMS Company"
          leftIcon={<BuildIcon />}
        />
      )}
      {isCompanyOrCompanyAdmin(role) && supplier_id && (
        <MenuItemLink
          key="products"
          to={`/product_suppliers/${supplier_id}/show`}
          primaryText="Inventory Management"
          leftIcon={<BuildIcon />}
        />
      )}
      {isCompanyOrCompanyAdmin(role) && (
        <MenuItemLink
          key="downloads"
          to="/downloads"
          primaryText="Downloads"
          leftIcon={<GetAppIcon />}
        />
      )}
      {isSuperAdmin(role) && (
        <MenuItemLink
          key="/super-admin-tools"
          to={"/super-admin-tools"}
          primaryText="Super Admin Tools"
          leftIcon={<AddIcon />}
        />
      )}
      {isCompanyOrCompanyAdmin(role) && (
        <MenuItemLink
          key="receipt"
          to="/company_donation_receipt_config"
          primaryText="Receipt Email Config"
          leftIcon={<DraftsIcon />}
        />
      )}
      {isCompanyOrCompanyAdmin(role) && (
        <MenuItemLink
          key="knowledgeBase"
          to="/knowledge_base"
          primaryText="Knowledge Base"
          leftIcon={<InfoIcon />}
        />
      )}
      {isJMSCompany(companyId) && isCompanyOrCompanyAdmin(role) && (
        <MenuItemLink
          key="material lib"
          to="/material_library"
          primaryText="Material Library"
          leftIcon={<MaterialIcon />}
        />
      )}
      {isCompanyOrCompanyAdmin(role) && (
        <MenuItemLink
          key="communication lib"
          to="/communication_library"
          primaryText="Communication Library"
          leftIcon={<CommunicationIcon />}
        />
      )}
      {isRep(role) && allowDiscountCards && (
        <MenuItemLink
          key="discountCard"
          to={`/discount_card_management`}
          primaryText="Discount Card Mgmt"
          leftIcon={<LoyaltyIcon />}
        />
      )}

      <UserMenu anchorEl={userMenuAnchorEl} handleClose={handleUserMenuClose} />
    </div>
  );
};

export default withRouter(
  connect(state => {
    const {
      user: { role, supplier_id, allowDiscountCards, companyId },
    } = state;
    return { role, supplier_id, allowDiscountCards, companyId };
  })(Menu),
);

function getLinkTxt(resource) {
  const { name, options } = resource;
  switch (true) {
    case Boolean(options && options.label):
      return options.label;
    default:
      return name;
  }
}
