import { SearchFields } from "./list";

export type CampaignDonationQueryParams = {
  page: number;
  limit: number;
  sort: string;
  order: "asc" | "desc";
  donation_name_or_email?: string | null;
  donation_id?: string | null;
  start_date?: string;
  end_date?: string;
  volunteer_id?: number;
  unassigned?: boolean;
  id_or_name?: string | null;
};

export enum CampaignDonationFilterFields {
  start_date = "start_date",
  end_date = "end_date",
  volunteer_id = "volunteer_id",
  unassigned = "unassigned",
}

export enum DonationDetailModes {
  view = "view",
  edit = "edit",
}

export function campaignDonationSearchFields(
  isProduct: boolean,
  volunteerLabelSing: string,
): SearchFields {
  return [
    {
      label: "Supporter",
      field: "donation_name_or_email",
      placeholder: "Name or email",
    },
    {
      label: `${isProduct ? "Order" : "Donation"} ID`,
      field: "donation_id",
      placeholder: `${isProduct ? "Order" : "Donation"} ID`,
      isNumber: true,
    },
    {
      label: volunteerLabelSing,
      field: "id_or_name",
      placeholder: `${volunteerLabelSing} ID/name`,
    },
  ];
}

export function campaignDonationSortFields(
  isProduct: boolean,
  volunteerLabelSing: string,
  isAdmin: boolean,
) {
  const fields = [
    { field: "id", label: isProduct ? "Order ID" : "Donation ID" },
    { field: "last_name", label: "Name" },
    { field: "created_at", label: isProduct ? "Order date" : "Donation date" },
    { field: "solicited_by", label: "Solicited by" },
    { field: "total_amount", label: "Amount" },
  ];
  if (isAdmin) {
    fields.push({ field: "volunteerName", label: volunteerLabelSing });
  }
  if (isProduct) {
    fields.push({ field: "ship_to_school", label: "Shipping Method" });
  }

  return fields;
}

export type DonationEventTickets =
  | {
      id: number;
      event_id: number;
      event_name: string;
      ticket_type: string;
      seat: string;
      ticket_price: string;
      attendee: string | null;
      row_id: number | null;
      seat_id: number | null;
      section_id: number | null;
    }[]
  | null;

export const companyDonationSearchFields: SearchFields = [
  {
    label: "ID",
    field: "donation_id",
    placeholder: "Order ID",
    isNumber: true,
  },
  { label: "Supporter name", field: "name", placeholder: "Supporter name" },
  { label: "Supporter email", field: "email", placeholder: "Supporter email" },
  {
    label: "Seller ID",
    field: "volunteer_id",
    placeholder: "Seller ID",
    isNumber: true,
  },
  { label: "Seller name", field: "volunteer_name", placeholder: "Seller name" },
  {
    label: "Campaign ID",
    field: "campaign_id",
    placeholder: "Campaign ID",
    isNumber: true,
  },
];

export const companyDonationSortFields = [
  { field: "id", label: "Order ID" },
  { field: "created_at", label: "Date" },
  { field: "orgName", label: "Org" },
  { field: "campaign_id", label: "Campaign ID" },
  { field: "campaignName", label: "Campaign" },
  { field: "first_name", label: "First name" },
  { field: "last_name", label: "Last name" },
  { field: "email", label: "Email" },
  { field: "total_amount", label: "Amount" },
  { field: "volunteerName", label: "Seller" },
  { field: "ship_to_school", label: "Shipping Method" },
];

export enum CompanyDonationFilterFields {
  start_date = "start_date",
  end_date = "end_date",
  unassigned = "unassigned",
}
