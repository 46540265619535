import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, setToast } from "../../../state";
import { APIRes, ToastTypes } from "../../../types";
import { APIAuthClient } from "../../../lib";
import { ConfirmationDialog } from "../../../components";

type Props = {
  refreshData: () => void;
  onClose: () => void;
};
export function AssignUnassignedSTSOrders({ onClose, refreshData }: Props) {
  const dispatch = useDispatch();
  const campaign_id = useSelector(getCampaignId);

  const onConfirm = async (
    setSubmitting: Dispatch<SetStateAction<boolean>>,
  ) => {
    setSubmitting(true);
    const url = `/donations/assign_unassigned-sts-orders?campaign_id=${campaign_id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, {});
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      setSubmitting(false);
    } else {
      onClose();
      dispatch(setToast("STS orders have been assigned", ToastTypes.success));
      refreshData();
    }
  };

  return (
    <ConfirmationDialog
      onClose={onClose}
      title="Are you sure?"
      subtitle="Are you sure you want to assign all unassigned STS orders to a generic student account?"
      actionLabel="Confirm"
      actionFunc={setSubmitting => onConfirm(setSubmitting)}
    />
  );
}
