import { IconButton, makeStyles, TextField, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { APIRes, GenericObject } from "../../../types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCampaignId,
  getOrgVolunteerAutocomplete,
  getVolunteerLabel,
  orgVolunteerAutocomplete,
  setToast,
} from "../../../state";
import { APIAuthClient } from "../../../lib";
import { VariableHeightDrawer } from "../../../components/ui/VariableHeightDrawer";
import { useAppDispatch } from "../../../types/state";
import { ButtonSpinner } from "../../../components";
import { useDrawerTransition } from "../../../hooks/ui";
const MIN_2_WORDS = /(\w+\s+[^-])+\S+/;

type Props = {
  donationId: number;
  currentVolunteerId?: null | number;
  solicited_by: string | null;
  onClose: () => void;
  shouldRefreshListRef: React.MutableRefObject<boolean>;
  refreshData: () => void;
};
export function MobileUpdateVolunteer({
  donationId,
  currentVolunteerId,
  solicited_by,
  onClose: _onClose,
  shouldRefreshListRef,
  refreshData,
}: Props) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const campaignId = useSelector(getCampaignId);
  const volunteers = useSelector(getOrgVolunteerAutocomplete);
  const { volunteerLabelLCSingular, volunteerLabelSing } = useSelector(
    getVolunteerLabel,
  );
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [volunteerName, setVolunteerName] = useState("");
  const [volunteerId, setVolunteerId] = useState<null | string>(null);
  const [showUpdate, setShowUpdate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    setCurrVolunteer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVolunteerId, volunteers, isOpen]);

  const setCurrVolunteer = () => {
    if (!currentVolunteerId) {
      setVolunteerName("");
      setVolunteerId(null);
      setNotFound(false);
      return;
    }
    const v = volunteers.find(({ id }) => id === currentVolunteerId);
    if (v) {
      const { id, name } = v;
      setVolunteerName(name as string);
      setVolunteerId(`${id}`);
      setNotFound(false);
    } else {
      // if for some reason the volunteer on the sale is not in the current list (which shouldn't happen) - show an error
      if (volunteers.length) {
        setNotFound(true);
      } else {
        setVolunteerName("");
        setVolunteerId(null);
        setNotFound(false);
      }
    }
  };

  const updateShowUpdate = (newValue: any) => {
    if (
      (newValue?.id && currentVolunteerId === newValue?.id) ||
      (!currentVolunteerId && !newValue)
    ) {
      setShowUpdate(false);
    } else {
      setShowUpdate(true);
    }
  };

  const onSelect = (_: any, newValue: any) => {
    updateShowUpdate(newValue);

    if (!newValue) {
      setVolunteerName("");
      setVolunteerId(null);
    } else if (typeof newValue === "string") {
      setVolunteerName(newValue);
      setVolunteerId(null);
    } else {
      setVolunteerName(newValue.name);
      setVolunteerId(`${newValue.id}`);
    }
  };

  const onInputChange = (_: any, newValue: string, action: string) => {
    if (action === "reset") return;
    updateShowUpdate(newValue);
    setVolunteerName(newValue);
  };

  const onUpdate = async () => {
    setSubmitting(true);
    if (
      !volunteerId &&
      volunteerName &&
      !MIN_2_WORDS.test(volunteerName.trim())
    ) {
      dispatch(
        setToast(`${volunteerLabelSing} must have a first and last name`),
      );
      setSubmitting(false);
      return;
    }

    const data: GenericObject = {
      campaignId,
      volunteerId: Number(volunteerId),
      volunteerName,
    };
    if (!volunteerId && !volunteerName) {
      data.remove = true;
      data.volunteerName = null;
    }
    const res = await APIAuthClient.put<any, APIRes>(
      `/donations/${donationId}/update-volunteer`,
      data,
    );
    setSubmitting(false);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return;
    }
    refreshData();
    shouldRefreshListRef.current = true;
    if (!volunteerId) dispatch(orgVolunteerAutocomplete());
    onClose();
  };

  return (
    <VariableHeightDrawer isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.closeWrapper}>
          <IconButton size="small" className={classes.close} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <h1 className={classes.title}>Update {volunteerLabelLCSingular}</h1>

        <p className={classes.solicited}>
          <span className={classes.solicitedBy}>Solicited by</span>
          {solicited_by ? solicited_by : ""}
        </p>

        {notFound && (
          <div className={classes.error}>
            Refresh to view {volunteerLabelLCSingular}
          </div>
        )}

        {!notFound && (
          <Autocomplete
            fullWidth
            inputValue={volunteerName}
            value={volunteerId}
            freeSolo
            selectOnFocus
            onInputChange={onInputChange}
            onChange={onSelect}
            options={volunteers}
            forcePopupIcon
            getOptionSelected={(o: any, v: any) => {
              if (!v) return false;
              if (typeof v === "string") return `${o.id}` === v;
              return o.id === v.id;
            }}
            getOptionLabel={(option: any) => option.name || option}
            disabled={submitting}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={`Search or add ${volunteerLabelLCSingular}`}
                fullWidth
                disabled={submitting}
              />
            )}
          />
        )}

        <div className={classes.updateWrapper}>
          <Button
            color="primary"
            className={classes.update}
            onClick={onUpdate}
            disabled={!showUpdate || submitting}
          >
            Update
            <ButtonSpinner show={submitting} />
          </Button>
        </div>
      </div>
    </VariableHeightDrawer>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "12px 16px 16px 16px",
  },
  closeWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  close: {
    marginRight: -6,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    marginBottom: 24,
  },
  solicited: {
    fontSize: 14,
    marginBottom: 24,
    letterSpacing: 0.15,
    color: theme.palette.text.secondary,
  },
  solicitedBy: {
    fontWeight: 500,
    paddingRight: 16,
  },
  error: {
    color: "#ED2A2A",
  },
  updateWrapper: {
    marginTop: 32,
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  update: {
    width: 300,
    maxWidth: "100%",
  },
}));
