import { Fragment } from "react";
import { CompanyDonationFilterFields, ListProps } from "../../../../types";

import {
  DateRangeFilter,
  FiltersDrawer,
  SwitchFilter,
} from "../../../../components";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function CompanyDonationFilters({ onClose, listProps }: Props) {
  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header="Filter sales"
    >
      <Fragment>
        <DateRangeFilter header="Sale date" listProps={listProps} />

        <SwitchFilter
          field={CompanyDonationFilterFields.unassigned}
          chipText="Unassigned sales"
          header="Sales attribution"
          switchLabel="Show unassigned sales only"
          listProps={listProps}
        />
      </Fragment>
    </FiltersDrawer>
  );
}
