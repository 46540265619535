import { Fragment, useState } from "react";
import {
  IconButton,
  makeStyles,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import { useAppDispatch } from "../../../types/state";
import { APIAuthClient } from "../../../lib";
import { useIsMobile } from "../../../hooks/ui";
import { hexToRGBA } from "../../../../lib";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCloseParent: () => void;
  donation: GenericObject;
  refreshList?: () => void;
  refreshParentRef?: React.MutableRefObject<boolean>;
  isProduct: boolean;
};

export function DeleteDonation({
  isOpen,
  onClose,
  onCloseParent,
  donation,
  refreshList,
  refreshParentRef,
  isProduct,
}: Props) {
  const { id: donationId, hasShipstationOrders } = donation;
  const classes = styles();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const [submitting, setSubmitting] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [restockInventory, setRestockInventory] = useState(true);
  const [deleteFromShipstation, setDeleteFromShipstation] = useState(false);
  const keyWord = isProduct ? "Order" : "Donation";
  const keyWordLC = isProduct ? "order" : "donation";

  const onDelete = async () => {
    setSubmitting(true);
    const res = await APIAuthClient.delete<any, APIRes>(
      `donations/${donationId}`,
    );
    const { error, errorMessage, code } = res;
    if (error) {
      if (code === 113) setShowWarning(true);
      else dispatch(setToast(errorMessage));
      setSubmitting(false);
      return;
    }
    dispatch(setToast(`${keyWord} deleted`, ToastTypes.success));
    if (refreshParentRef) refreshParentRef.current = true;
    if (refreshList) refreshList();
    onClose();
    onCloseParent();
  };

  const onDeleteFulfilled = async () => {
    setSubmitting(true);
    const post = {
      donation_id: donationId,
      restock_inventory: restockInventory,
      delete_from_shipstation: deleteFromShipstation,
    };
    const res = await APIAuthClient.post<any, APIRes>(
      "/donations/delete_fulfilled_order",
      post,
    );
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      setSubmitting(false);
      return;
    }
    dispatch(setToast(`${keyWord} deleted`, ToastTypes.success));
    if (refreshParentRef) refreshParentRef.current = true;
    if (refreshList) refreshList();
    onCloseParent();
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} dynamicHeight>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.closeWrapper}>
            <IconButton
              size="small"
              className={classes.close}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}

        <div className={classes.main}>
          {!showWarning && (
            <h1 className={classes.title}>
              Are you sure you want to delete {keyWordLC} #{donationId}?
            </h1>
          )}
          {showWarning && (
            <div className={classes.warningTitleWrapper}>
              <WarningIcon className={classes.warningIcon} />
              <h1 className={classes.title}>
                This order has already been fulfilled. Are you sure you want to
                delete it?
              </h1>
            </div>
          )}

          <p className={classes.txt}>
            All records will be permanently removed, and any payment made will
            not be refunded automatically. You'll need to process the refund
            manually if necessary.
          </p>

          {showWarning && (
            <Fragment>
              <FormControlLabel
                className={classes.restock}
                control={
                  <Checkbox
                    color="secondary"
                    checked={restockInventory}
                    onChange={({ target }) =>
                      setRestockInventory(target.checked)
                    }
                  />
                }
                label="Add items back to inventory"
              />

              {hasShipstationOrders && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={deleteFromShipstation}
                      onChange={({ target }) =>
                        setDeleteFromShipstation(target.checked)
                      }
                    />
                  }
                  label="Delete Order in ShipStation"
                />
              )}
            </Fragment>
          )}
        </div>

        <div className={classes.actions}>
          <Button
            variant={isMobile ? "contained" : "text"}
            className={classes.cancel}
            onClick={onClose}
            size={isMobile ? "medium" : "small"}
            disabled={submitting}
            color={isMobile ? "secondary" : "default"}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            size={isMobile ? "medium" : "small"}
            variant={isMobile ? "contained" : "text"}
            className={classes.delete}
            disabled={submitting}
            onClick={showWarning ? onDeleteFulfilled : onDelete}
          >
            Delete
            <ButtonSpinner show={submitting} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      height: "fit-content",
    },
  },
  closeWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  close: {
    padding: 12,
  },
  main: {
    padding: "16px 24px 32px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 48px 16px",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
    },
  },
  warningTitleWrapper: {
    display: "flex",
  },
  warningIcon: {
    marginRight: 16,
    marginTop: 6,
    color: theme.palette.error.main,
  },
  txt: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 16,
    padding: "0 8px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0 16px",
    },
  },
  restock: {
    display: "block",
    marginTop: 16,
  },
  delete: {
    width: 67,
    borderRadius: 4,
    color: theme.palette.error.main,
    textTransform: "uppercase",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: hexToRGBA(theme.palette.error.main, 0.1),
    },
    [theme.breakpoints.down("sm")]: {
      width: 160,
      borderRadius: 18,
      textTransform: "none",
      color: "#FFFFFF",
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  cancel: {
    width: 73,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 6,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: 160,
      borderRadius: 18,
      marginRight: 16,
      color: theme.palette.secondary.contrastText,
      textTransform: "none",
    },
  },
}));
