import { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  makeStyles,
  RadioGroup,
  Radio,
  Button,
  Dialog,
  DialogContentText,
} from "@material-ui/core";
import { APIAuthClient, nonEmptyArray } from "../../../lib";
import {
  APIRes,
  GenericObject,
  ObjectOfArrays,
  ToastTypes,
} from "../../../types";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";
import { ButtonSpinner, CircularLoader } from "../../../components";
import { hexToRGBA, productStructureTypes } from "../../../../lib";
import classNames from "classnames";

type Props = { campaign_id: any };
export function UpdateConfigurableDefaultVariant({ campaign_id }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [noConfProducts, setNoConfProducts] = useState(false);
  const [categories, setCategories] = useState<GenericObject[]>([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryParents, setCategoryParents] = useState<ObjectOfArrays>({});
  const [parentId, setParentId] = useState<null | number>(null);

  useEffect(() => {
    if (campaign_id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchData = async () => {
    const url = `/campaign_products/store?campaign_id=${campaign_id}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    const { categories, categorizedProducts } = data;
    const categoryArr: GenericObject[] = [];
    const _categoryParents = {};
    categories.forEach((c: { id: number; name: string }) => {
      const { id: categoryId, name } = c;
      if (!categorizedProducts[categoryId]) return;
      categorizedProducts[categoryId].forEach((p: any) => {
        const { id: parentId, image, product_name, structure_type } = p;
        if (structure_type !== productStructureTypes.CONFIGURABLE) return;
        if (!_categoryParents[categoryId]) {
          categoryArr.push({ id: categoryId, name });
          _categoryParents[categoryId] = [];
        }
        _categoryParents[categoryId].push({
          id: parentId,
          image,
          product_name,
        });
      });
    });
    if (!categoryArr.length) setNoConfProducts(true);
    else {
      setCategories(categoryArr);
      setCategoryParents(_categoryParents);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className={classes.container}>
        <CircularLoader show />
      </div>
    );
  }
  if (noConfProducts) {
    return (
      <div className={classes.container}>
        <div>This campaign does not have any configurable products</div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <div>
        <TextField
          select
          label="Select a category"
          value={categoryId}
          className={classes.categorySelect}
          onChange={({ target }) => {
            setParentId(null);
            setCategoryId(target.value);
          }}
        >
          {categories.map(({ id, name }) => (
            <MenuItem key={id as number} value={id as number}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </div>

      {categoryId && categoryParents[categoryId] && (
        <div className={classes.parentCards}>
          {categoryParents[categoryId].map(({ id, image, product_name }) => (
            <div
              key={id as number}
              className={classes.parentCard}
              onClick={() => setParentId(id as number)}
            >
              <img src={image as string} className={classes.parentImg} />
              <div className={classes.parentName}>{product_name}</div>
            </div>
          ))}
        </div>
      )}

      <Modal
        campaign_id={campaign_id}
        parentId={parentId}
        setParentId={setParentId}
        fetchData={fetchData}
      />
    </div>
  );
}

type ModalProps = {
  campaign_id: any;
  parentId: number | null;
  setParentId: React.Dispatch<React.SetStateAction<number | null>>;
  fetchData: () => any;
};
function Modal({ parentId, setParentId, campaign_id, fetchData }: ModalProps) {
  const classes = styles();
  const dispatch = useDispatch();
  const [variants, setVariants] = useState([]);
  const [variantId, setVariantId] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setVariants([]);
    if (!campaign_id || !parentId) return;
    const fetch = async () => {
      const url = `/campaign_products/store/configurable_product_variants?campaign_id=${campaign_id}&product_id=${parentId}`;
      const res = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = res;
      if (error) return dispatch(setToast(errorMessage));
      setVariants(data.variants);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id, parentId]);

  const reset = () => {
    setSaving(false);
    setImageUrl(null);
    setVariantId("");
    setParentId(null);
  };

  const onSave = async () => {
    setSaving(true);
    const post = {
      campaign_id,
      configurable_product_id: Number(parentId),
      variant_product_id: Number(variantId),
      imageUrl,
    };
    const url = "/campaign_products/update_configurable_default_variant";
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      setSaving(false);
      return;
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    reset();
    fetchData();
  };

  return (
    <Dialog
      open={Boolean(parentId)}
      maxWidth={false}
      onClose={() => setParentId(null)}
    >
      <div className={classes.modal}>
        <div className={classes.title}>Update default variant</div>
        <div>
          <DialogContentText>Select a variant</DialogContentText>
          {nonEmptyArray(variants) && (
            <RadioGroup
              value={variantId}
              onChange={({ target }) => setVariantId(target.value)}
              className={classes.radioGroup}
            >
              {variants.map((v: any, index: number) => {
                const {
                  id: variantProductId,
                  image,
                  product_name,
                  sku,
                  is_default_variant,
                } = v;
                return (
                  <div
                    key={index}
                    className={classNames(
                      classes.radioOption,
                      is_default_variant && classes.default,
                    )}
                  >
                    <Radio
                      value={variantProductId}
                      checked={`${variantProductId}` === variantId}
                      onChange={() => setImageUrl(image)}
                      disabled={Boolean(is_default_variant)}
                    />

                    <div>
                      <img
                        src={image as string}
                        alt={product_name}
                        className={classes.radioImg}
                      />
                      <div className={classes.sku}>{sku}</div>
                    </div>
                  </div>
                );
              })}
            </RadioGroup>
          )}
        </div>
        <div className={classes.actions}>
          <Button
            onClick={() => setParentId(null)}
            color="secondary"
            variant="text"
          >
            Cancel
          </Button>

          <Button
            onClick={onSave}
            color="primary"
            className={classes.save}
            disabled={saving}
          >
            Save
            <ButtonSpinner show={saving} />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  categorySelect: {
    minWidth: 300,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  parentCards: {
    display: "flex",
    flexWrap: "wrap",
  },
  parentCard: {
    cursor: "pointer",
    padding: 10,
    border: `1px solid ${theme.palette.text.secondary2}`,
    borderRadius: 4,
    width: 120,
    marginRight: 12,
    marginBottom: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  parentImg: {
    maxHeight: 60,
    maxWidth: 60,
  },
  parentName: {
    textAlign: "center",
    fontSize: 12,
    lineHeight: "16px",
    marginTop: 8,
  },
  // MODAL
  modal: {
    width: "calc(100vw - 80px)",
    height: "calc(100vh - 80px)",
    maxWidth: "calc(100vw - 80px)",
    maxHeight: "calc(100vh - 80px)",
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 24,
  },
  radioGroup: {
    flexDirection: "row",
  },
  radioOption: {
    display: "flex",
    alignItems: "center",
    width: 200,
    maxWidth: 200,
    overflowWrap: "anywhere",
    marginRight: 10,
    marginBottom: 12,
  },
  radioImg: {
    maxWidth: 40,
    maxHeight: 40,
  },
  sku: {
    fontSize: 12,
  },
  default: {
    border: `1px solid ${theme.palette.text.secondary2}`,
    borderRadius: 4,
    backgroundColor: hexToRGBA(theme.palette.secondary2.main, 0.1),
    padding: "6px 6px 6px 0",
  },
  actions: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingBottom: 24,
    paddingTop: 24,
    minHeight: "fit-content",
  },
  save: {
    width: 80,
    marginLeft: 16,
  },
}));
