import { Fragment, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Admin, Resource } from "react-admin";
import {
  OrgList,
  OrgEdit,
  DashboardController,
  OrgShow,
  OrgCreate,
  CampaignCreate,
  CampaignEdit,
  SubmissionList,
  Profile,
  CampaignDetailsShow,
  RepList,
  RepCreate,
  RepShow,
  RepEdit,
  ProductSupplierList,
  ProductSupplierCreate,
  SupplierDetails,
  CompanyList,
  CompanyCreate,
  CompanyEdit,
  AuthenticateSuperAdminCompany,
  DigitalContentList,
  DigitalContentCreate,
  DigitalContentEdit,
  DigitalContentCodeList,
  DigitalContentCodeCreate,
  DigitalContentCodeEdit,
  DigitalContentInventoryGroupList,
  DigitalContentInventoryGroupCreate,
  DigitalContentInventoryGroupShow,
  CampaignList,
  SupplierCampaignList,
  CampaignOrderFulfillmentDetails,
  AllShipToHomeOrders,
  CompanyEmployeeList,
  CompanyEmployeeCreate,
  CompanyEmployeeEdit,
  LaunchPacketFieldList,
  LaunchPacketFieldCreate,
  LaunchPacketFieldEdit,
  ProductGroupList,
  ProductGroupDetails,
  CompanyProductGroupList,
  CompanyProductGroupDetails,
  SuperAdminTools,
  ProfitCategoryCompanyList,
  ProfitCategoryCompanyEdit,
  DonationGroupList,
  DonationGroupDetails,
  KnowledgeBase,
  DownloadList,
  CampaignReservationList,
  CampaignReservationCreate,
  CampaignReservationEdit,
  CompanySeasonList,
  CompanySeasonCreate,
  CompanySeasonEdit,
  GiftCardList,
  GiftCardCreate,
  GiftCardEdit,
  SupplierCompanyPrizesByProductList,
  CompanyPrizeProgramList,
  CompanyPrizeProgramDetails,
  CompanyDonationReceiptConfigs,
  SentEmailsList,
  AffiliateList,
  DeletedDonationList,
  CompanySuppliersList,
  Login,
} from "./views";
import {
  authProvider,
  customTheme,
  i18nProvider,
  dataProvider,
  history,
  isSuperAdmin,
  isCompany,
  isRep,
  isCompanyOrCompanyAdmin,
  isCompanyOrCompanyAdminOrRep,
  isCompanyOrCompanyAdminOrFulfiller,
  isGivvrCompany,
  isCompanyDataEntry,
  isJMSCompany,
  isUltimateCompany,
} from "./lib";
import CustomLayout from "./layout/CustomLayout";
import SubmissionIcon from "@material-ui/icons/Email";
import SchoolIcon from "@material-ui/icons/School";
import RepsIcon from "@material-ui/icons/People";
import TruckIcon from "@material-ui/icons/LocalShipping";
import BusinessIcon from "@material-ui/icons/Business";
import ListIcon from "@material-ui/icons/List";
import ComputerIcon from "@material-ui/icons/Computer";
import RedeemIcon from "@material-ui/icons/Redeem";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import EmployeesIcon from "@material-ui/icons/PeopleOutline";
import FieldsIcon from "@material-ui/icons/TextFields";
import AppsIcon from "@material-ui/icons/Apps";
import ProfitIcon from "@material-ui/icons/LocalAtm";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import GetAppIcon from "@material-ui/icons/GetApp";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { store } from "./lib/store";
import { NewAppRoutes } from "./newApp/newAppRoutes";
import {
  getCompanyAppleTouchHref,
  getCompanyFavicon16Href,
  getCompanyFavicon32Href,
} from "./newApp/lib";
import { DiscountCardManagement } from "./newApp/pages/discountCardManagement/DiscountCardManagement";
import { MaterialLibrary } from "./newApp/pages/materialLibrary/MaterialLibrary";
import { CommunicationLibrary } from "./newApp/pages/communicationLibrary/CommunicationLibrary";
import { CompanyDonations } from "./newApp/pages/donations/companyDonations/CompanyDonations";

const customRoutes = [
  <Route key="2" exact path="/knowledge_base" component={KnowledgeBase} />,
  <Route key="3" exact path="/profile" component={Profile} />,
  <Route
    key="5"
    exact
    path="/authenticate-super-admin-company"
    noLayout
    component={AuthenticateSuperAdminCompany}
  />,
  <Route key="8" exact path="/super-admin-tools" component={SuperAdminTools} />,
  <Route key="9" exact path="/login" component={Login} />,
  <Route
    key="10"
    exact
    path="/company_donation_receipt_config"
    component={CompanyDonationReceiptConfigs}
  />,
  <Route
    key="11"
    exact
    path="/discount_card_management"
    component={DiscountCardManagement}
  />,
  <Route key="12" exact path="/material_library" component={MaterialLibrary} />,
  <Route
    key="13"
    exact
    path="/communication_library"
    component={CommunicationLibrary}
  />,
  <Route
    key="14"
    exact
    path="/company_donations"
    component={CompanyDonations}
  />,
];

function App() {
  useEffect(() => {
    // TODO: for now using Instaraise's (companyId 1) logos for all users
    const icon32 = document.getElementById("icon32");
    icon32.href = getCompanyFavicon32Href(1);
    const icon16 = document.getElementById("icon16");
    icon16.href = getCompanyFavicon16Href(1);
    const ATI = document.getElementById("apple-touch-icon");
    ATI.href = getCompanyAppleTouchHref(1);
  }, []);

  return (
    <Fragment>
      {/* <CssBaseline /> */}
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route
              path="/admin"
              exact
              render={props => <CustomAdmin {...props} />}
            />
            <Route
              path="/admin/"
              exact
              render={props => <CustomAdmin {...props} />}
            />
            <Route
              path="/admin/*"
              exact
              render={props => <CustomAdmin {...props} />}
            />
            <NewAppRoutes />
          </Switch>
        </BrowserRouter>
      </Provider>
    </Fragment>
  );
}

function CustomAdmin() {
  return (
    <Admin
      title="Instaraise"
      disableTelemetry
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={customTheme}
      loginPage={Login}
      i18nProvider={i18nProvider}
      history={history}
      dashboard={DashboardController}
      layout={CustomLayout}
      customRoutes={customRoutes}
    >
      {(userInfo = {}) => {
        const {
          role,
          hasRepHierarchy,
          repImpersonatingGrandchild,
          supplier_id,
          companyId,
          usingGiftCardSites,
        } = userInfo;
        const hasDonationGroups =
          (isGivvrCompany(companyId) ||
            isJMSCompany(companyId) ||
            isUltimateCompany(companyId)) &&
          isCompanyOrCompanyAdmin(role);
        const isCoOrCoAdmin = isCompanyOrCompanyAdmin(role);

        return [
          <Resource
            key="1"
            name="orgs"
            list={isCompanyOrCompanyAdminOrRep(role) ? OrgList : null}
            options={{ label: "Organizations" }}
            create={isCompanyOrCompanyAdminOrRep(role) ? OrgCreate : null}
            edit={isCompanyOrCompanyAdminOrRep(role) ? OrgEdit : null}
            show={isCompanyOrCompanyAdminOrRep(role) ? OrgShow : null}
            icon={SchoolIcon}
          />,
          <Resource key="2" name="orgs/auto-complete" />,
          <Resource
            key="3"
            name="reps"
            list={
              isCompanyOrCompanyAdmin(role) ||
              (isRep(role) && hasRepHierarchy && !repImpersonatingGrandchild)
                ? RepList
                : null
            }
            options={{ label: "Reps" }}
            create={RepCreate}
            edit={RepEdit}
            show={RepShow}
            icon={RepsIcon}
          />,
          <Resource key="8b" name="volunteer_campaign_prizes/volunteer_list" />,
          <Resource key="10" name="gateway_providers" />,
          <Resource key="11" name="campaign_ticket_packages" />,
          <Resource key="12" name="campaign_types" />,
          <Resource key="13" name="campaigns/payment-info" />,
          <Resource key="14" name="orgs/impersonate" />,
          <Resource key="19" name="campaigns/org_campaigns" />,
          <Resource
            key="20"
            name="campaigns"
            list={
              isCompanyOrCompanyAdminOrRep(role) || isCompanyDataEntry(role)
                ? CampaignList
                : null
            }
            create={isCompanyOrCompanyAdminOrRep(role) ? CampaignCreate : null}
            edit={isCompanyOrCompanyAdminOrRep(role) ? CampaignEdit : null}
            // show={
            //   isCompanyOrCompanyAdminOrRep(role) ? CampaignDetailsShow : null
            // }
            // need to always define this component for links to work on logged out users
            show={CampaignDetailsShow}
            options={{ label: "Campaigns" }}
            icon={EmojiEventsIcon}
          />,
          <Resource
            key="21"
            name="campaign_reservations"
            list={
              isCompanyOrCompanyAdminOrRep(role)
                ? CampaignReservationList
                : null
            }
            create={
              isCompanyOrCompanyAdminOrRep(role)
                ? CampaignReservationCreate
                : null
            }
            edit={
              isCompanyOrCompanyAdminOrRep(role)
                ? CampaignReservationEdit
                : null
            }
            options={{ label: "Campaign Reservations" }}
            icon={EventSeatIcon}
          />,
          <Resource
            key="22"
            name="submissions"
            list={isCompanyOrCompanyAdmin(role) ? SubmissionList : null}
            icon={SubmissionIcon}
            options={{ label: "Submissions" }}
          />,
          <Resource key="23" name="dashboards" />,
          // this needs to be before product_suppliers
          <Resource key="24" name="product_suppliers/expanded" />,
          <Resource
            key="25"
            name="product_suppliers"
            list={isSuperAdmin(role) ? ProductSupplierList : null}
            options={{ label: "Product Suppliers" }}
            create={isSuperAdmin(role) ? ProductSupplierCreate : null}
            show={
              isSuperAdmin(role) || isCompanyOrCompanyAdmin(role)
                ? SupplierDetails
                : null
            }
            icon={TruckIcon}
          />,
          <Resource key="26" name="product_categories" />,
          <Resource key="27" name="campaign/products" />,
          <Resource key="28" name="products" />,
          <Resource key="33" name="product_sources" />,
          <Resource key="34" name="product_warehouses" />,
          <Resource key="35" name="product_source_purchase_orders" />,
          <Resource key="36" name="inventory/products" />,
          <Resource key="37" name="product_wholesale_prices" />,
          <Resource key="38" name="inventory/back_order_products" />,
          <Resource key="39" name="inventory/back_order_campaigns" />,
          <Resource
            key="40"
            name="companies"
            list={isSuperAdmin(role) ? CompanyList : null}
            options={{ label: "Companies" }}
            create={isSuperAdmin(role) ? CompanyCreate : null}
            edit={isSuperAdmin(role) ? CompanyEdit : null}
            icon={BusinessIcon}
          />,
          <Resource key="41" name="campaign_defaults" />,
          <Resource
            key="42"
            name="digital_content"
            list={isSuperAdmin(role) ? DigitalContentList : null}
            options={{ label: "Digital Content" }}
            create={isSuperAdmin(role) ? DigitalContentCreate : null}
            edit={isSuperAdmin(role) ? DigitalContentEdit : null}
            icon={ComputerIcon}
          />,
          <Resource
            key="43"
            name="digital_content_codes"
            list={isSuperAdmin(role) ? DigitalContentCodeList : null}
            options={{ label: "Digital Content Codes" }}
            create={isSuperAdmin(role) ? DigitalContentCodeCreate : null}
            edit={isSuperAdmin(role) ? DigitalContentCodeEdit : null}
            icon={RedeemIcon}
          />,
          <Resource
            key="44"
            name="digital_content_inventory_groups"
            list={isSuperAdmin(role) ? DigitalContentInventoryGroupList : null}
            options={{ label: "Digital Content Groups" }}
            create={
              isSuperAdmin(role) ? DigitalContentInventoryGroupCreate : null
            }
            show={isSuperAdmin(role) ? DigitalContentInventoryGroupShow : null}
            edit={null}
            icon={LocalGroceryStoreIcon}
          />,
          <Resource key="45" name="digital_content_inventory_group_products" />,
          <Resource
            key="46"
            name="company_employees"
            list={isCompany(role) ? CompanyEmployeeList : null}
            options={{ label: "Employees" }}
            create={isCompany(role) ? CompanyEmployeeCreate : null}
            edit={isCompany(role) ? CompanyEmployeeEdit : null}
            icon={EmployeesIcon}
          />,
          <Resource
            key="47"
            name="campaign_launch_packet_fields"
            list={isCompanyOrCompanyAdmin(role) ? LaunchPacketFieldList : null}
            create={
              isCompanyOrCompanyAdmin(role) ? LaunchPacketFieldCreate : null
            }
            edit={isCompanyOrCompanyAdmin(role) ? LaunchPacketFieldEdit : null}
            icon={FieldsIcon}
            options={{ label: "Launch Packet Fields" }}
          />,
          <Resource
            key="49"
            name="company_product_suppliers"
            list={isCompanyOrCompanyAdmin(role) ? CompanySuppliersList : null}
            options={{ label: "Co. Suppliers" }}
            icon={AllInclusiveIcon}
          />,
          // this has to go before supplier_product_groups
          <Resource
            key="49b"
            name="supplier_product_groups/company_groups"
            list={
              isCompanyOrCompanyAdmin(role) ? CompanyProductGroupList : null
            }
            show={
              isCompanyOrCompanyAdmin(role) ? CompanyProductGroupDetails : null
            }
            options={{ label: "Co. Product Groups" }}
            icon={AppsIcon}
          />,
          <Resource
            key="50"
            name="profit_category_companies"
            list={
              isCompanyOrCompanyAdmin(role) ? ProfitCategoryCompanyList : null
            }
            edit={
              isCompanyOrCompanyAdmin(role) ? ProfitCategoryCompanyEdit : null
            }
            options={{ label: "Co. Profit Categories" }}
            icon={ProfitIcon}
          />,
          <Resource
            key="51"
            name="company_seasons"
            list={isCompanyOrCompanyAdmin(role) ? CompanySeasonList : null}
            create={isCompanyOrCompanyAdmin(role) ? CompanySeasonCreate : null}
            edit={isCompanyOrCompanyAdmin(role) ? CompanySeasonEdit : null}
            options={{ label: "Co. Seasons" }}
            icon={BeachAccessIcon}
          />,
          <Resource
            key="51b"
            name="company_prize_programs"
            list={
              isCompanyOrCompanyAdmin(role) ? CompanyPrizeProgramList : null
            }
            show={
              isCompanyOrCompanyAdmin(role) ? CompanyPrizeProgramDetails : null
            }
            options={{ label: "Co. Prize Programs" }}
            icon={SentimentSatisfiedAltIcon}
          />,
          <Resource
            key="51c"
            name="campaign_emails"
            list={isCompanyOrCompanyAdmin(role) ? SentEmailsList : null}
            options={{ label: "Co. Sent Emails" }}
            icon={MarkunreadMailboxIcon}
          />,
          <Resource
            key="52"
            name="gift_cards"
            list={isCoOrCoAdmin && usingGiftCardSites ? GiftCardList : null}
            create={isCoOrCoAdmin && usingGiftCardSites ? GiftCardCreate : null}
            edit={isCoOrCoAdmin && usingGiftCardSites ? GiftCardEdit : null}
            options={{ label: "Gift Cards" }}
            icon={CardGiftcardIcon}
          />,
          <Resource
            key="53"
            name="order_fulfillment/home"
            list={
              isCompanyOrCompanyAdminOrFulfiller(role) && supplier_id
                ? AllShipToHomeOrders
                : null
            }
            options={{ label: "Ship To Home Orders" }}
            icon={TruckIcon}
          />,
          <Resource key="54" name="order_fulfillment/by_campaign/home" />,
          <Resource key="55" name="order_fulfillment/by_campaign/school" />,
          <Resource
            key="56"
            name="order_fulfillment/campaigns"
            list={
              isCompanyOrCompanyAdminOrFulfiller(role) && supplier_id
                ? SupplierCampaignList
                : null
            }
            show={
              isCompanyOrCompanyAdminOrFulfiller(role) && supplier_id
                ? CampaignOrderFulfillmentDetails
                : null
            }
            options={{ label: "Campaign Fulfillment" }}
            icon={LocalGroceryStoreIcon}
          />,
          <Resource
            key="57"
            name="supplier_product_groups"
            list={
              isSuperAdmin(role) ||
              (isCompanyOrCompanyAdmin(role) && supplier_id)
                ? ProductGroupList
                : null
            }
            show={
              isSuperAdmin(role) ||
              (isCompanyOrCompanyAdmin(role) && supplier_id)
                ? ProductGroupDetails
                : null
            }
            options={{ label: "Supplier Product Groups" }}
            icon={ListIcon}
          />,
          <Resource
            key="57b"
            name="supplier_company_prize_products/grouped_by_product"
            list={
              isCompanyOrCompanyAdmin(role) && supplier_id
                ? SupplierCompanyPrizesByProductList
                : null
            }
            options={{ label: "Supplier-Company Prizes" }}
            icon={AccountTreeIcon}
          />,
          <Resource
            key="58"
            name="supplier_product_groups/group_curr_and_available_products"
          />,
          <Resource
            key="59"
            name="supplier_product_groups/company_group_products"
          />,
          // this has to be before campaign_management
          <Resource key="60" name="campaign_management/supplier_controlled" />,
          <Resource key="61" name="campaign_management" />,
          <Resource key="62" name="profit_categories" />,
          <Resource key="63" name="profit_category_campaigns" />,
          <Resource
            key="64"
            name="supplier_product_group_company_campaign_defaults"
          />,
          <Resource
            key="65"
            name="donation_groups"
            list={hasDonationGroups ? DonationGroupList : null}
            show={hasDonationGroups ? DonationGroupDetails : null}
            options={{ label: "Co. Donation Groups" }}
            icon={GroupWorkIcon}
          />,
          <Resource key="66" name="donation_group_items" />,
          <Resource key="67" name="donation_group_campaign_defaults" />,
          <Resource key="68" name="campaign_donation_groups" />,
          <Resource key="69" name="campaign_donation_group_items" />,
          <Resource key="70" name="product_suggestions" />,
          <Resource
            key="72"
            name="downloads"
            list={isCompanyOrCompanyAdmin(role) ? DownloadList : null}
            options={{ label: "Downloads", hide: true }}
            icon={GetAppIcon}
          />,
          <Resource key="73" name="campaign_showcase_items" />,
          <Resource key="74" name="company_prize_program_tiers" />,
          <Resource key="75" name="company_prize_program_tier_prize_levels" />,
          <Resource key="76" name="company_prize_program_tier_rules" />,
          <Resource key="77" name="company_prize_program_reps" />,
          <Resource key="78" name="supplier_product_group_company_reps" />,
          <Resource key="79" name="event_groups" />,
          <Resource key="80" name="event_group_events" />,
          <Resource key="81" name="campaign_event_groups" />,
          <Resource
            key="82"
            name="affiliates/stats"
            list={isCompanyOrCompanyAdminOrRep(role) ? AffiliateList : null}
            options={{ label: "Online Performance" }}
            icon={TrendingUpIcon}
          />,
          <Resource
            key="84"
            name="deleted_donations"
            list={isCompanyOrCompanyAdmin(role) ? DeletedDonationList : null}
            icon={MoneyOffIcon}
            options={{ label: "Deleted Sales" }}
          />,
        ];
      }}
    </Admin>
  );
}

export default App;
