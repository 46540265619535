import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";
import { routerMiddleware, connectRouter } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { adminReducer, adminSaga, USER_LOGOUT } from "react-admin";
import {
  dashboardReducers,
  profileReducers,
  userReducers,
} from "../customState";
import {
  uiReducers,
  contactReducers,
  campaignReducers,
  volunteerSetupReducers,
  dashboardDataReducers,
  volunteerReducers,
  discountCardReducers,
  eventReducers,
  repReducers,
  materialReducers,
  communicationReducers,
  giveawayReducers,
  companyReducers,
} from "../newApp/state";
import { authProvider } from "./authProvider";
import { dataProvider } from "./dataProvider";
import { history } from "./history";
const _dataProvider = dataProvider as any;
const _authProvider = authProvider as any;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

const reducer = combineReducers({
  admin: adminReducer,
  router: connectRouter(history),
  dashboard: dashboardReducers,
  profile: profileReducers,
  user: userReducers,
  // NEW APP SLICES
  volunteerSetup: volunteerSetupReducers,
  ui: uiReducers,
  contacts: contactReducers,
  campaign: campaignReducers,
  dashboardData: dashboardDataReducers,
  volunteer: volunteerReducers,
  discountCard: discountCardReducers,
  events: eventReducers,
  reps: repReducers,
  material: materialReducers,
  communication: communicationReducers,
  giveaway: giveawayReducers,
  company: companyReducers,
});

// needed for react-admin
const resettableAppReducer = (state: any, action: any) =>
  reducer(action.type !== USER_LOGOUT ? state : undefined, action);

// needed for react-admin
const saga = function* rootSaga() {
  yield all([adminSaga(_dataProvider, _authProvider)].map(fork));
};
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const store = createStore(
  resettableAppReducer,
  {
    /* set your initial state here */
  },
  composeEnhancers(
    applyMiddleware(sagaMiddleware, routerMiddleware(history), thunk),
  ),
);
sagaMiddleware.run(saga);

export { store };
