import { useState, Fragment, useEffect } from "react";
import { useField } from "react-final-form";
import { IconButton, makeStyles } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import FileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { nonEmptyArray } from "../../lib";

type Props = {
  fieldName: string;
  accept?: string[];
  currentFileName?: string;
};

export function SingleFileDropzoneField({
  fieldName,
  accept,
  currentFileName,
}: Props) {
  const input = useField(fieldName).input;
  const file = input.value as File;
  const classes = styles();
  const [fileName, setFileName] = useState<string>("");
  0;
  useEffect(() => {
    if (file) setFileName(file.name);
    else setFileName("");
  }, [file]);

  const onDrop = (fileArr: File[]) => {
    if (nonEmptyArray(fileArr)) {
      input.onChange(fileArr[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept ? accept : ["image/*", "application/pdf"],
    multiple: false,
    onDrop,
  });

  return (
    <Fragment>
      <div {...getRootProps()} className={classes.uploadContainer}>
        <input {...getInputProps()} />
        <span className={classes.click}>Click to upload</span>or drag and drop a
        file here
      </div>

      {fileName && (
        <div className={classes.filePreview}>
          <div className={classes.fileIconAndName}>
            <FileIcon />
            <div className={classes.fileName}>{fileName}</div>
          </div>
          <IconButton size="small" onClick={() => input.onChange(null)}>
            <DeleteIcon color="primary" />
          </IconButton>
        </div>
      )}

      {currentFileName && !fileName && (
        <div className={classes.filePreview}>
          <div className={classes.fileIconAndName}>
            <FileIcon />
            <a
              className={classes.currFileName}
              href={currentFileName}
              target="_blank"
              rel="noreferrer"
            >
              {currentFileName}
            </a>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  uploadContainer: {
    height: 64,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  click: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginRight: 6,
  },
  filePreview: {
    width: "100%",
    minHeight: 40,
    border: "1px solid #C9CDDE",
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 8,
    padding: "8px 16px",
  },
  fileIconAndName: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: 0.1,
    overflow: "hidden",
  },
  fileName: {
    marginLeft: 8,
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 12,
  },
  currFileName: {
    overflowWrap: "anywhere",
    marginLeft: 8,
    color: theme.palette.primary.main,
  },
}));
