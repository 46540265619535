import { TextField, makeStyles } from "@material-ui/core";
import { ListProps } from "../../../types";
import { FilterWrapper } from "./FilterWrapper";
import { format } from "date-fns";

type Props = {
  header: string;
  listProps: ListProps;
};
export function DateRangeFilter({ header, listProps }: Props) {
  const {
    getFilterValue,
    updateTempFilters,
    isActiveFilter,
    tempFilters,
  } = listProps;
  const { start_date, end_date } = tempFilters;
  const classes = styles();

  const onChange = (field: string, value: string) => {
    let chipText = "";
    const startStr = field === "start_date" ? value : start_date;
    const start = startStr
      ? format(new Date(`${startStr} 00:00:00`), "M/d/y")
      : "";
    const endStr = field === "end_date" ? value : end_date;
    const end = endStr ? format(new Date(`${endStr} 00:00:00`), "M/d/y") : "";
    if (start && end) {
      chipText = `${start} - ${end}`;
    } else if (start) {
      chipText = `From ${start}`;
    } else if (end) {
      chipText = `Through ${end}`;
    }
    updateTempFilters({
      field,
      value,
      customChipField: "dateRange",
      customChipFilterFields: ["start_date", "end_date"],
      chipText,
      checkActiveChipByText: true,
    });
  };

  return (
    <FilterWrapper
      active={isActiveFilter("start_date", "end_date")}
      header={header}
    >
      <div className={classes.dates}>
        <TextField
          label="Start date"
          type="date"
          value={getFilterValue("start_date")}
          onChange={({ target }) => onChange("start_date", target.value)}
          className={classes.dateInput}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End date"
          type="date"
          value={getFilterValue("end_date")}
          onChange={({ target }) => onChange("end_date", target.value)}
          className={classes.dateInput}
          InputLabelProps={{ shrink: true }}
        />
      </div>
    </FilterWrapper>
  );
}

const styles = makeStyles(_theme => ({
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  dateInput: {
    width: "calc(50% - 8px)",
    minWidth: "unset",
  },
}));
