import { APIAuthClient } from "../../lib";
import { APIRes } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

export const volunteerTypes = {
  CAMPAIGN_VOLUNTEER_AUTOCOMPLETE_SET: "CAMPAIGN_VOLUNTEER_AUTOCOMPLETE_SET",
  ORG_VOLUNTEER_AUTOCOMPLETE_SET: "ORG_VOLUNTEER_AUTOCOMPLETE_SET",
};

export function campaignVolunteerAutocomplete(campaignId: any): AppThunkAction {
  return async dispatch => {
    if (!campaignId) return;
    const url = `/campaigns/${campaignId}/volunteers/autocomplete_list`;
    const response = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = response;
    if (error) return dispatch(setToast(errorMessage));
    dispatch({
      type: volunteerTypes.CAMPAIGN_VOLUNTEER_AUTOCOMPLETE_SET,
      data,
    });
    return;
  };
}

export function orgVolunteerAutocomplete(): AppThunkAction {
  return async (dispatch, getState) => {
    const {
      campaign: { campaignId, org_id },
    } = getState();
    if (!campaignId || !org_id) return;
    const url = `/volunteers/autocomplete?org_id=${org_id}&campaign_id=${campaignId}`;
    const response = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = response;
    if (error) return dispatch(setToast(errorMessage));
    dispatch({
      type: volunteerTypes.ORG_VOLUNTEER_AUTOCOMPLETE_SET,
      data,
    });
    return;
  };
}
