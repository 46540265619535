import { useDispatch } from "react-redux";
import arrayMutators from "final-form-arrays";
import { FormApi } from "final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, MenuItem, makeStyles } from "@material-ui/core";
import { Form, Field, useField } from "react-final-form";
import { APIAuthClient, nonEmptyArray, requiredField } from "../../../../lib";
import {
  APIRes,
  DonationEventTickets,
  GenericObject,
  ToastTypes,
} from "../../../../types";
import { setToast } from "../../../../state";
import {
  ButtonSpinner,
  CircularLoader,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../../components";
import { useDrawerTransition, useIsMobile } from "../../../../hooks/ui";
import { useEffect, useState } from "react";
import { EventTicketTypes } from "../../../../types/eventTickets";
import classNames from "classnames";
type Seat = { seat_id: number; seat_identifier: string };
type Row = { row_id: number; row_identifier: string; seats: Seat[] };
type Section = {
  section_id: number;
  section_name: string;
  rows: { [rowKey: string]: Row };
};
type Sections = { [sectionKey: string]: Section };

type Props = {
  onClose: () => void;
  refreshData: () => Promise<void>;
  eventTickets: DonationEventTickets;
  donation_id: any;
};

export function EditEventTickets({
  onClose: _onClose,
  refreshData,
  eventTickets,
  donation_id,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [sections, setSections] = useState<Sections>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!nonEmptyArray(eventTickets)) return;
    let errorMsg = "";
    let eventId: any = null;
    const events = {};
    const seats: GenericObject[] = [];
    eventTickets.forEach(t => {
      const { event_id, ticket_type, section_id, row_id, seat_id } = t;
      if (!events[event_id]) {
        events[event_id] = true;
        eventId = event_id;
      }
      if (ticket_type !== EventTicketTypes.ASSIGNED) {
        errorMsg =
          "At this time, we don't allow editing orders with tickets that are not reserved seats.";
        return;
      }
      seats.push({ section_id, row_id, seat_id });
    });

    if (Object.keys(events).length > 1) {
      errorMsg =
        "At this time, we don't allow editing orders with tickets for multiple events.";
    }

    if (errorMsg) setError(errorMsg);
    else if (eventId) fetchSections(eventId);

    setInitialValues({ seats });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTickets]);

  const fetchSections = async (event_id: any) => {
    const url = `/seating_map_sections/autocomplete?event_id=${event_id}`;
    const response = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = response;
    if (error) return dispatch(setToast(errorMessage));
    setSections(data);
    setLoading(false);
  };

  const onSubmit = async ({ seats }: any, _form: FormApi, complete: any) => {
    const seatIds: number[] = seats.map(({ seat_id }) => seat_id);
    const put = { donation_id, seatIds };
    const url = "/event_tickets/edit_donation_tickets";
    const res = await APIAuthClient.put<any, APIRes>(url, put);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Tickets updated", ToastTypes.success));
    refreshData();
    onClose();
  };

  if (!nonEmptyArray(eventTickets)) return <></>;
  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div>Switch seats</div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        {error && (
          <div className={classes.error}>
            <div>{error}</div>
          </div>
        )}

        {!error && loading && (
          <div className={classes.loadingWrapper}>
            <CircularLoader show />
          </div>
        )}

        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, form, pristine }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className={classNames(
                  classes.form,
                  loading && classes.hideForm,
                )}
              >
                <div className={classes.mainContent}>
                  <div className={classes.info}>
                    Customer will receive an email with updated tickets.
                  </div>
                  <div className={classes.warning}>
                    Warning: Changing ANY ticket within this order will
                    invalidate ALL original tickets in this order and they will
                    ALL be replaced with new tickets.
                  </div>
                  <Seats sections={sections} />
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}

                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting || pristine}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}
type SeatsProps = {
  sections: Sections;
};
function Seats({ sections }: SeatsProps) {
  const classes = styles();

  return (
    <FieldArray name="seats">
      {({ fields }) => (
        <div>
          {fields.map((row, index) => {
            const { section_id, row_id } = fields.value[index];
            const notLast = index < (fields.length || 0) - 1;
            return (
              <div key={index}>
                <div
                  className={classNames(
                    classes.row,
                    notLast && classes.rowSpacer,
                  )}
                >
                  <SectionChangeListener rowName={row} />
                  <RowChangeListener rowName={row} />
                  <Field
                    select
                    component={TextFieldWrapper}
                    name={`${row}.section_id`}
                    label="Section"
                    validate={requiredField}
                    className={classes.sectionInput}
                  >
                    {Object.keys(sections).map(sectionId => {
                      const { section_id, section_name } = sections[sectionId];
                      return (
                        <MenuItem key={section_id} value={section_id}>
                          {section_name}
                        </MenuItem>
                      );
                    })}
                  </Field>

                  {section_id && sections[section_id] && (
                    <Field
                      select
                      component={TextFieldWrapper}
                      name={`${row}.row_id`}
                      label="Row"
                      validate={requiredField}
                      className={classes.identifierInput}
                    >
                      {Object.keys(sections[section_id].rows).map(rowId => {
                        const { row_id, row_identifier } = sections[
                          section_id
                        ].rows[rowId];
                        return (
                          <MenuItem key={row_id} value={row_id}>
                            {row_identifier}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  )}

                  {row_id &&
                    sections[section_id] &&
                    sections[section_id].rows[row_id] &&
                    nonEmptyArray(sections[section_id].rows[row_id].seats) && (
                      <Field
                        select
                        component={TextFieldWrapper}
                        name={`${row}.seat_id`}
                        label="Seat"
                        validate={requiredField}
                        className={classes.identifierInput}
                      >
                        {sections[section_id].rows[row_id].seats.map(seat => {
                          const { seat_id, seat_identifier } = seat;
                          return (
                            <MenuItem key={seat_id} value={seat_id}>
                              {seat_identifier}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    )}
                  {/* ADD A BLANK DIV FOR PROPER SPACING */}
                  {!row_id && <div className={classes.identifierInput} />}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </FieldArray>
  );
}

function SectionChangeListener({ rowName }: { rowName: string }) {
  const seatsInput = useField(`${rowName}.seat_id`).input;
  const rowInput = useField(`${rowName}.row_id`).input;
  return (
    <OnChange name={`${rowName}.section_id`}>
      {() => {
        seatsInput.onChange("");
        rowInput.onChange("");
      }}
    </OnChange>
  );
}

function RowChangeListener({ rowName }: { rowName: string }) {
  const seatsInput = useField(`${rowName}.seat_id`).input;
  return (
    <OnChange name={`${rowName}.row_id`}>
      {() => {
        seatsInput.onChange("");
      }}
    </OnChange>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 700,
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  loadingWrapper: {
    paddingTop: 50,
  },
  error: {
    padding: "50px 24px",
    color: theme.palette.error.light,
    fontSize: 16,
    textAlign: "center",
    fontWeight: 500,
    lineHeight: "21px",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 16px",
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    top: 0,
    padding: 24,
    borderBottom: "1px solid #DBDEEE",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
  },
  hideForm: {
    display: "none",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100vw",
    padding: "24px 24px 8px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0 0 0",
    },
  },
  info: {
    fontSize: 16,
    lineHeight: "22px",
    marginBottom: 16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  warning: {
    fontSize: 13,
    lineHeight: "18px",
    marginBottom: 24,
    textAlign: "center",
    color: theme.palette.error.dark,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "0 16px 16px 12px",
    },
  },
  rowSpacer: {
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #DBDEEE",
      marginBottom: 28,
    },
  },
  sectionInput: {
    width: "calc(44% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  identifierInput: {
    width: "calc(28% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  bottom: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
