import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { makeStyles, IconButton, Checkbox, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import RestoreIcon from "@material-ui/icons/RestoreFromTrashOutlined";
import { APIRes, GenericObject, ListProps, ToastTypes } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ConfirmationDialog,
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { APIAuthClient, formatMoney, nonEmptyArray } from "../../../lib";
import { EditCampaignProduct } from "./EditCampaignProduct";
import { useDispatch, useSelector } from "react-redux";
import {
  getCanCustomizeCampaignProducts,
  getCanRemoveOrRestoreCampaignProducts,
  setToast,
} from "../../../state";
const MIN_WIDTH = 1000;

type Props = {
  listProps: ListProps<any>;
  softDeleted: boolean;
};
export function CampaignProductRows({ listProps, softDeleted }: Props) {
  const {
    rows,
    params,
    setParams,
    allRowsSelected,
    nextPage,
    paginationProps,
    unselectAllRows,
    selectAllRows,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const canRemove = useSelector(getCanRemoveOrRestoreCampaignProducts);
  const canCustomize = useSelector(getCanCustomizeCampaignProducts);

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <div>
                <Checkbox
                  checked={allRowsSelected}
                  onChange={() => {
                    allRowsSelected ? unselectAllRows() : selectAllRows();
                  }}
                />
              </div>
              <SortableColumnHeader
                columnName="ID"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <div>Thumbnail</div>
              <SortableColumnHeader
                columnName="Sku"
                fieldName="sku"
                params={params}
                setParams={setParams}
              />
              <div>Categories</div>
              <SortableColumnHeader
                columnName="Name"
                fieldName="product_name"
                params={params}
                setParams={setParams}
              />
              <div>Price</div>
              <div>+Dig. codes</div>
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              listProps={listProps}
              canRemove={canRemove}
              canCustomize={canCustomize}
              softDeleted={softDeleted}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Products per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  listProps: ListProps;
  canRemove: boolean;
  canCustomize: boolean;
  softDeleted: boolean;
};
function Row({
  row,
  lastRow,
  listProps,
  canRemove,
  canCustomize,
  softDeleted,
}: RowProps) {
  const { refreshList, selectedRows, toggleSelectedRow } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const {
    id,
    campaign_id,
    categories,
    digital_content_addition_code_qty,
    digital_content_addition_id,
    thumbnail_image,
    product_name,
    product_price,
    sku,
  } = row;

  const onEditClose = (refresh: boolean) => {
    setShowEdit(false);
    if (refresh) refreshList();
  };

  const onRemove = async (
    setShowSubmitting: Dispatch<SetStateAction<boolean>>,
  ) => {
    setShowSubmitting(true);
    const url = "/campaign_products/bulk_delete";
    const body = { campaign_id, productIds: [id] };
    const res = await APIAuthClient.put<any, APIRes>(url, body);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return setShowSubmitting(false);
    }
    dispatch(setToast("Product Removed", ToastTypes.success));
    refreshList();
  };

  const onRestore = async (
    setShowSubmitting: Dispatch<SetStateAction<boolean>>,
  ) => {
    setShowSubmitting(true);
    const url = "/campaign_products/bulk_restore";
    const body = { campaign_id, productIds: [id] };
    const res = await APIAuthClient.put<any, APIRes>(url, body);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return setShowSubmitting(false);
    }
    dispatch(setToast("Product restored", ToastTypes.success));
    refreshList();
  };

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>
              <Checkbox
                checked={Boolean(selectedRows[id as number])}
                onChange={() => toggleSelectedRow(id as number, row)}
              />
            </div>
            <div>{id}</div>
            <div>
              <img
                src={thumbnail_image as string}
                className={classes.thumbnail}
                alt="Product"
              />
            </div>
            <div>{sku}</div>
            <div className={classes.chips}>
              {nonEmptyArray(categories) &&
                (categories as any[]).map(({ name }) => (
                  <div key={name} className={classes.chipWrapper}>
                    <div className={classes.chip}>{name}</div>
                  </div>
                ))}
            </div>
            <div>{product_name}</div>
            <div>{formatMoney(product_price)}</div>
            <div>
              {digital_content_addition_id &&
                `#${digital_content_addition_id} - ${digital_content_addition_code_qty} codes`}
            </div>

            <div className={classes.actions}>
              {!softDeleted && (
                <Fragment>
                  <IconButton
                    onClick={() => setShowEdit(true)}
                    disabled={!canCustomize}
                  >
                    <EditIcon color={canCustomize ? "primary" : "disabled"} />
                  </IconButton>
                  <IconButton
                    onClick={() => setShowRemove(true)}
                    disabled={!canRemove}
                  >
                    <DeleteIcon color={canRemove ? "error" : "disabled"} />
                  </IconButton>
                </Fragment>
              )}
              {softDeleted && (
                <Button
                  color="primary"
                  startIcon={<RestoreIcon />}
                  size="small"
                  onClick={() => setShowRestore(true)}
                  disabled={!canRemove}
                >
                  Restore
                </Button>
              )}
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div>#{id}</div>
              <div className={classes.sku}>Sku: {sku}</div>
              <div className={classes.name}>{product_name}</div>
              <div>{formatMoney(product_price)}</div>
            </div>

            {!softDeleted && (
              <div className={classes.mobileActions}>
                <div>
                  <IconButton
                    onClick={() => setShowEdit(true)}
                    disabled={!canCustomize}
                  >
                    <EditIcon color={canCustomize ? "primary" : "disabled"} />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    onClick={() => setShowRemove(true)}
                    disabled={!canRemove}
                  >
                    <DeleteIcon color={canRemove ? "error" : "disabled"} />
                  </IconButton>
                </div>
              </div>
            )}
            {softDeleted && (
              <div className={classes.mobileRestoreWrapper}>
                <IconButton
                  onClick={() => setShowRestore(true)}
                  disabled={!canRemove}
                >
                  <RestoreIcon color={canRemove ? "primary" : "disabled"} />
                </IconButton>
              </div>
            )}
          </Fragment>
        )}
      </div>
      {showEdit && <EditCampaignProduct onClose={onEditClose} record={row} />}
      {showRemove && (
        <ConfirmationDialog
          title={`Are you sure you want to remove ${product_name} from this campaign?`}
          subtitle="(If this is a configurable product we will remove all its variants)"
          actionFunc={onRemove}
          onClose={() => setShowRemove(false)}
          redButton
          actionLabel="Remove"
        />
      )}
      {showRestore && (
        <ConfirmationDialog
          onClose={() => setShowRestore(false)}
          title={`Are you sure you want to restore ${product_name} to this campaign?`}
          subtitle="(If this is a configurable product we will restore all its variants)"
          actionLabel="Restore"
          actionFunc={onRestore}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "56px 72px 96px .5fr 1fr 1fr 118px 150px 112px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      alignItems: "stretch",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  thumbnail: {
    maxWidth: 60,
    maxHeight: 60,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chipWrapper: {
    height: 32,
    borderRadius: 16,
    backgroundColor: "#E0E0E0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 12px",
    overflow: "hidden",
    marginRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
  chip: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    fontSize: 13,
    letterSpacing: 0.15,
  },
  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  mobileLeft: {
    flex: 1,
  },
  mobileActions: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    minHeight: "100%",
  },
  mobileRestoreWrapper: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
    display: "flex",
    alignItems: "center",
    flex: 1,
    minHeight: "100%",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      paddingBottom: 4,
    },
  },
  sku: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
}));
