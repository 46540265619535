import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton,
  TextField,
  BooleanInput,
  NumberInput,
  regex,
  SelectInput,
  email,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import {
  shippingCalculatedByChoices,
  shippingRateTypeOptions,
  shippingCalculatedByOptions,
  TWO_DECIMAL_REGEX,
  thirdPartyProcessorOptions,
} from "../../lib";
import { useSelector } from "react-redux";
import { getIsSuperAdminOrSuperAdminImpersonator } from "../../customState";

const requiredInput = [required()];
const validEmail = [email()];
const moneyInput = [regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only")];
const requiredMoneyInput = [
  regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only"),
  required(),
];

export function SupplierConfig({ id }) {
  const classes = styles();
  const isSA = useSelector(getIsSuperAdminOrSuperAdminImpersonator);

  const SupplierToolbar = props => (
    <Toolbar {...props}>
      <SaveButton redirect={false} />
    </Toolbar>
  );

  const transformDataForSubmit = values => {
    // remove fields for server-side validation
    const {
      id: _rm1,
      product_category_display_order: _rm2,
      product_display_order: _rm3,
      masked_shipstation_api_secret: _rm4,
      masked_third_party_processor_api_secret: _rm5,
      active_season: _rm6,
      active_season_id: _rm7,
      billing_address_id: _rm8,
      receiving_address_id: _rm9,
      ...rest
    } = values;
    return rest;
  };

  return (
    <Edit
      title=" "
      id={id}
      basePath=""
      resource="product_suppliers/expanded"
      undoable={false}
      successMessage="Config updated successfully"
      transform={transformDataForSubmit}
    >
      <SimpleForm toolbar={<SupplierToolbar />}>
        <TextInput source="billing_contact_name" />
        <TextInput source="billing_phone" />
        <TextInput source="billing_email" validate={validEmail} />
        <TextInput source="billing_address" />
        <TextInput source="billing_city" />
        <TextInput source="billing_state" />
        <TextInput source="billing_zip" />
        <TextInput source="receiving_contact_name" />
        <TextInput source="receiving_phone" />
        <TextInput source="receiving_email" validate={validEmail} />
        <TextInput source="receiving_address" />
        <TextInput source="receiving_city" />
        <TextInput source="receiving_state" />
        <TextInput source="receiving_zip" />
        <SelectInput
          source="shipping_calculated_by"
          label="Shipping Calculated By"
          choices={shippingCalculatedByOptions}
          translateChoice={false}
          validate={requiredInput}
          disabled={!isSA}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { shipping_calculated_by } = formData;
            const isLiveRate =
              shipping_calculated_by === shippingCalculatedByChoices.LIVE_RATES;
            return (
              <Fragment>
                <SelectInput
                  source="shipping_rate_type"
                  {...rest}
                  label={
                    isLiveRate
                      ? "Additional Shipping Fee (added to live rate) Rate Type"
                      : "Shipping Rate Type"
                  }
                  choices={shippingRateTypeOptions}
                  translateChoice={false}
                  validate={requiredInput}
                  className={classes.wideInput}
                  disabled={!isSA}
                />
                <div />
                {isLiveRate && (
                  <NumberInput
                    source="live_rate_packaging_weight_ounces"
                    label="Packaging Weight Ounces - For Live Rate Quote (integers only)"
                    min={0}
                    validate={requiredInput}
                    className={classes.wideInput}
                    disabled={!isSA}
                  />
                )}
                <div />
                {!isLiveRate && (
                  <TextInput
                    source="default_shipping_price"
                    type="number"
                    label="Default Shipping Price"
                    validate={requiredMoneyInput}
                    disabled={!isSA}
                  />
                )}
              </Fragment>
            );
          }}
        </FormDataConsumer>

        <Fragment>
          <div className={classes.warning}>
            IMPORTANT! If you are not using this feature please leave this field
            blank. Do NOT set to zero. or all your shipping will be free.
          </div>
        </Fragment>
        <TextInput
          source="free_shipping_from_amount"
          type="number"
          label="Free shipping when sale (+ donation) amount reaches"
          validate={moneyInput}
          fullWidth
          disabled={!isSA}
        />

        <SelectInput
          source="third_party_processor"
          label="Third Party Processor"
          choices={thirdPartyProcessorOptions}
          translateChoice={false}
          disabled={!isSA}
        />
        <TextInput
          source="third_party_processor_api_url"
          label="Third Party Processor Api URL (no trailing /)"
          className={classes.medInput}
          disabled={!isSA}
        />
        <TextInput
          source="third_party_processor_api_key"
          label="Third Party Processor Api Key"
          disabled={!isSA}
        />
        <TextField
          source="masked_third_party_processor_api_secret"
          label="Current Third Party Processor Api Secret"
          formClassName={classes.inlineField}
          disabled={!isSA}
        />
        <TextInput
          source="third_party_processor_api_secret"
          label="Update Third Party Processor Api Secret"
          className={classes.medInput}
          formClassName={classes.inlineField}
          disabled={!isSA}
        />

        <Fragment>
          <div className={classes.warning}>
            IMPORTANT! Before you add your Shipstation credentials you need to
            setup a webhook on your account, please reach out to Instaraise
            Support for help.
          </div>
        </Fragment>
        <NumberInput
          source="shipstation_store_id"
          label="shipstation Store ID"
          type="number"
          disabled={!isSA}
        />
        <TextInput
          source="shipstation_api_key"
          label="shipstation Api Key"
          disabled={!isSA}
        />
        <TextField
          source="masked_shipstation_api_secret"
          label="Current Shipstation Api Secret"
          formClassName={classes.inlineField}
        />
        <BooleanInput
          source="remove_shipstation_api_secret"
          label="Remove"
          formClassName={classes.removeInput}
          disabled={!isSA}
        />
        <TextInput
          source="shipstation_api_secret"
          label="Update Shipstation Api Secret"
          formClassName={classes.inlineField}
          disabled={!isSA}
        />
        <Fragment>
          <div className={classes.warning}>
            IMPORTANT! Only use this if you DON'T use Instaraise fulfillment and
            you use a supported Third-Party processor
          </div>
        </Fragment>
        <BooleanInput
          source="send_orders_to_third_party_processor_at_purchase"
          label="Send Orders To Third Party Processor At Purchase"
          className={classes.fitContent}
          disabled={!isSA}
        />
        <BooleanInput
          source="no_po_boxes"
          label="No PO Boxes allowed (on STH orders)"
          className={classes.fitContent}
          disabled={!isSA}
        />
        <BooleanInput
          source="print_sts_labels_at_fulfillment"
          label="Print STS Labels At Fulfillment"
          className={classes.fitContent}
          disabled={!isSA}
        />
      </SimpleForm>
    </Edit>
  );
}

const styles = makeStyles(
  _theme => ({
    inlineField: {
      display: "inline-flex",
      marginRight: "8px",
    },
    removeInput: {
      display: "inline-flex",
      marginRight: "8px",
      width: 120,
    },
    warning: {
      color: "red",
    },
    wideInput: { width: 500 },
    medInput: { width: 360 },
    fitContent: { width: "fit-content" },
  }),
  { classNamePrefix: "supplierConfig" },
);
