import { useEffect, useState } from "react";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDownOutlined";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsDesktop, useIsMobile, useScrollToTop } from "../../../hooks/ui";
import {
  companyDonationSortFields,
  companyDonationSearchFields,
  CompanyDonationFilterFields,
} from "../../../types";
import {
  FilterChips,
  FilterDrawerButton,
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
  ReactAdminNewAppWrapper,
} from "../../../components";
import { useSetTopBarTitle } from "../../../hooks/ui";
import { useList } from "../../../hooks/list";
import { getUserTimezone } from "../../../../lib";
import { CompanyDonationRows } from "./components/CompanyDonationRows";
import { NoResultsSVG } from "../../../assets";
import { CompanyDonationFilters } from "./components/CompanyDonationFilters";
import { CompanyDonationActionsMenu } from "./components/CompanyDonationActionsMenu";
import { useSelector } from "react-redux";
import { getSeasonId } from "../../../../customState";

export function CompanyDonations() {
  const classes = styles();
  const season_id = useSelector(getSeasonId);
  const [showActions, setShowActions] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [anchorEl, setAnchorEl] = useState<undefined | Element>(undefined);
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();

  useScrollToTop();

  const listProps = useList({
    baseUrl: "/donations/company_donations",
    isReady: true,
    initialState: {
      sort: "id",
      order: "desc",
      timezone: getUserTimezone(),
      season_id,
    },
    filterFields: CompanyDonationFilterFields,
  });
  const {
    listIsFiltered,
    total,
    noResults,
    refreshList,
    filterDrawerActive,
    showRows,
    setParams,
  } = listProps;

  useSetTopBarTitle(<h1 className={classes.title}>Sales</h1>);

  useEffect(() => {
    setParams(prev => {
      return { ...prev, season_id };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season_id]);

  const handleActionsClick = (e: any) => {
    setAnchorEl(e.currentTarget);
    setShowActions(true);
  };

  return (
    <ReactAdminNewAppWrapper>
      <ListContainer listProps={listProps}>
        {isMobile && (
          <div className={classes.topSection}>
            <div>
              <h1 className={classes.title}>Sales</h1>
              <div className={classes.donationCount}>
                {total} Sale
                {total !== 1 ? "s" : ""}
              </div>
            </div>
            <div>
              <IconButton onClick={() => setShowSort(true)}>
                <SwapVertIcon />
              </IconButton>
              <span className={classes.IconSpacer} />
              <FilterDrawerButton
                onClick={() => setShowFilter(true)}
                active={filterDrawerActive}
              />
              <span className={classes.IconSpacer} />
              <IconButton onClick={() => setShowActions(true)}>
                <MoreHorizIcon />
              </IconButton>
            </div>
          </div>
        )}

        <div className={classes.searchAndActionsRow}>
          <MultiSearch
            listProps={listProps}
            fields={companyDonationSearchFields}
          />
          {isDeskTop && (
            <div>
              <FilterDrawerButton
                onClick={() => setShowFilter(true)}
                active={filterDrawerActive}
              />
              <Button
                color="secondary"
                endIcon={<ArrowDropDownIcon />}
                className={classes.actionsButton}
                onClick={handleActionsClick}
              >
                More actions
              </Button>
            </div>
          )}
        </div>

        <FilterChips listProps={listProps} />

        {noResults && (
          <ListNoResults
            SVG={NoResultsSVG}
            listIsFiltered={listIsFiltered}
            label="Sales"
          />
        )}

        {showRows && <CompanyDonationRows listProps={listProps} />}

        <CompanyDonationActionsMenu
          isOpen={showActions}
          onClose={() => setShowActions(false)}
          anchorEl={anchorEl}
          refreshData={refreshList}
        />
        {showSort && (
          <MobileListSort
            onClose={() => setShowSort(false)}
            listProps={listProps}
            sortFields={companyDonationSortFields}
          />
        )}
        {showFilter && (
          <CompanyDonationFilters
            onClose={() => setShowFilter(false)}
            listProps={listProps}
          />
        )}
      </ListContainer>
    </ReactAdminNewAppWrapper>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0 8px 0",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
  },
  donationCount: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  IconSpacer: {
    marginRight: 8,
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  actionsButton: {
    width: 160,
    marginLeft: 16,
  },
}));
