import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
  SelectInput,
  ImageInput,
  ImageField,
  regex,
  NumberInput,
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  FormDataConsumer,
  useNotify,
  AutocompleteArrayInput,
} from "react-admin";
import { CustomRichText } from "../../components";
import { useSelector } from "react-redux";
import { getIsOwner } from "../../customState";
import {
  productTypeOptions,
  ownerProductTypeOptions,
  TWO_DECIMAL_REGEX,
  convertFileToBase64,
  productTypes,
  productStructureTypeOptions,
  productStructureTypes,
} from "../../lib";
import { styles } from "./Product.styles";

const validateName = [required(), minLength(2)];
const requiredInput = [required()];
const validMoney = regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only");
const requiredMoneyInput = [validMoney, required()];
const optionalMoneyInput = [validMoney];

export function ProductCreate(props) {
  const {
    supplier_id,
    closeAside,
    productSources,
    productWarehouses,
    profitCategories,
    productCategories,
  } = props;
  const basePath = `/product_suppliers/${supplier_id}/show`;
  const createProps = { resource: "products", basePath };
  const isOwner = useSelector(state => getIsOwner(state));
  const classes = styles();
  const notify = useNotify();

  const transformDataForSubmit = async values => {
    const { product_images: images, ...rest } = values;
    const product_images = [];
    if (Array.isArray(images)) {
      for (const i in images) {
        const { imageBase64, image_video_link } = images[i];
        const imageB64 = await convertFileToBase64(
          imageBase64,
          "imageBase64",
        ).catch(console.error);
        product_images.push({ ...imageB64, image_video_link });
      }
    }

    return { ...rest, product_images };
  };

  return (
    <Create
      {...createProps}
      transform={transformDataForSubmit}
      onSuccess={() => {
        notify(`Product added successfully`);
        closeAside();
      }}
    >
      <SimpleForm
        initialValues={{
          supplier_id,
          product_type: productTypes.PHYSICAL,
          weight_ounces: 0,
        }}
        submitOnEnter={false}
      >
        <AutocompleteArrayInput
          label="Categories"
          source="categoryIds"
          choices={productCategories}
          translateChoice={false}
          fullWidth
          validate={requiredInput}
        />
        <SelectInput
          source="source_id"
          label="Source"
          choices={productSources}
          translateChoice={false}
          resettable
        />
        <TextInput source="source_product_id" label="Source Product ID" />
        <TextInput
          source="source_price"
          type="number"
          validate={optionalMoneyInput}
          label="Source Product Price"
        />
        <SelectInput
          source="warehouse_id"
          label="Warehouse"
          choices={productWarehouses}
          translateChoice={false}
          resettable
        />
        <SelectInput
          source="profit_category_id"
          label="Profit Category"
          choices={profitCategories}
          translateChoice={false}
          validate={requiredInput}
        />
        <TextInput source="sku" validate={validateName} />
        <TextInput source="product_name" validate={validateName} />
        <SelectInput
          source="product_type"
          label="Product Type"
          choices={isOwner ? ownerProductTypeOptions : productTypeOptions}
          translateChoice={false}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const product_type = formData.product_type;
            if (product_type !== productTypes.DIGITAL) {
              formData.is_discount_card = false;
              return <></>;
            }
            return (
              <BooleanInput
                {...rest}
                source="is_discount_card"
                label="Is Discount Card"
              />
            );
          }}
        </FormDataConsumer>
        {isOwner && (
          <BooleanInput
            source="is_print_on_demand"
            label="Is Print On Demand Blank"
          />
        )}
        <TextInput
          source="product_price"
          type="number"
          label="Product Price"
          validate={requiredMoneyInput}
        />
        <NumberInput
          source="weight_ounces"
          label="Weight Ounces (integers only)"
          validate={requiredInput}
        />
        <Fragment>
          <div className={classes.warning}>
            Use this field to exclude this product from Fulfillment in
            Instaraise
          </div>
        </Fragment>
        <BooleanInput
          label="Externally Fulfilled"
          source="externally_fulfilled"
        />
        <Fragment>
          <div className={classes.warning}>
            {`Use this field to exclude this product from being charged for
            Shipping (this will also affect all Distributors using this Product)`}
          </div>
        </Fragment>
        <BooleanInput label="No Shipping Fees" source="no_shipping_fees" />
        <BooleanInput
          label="Ship To School Only"
          source="ship_to_school_only"
        />
        <BooleanInput label="Ship To Home Only" source="ship_to_home_only" />
        <BooleanInput label="Can Personalize" source="can_personalize" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const can_personalize = formData.can_personalize;
            if (!can_personalize) {
              formData.personalize_max_chars = null;
              return <></>;
            }
            return (
              <NumberInput
                {...rest}
                source="personalize_max_chars"
                label="Personalize Max Characters"
              />
            );
          }}
        </FormDataConsumer>
        <TextInput source="taxjar_tax_code" />
        <Fragment>
          <div className={classes.warning}>
            {`This field is for Tax reports only, and is not used for calculating
            tax on online sales (Taxjar)`}
          </div>
        </Fragment>
        <BooleanInput
          label="Manual Reporting Tax Exempt"
          source="manual_reporting_tax_exempt"
          className={classes.fitContent}
        />
        <Fragment>
          <div className={classes.warning}>
            This field is a DOLLAR amount (NOT %) and will override campaign's
            profit percentage
          </div>
        </Fragment>
        <TextInput
          source="profit_amount"
          type="number"
          label="Custom Profit Dollar Amount"
          validate={optionalMoneyInput}
        />
        {isOwner && (
          <NumberInput
            source="digital_content_id"
            label="Digital Content Id"
            step={1}
          />
        )}
        {isOwner && (
          <NumberInput
            source="digital_content_code_qty"
            label="Digital Content code(s) qty"
            step={1}
          />
        )}
        <CustomRichText
          source="product_description"
          label="Product Description"
        />
        <ImageInput
          source="imageBase64"
          label="Main Image"
          accept="image/*"
          multiple={false}
          validate={requiredInput}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          source="thumbnail_imageBase64"
          label="Thumbnail Image"
          accept="image/*"
          multiple={false}
          validate={requiredInput}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const product_images = formData.product_images;
            const disableAdd =
              Array.isArray(product_images) && product_images.length >= 3;
            return (
              <ArrayInput
                {...rest}
                fullWidth={true}
                source="product_images"
                label="Additional Images"
                className={classes.additionalImagesCreate}
              >
                <SimpleFormIterator
                  disableAdd={disableAdd}
                  disableReordering
                  TransitionProps={{ enter: false, exit: false }}
                >
                  <ImageInput
                    source={"imageBase64"}
                    label="Image"
                    accept="image/*"
                    multiple={false}
                    validate={requiredInput}
                  >
                    <ImageField
                      source="src"
                      title="title"
                      className={classes.image}
                    />
                  </ImageInput>
                  <TextInput
                    source="image_video_link"
                    label="Optional Link for image video"
                  />
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        </FormDataConsumer>
        <TextInput source="custom_tag" />
        <NumberInput source="items_per_case" />
        <TextInput source="bin" />
        <ArrayInput fullWidth={false} source="product_upc" label="UPC List">
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
          >
            <TextInput source="upc" label="upc" validate={requiredInput} />
          </SimpleFormIterator>
        </ArrayInput>
        <Fragment>
          <div className={classes.warning}>
            If you would like to create a configurable product (parent-product)
            with different variants, select `configurable` option.
          </div>
          <div className={classes.warning}>
            After you create it, you will be able to add variant options.
          </div>
        </Fragment>
        <SelectInput
          source="structure_type"
          label="Product Structure Type"
          defaultValue={productStructureTypes.STANDARD}
          choices={productStructureTypeOptions}
          translateChoice={false}
        />
      </SimpleForm>
    </Create>
  );
}
