import { Fragment } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import classNames from "classnames";

type Props = {
  show: boolean;
  customClass?: string;
};

export function CircularLoader({ show, customClass }: Props) {
  const classes = styles();

  return (
    <Fragment>
      {show && (
        <div
          className={classNames(classes.container, customClass && customClass)}
        >
          <CircularProgress color="primary" size={50} />
        </div>
      )}
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
