import React, { Fragment, useEffect, useState } from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  DateField,
  FunctionField,
  TopToolbar,
  EditButton,
  TabbedShowLayoutTabs,
} from "react-admin";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Button, makeStyles } from "@material-ui/core";
import { VolunteerCampaignPrizesVolunteerList } from "../";
import { OrgProfit } from "./orgProfit/OrgProfit";
import { RepCommission } from "./repCommission/RepCommission";
import { LaunchPacket } from "./LaunchPacket";
import { Management } from "./Management";
import { ProfitCategoryList } from "./profitCategories/ProfitCategoryList";
import { CampaignDonationGroup } from "./campaignDonationGroup/CampaignDonationGroup";
import { DedicationOptions } from "./components/DedicationOptions";
import { Showcase } from "./showcase/Showcase";
import {
  APIUtilClient,
  generateAuthHeader,
  downloadPdf,
  isCompanyOrCompanyAdminOrRep,
  isCompanyDataEntry,
  isGivvrCompany,
  getUserTimezone,
  isProductCampaign,
  donationReceiptConfigLevels,
} from "../../lib";
import { DonationCampaignReceiptTemplate } from "../donationReceiptConfig/DonationCampaignReceiptTemplate";
import { ProductCampaignReceiptTemplate } from "../donationReceiptConfig/ProductCampaignReceiptTemplate";
import { CampaignCommunicationList } from "../../newApp/components/communication/campaignCommunications/CampaignCommunicationList";
import classNames from "classnames";
import { AdminVolunteerList } from "../../newApp/pages/volunteers/AdminVolunteerList";
import { campaignActions } from "../../newApp/state";
import { Toast } from "../../newApp/components";
import { AdminCampaignDonationList } from "../../newApp/pages/donations/campaignDonations/AdminCampaignDonationList";
import { CampaignProducts } from "../../newApp/pages/campaignProducts/CampaignProducts";

const Actions = ({ basePath, data, isAdmin, isDataEntry }) => {
  return (
    <TopToolbar>
      {!isDataEntry && (
        <EditButton
          basePath={basePath}
          record={data}
          label="edit details and images"
        />
      )}
      {isAdmin && (
        <Button
          size="small"
          color="primary"
          onClick={() => campaignAgreement(data.id)}
        >
          <DownloadIcon />
          campaign agreement
        </Button>
      )}
    </TopToolbar>
  );
};

const Title = ({ record }) =>
  `Campaign - ${record ? `#${record.id} ${record.orgName}` : ""}`;

function CampaignDetailsShow(props) {
  const classes = styles();
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = props;
  const {
    id: campaign_id,
    campaign_type_id,
    company_id,
    rep_id,
    campaign_donation_group_id,
    has_showcase,
    communication_bundle_id,
  } = useSelector(
    state => state.admin.resources.campaigns.data[props.id] || {},
    shallowEqual,
  );
  const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);
  const campaignManagementEnabled = useSelector(
    state => state.user.campaignManagementEnabled,
  );
  const isAdmin = useSelector(state =>
    isCompanyOrCompanyAdminOrRep(state.user.role),
  );
  const isDataEntry = useSelector(state => isCompanyDataEntry(state.user.role));
  const canManage = useSelector(state => state.user.canManage);
  const isProduct = isProductCampaign(campaign_type_id);
  const isGivvr = isGivvrCompany(company_id);
  const [noPaper, setNoPaper] = useState(false);

  useEffect(() => {
    if (campaign_id) {
      dispatch(campaignActions.switchCampaigns(campaign_id));
      dispatch(campaignActions.getCampaignInfo(campaign_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  useEffect(() => {
    if (/communications|volunteer_list|donation_list|products/.test(pathname)) {
      setNoPaper(true);
    } else setNoPaper(false);
  }, [pathname]);

  return (
    <Fragment>
      <Show
        {...props}
        title={<Title />}
        actions={<Actions isAdmin={isAdmin} isDataEntry={isDataEntry} />}
        className={noPaper ? classes.noPaper : classes.paper}
      >
        <TabbedShowLayout
          tabs={
            <TabbedShowLayoutTabs
              variant="scrollable"
              scrollButtons="auto"
              className={classNames(
                classes.tabs,
                sidebarOpen && classes.sidebarOpen,
              )}
            />
          }
        >
          <Tab label="General" path="" className={classes.tab}>
            <TextField source="id" label="Campaign ID" />
            <TextField source="orgName" label="Org Name" />
            <TextField source="name" label="Campaign Name" />
            <TextField source="org.contact_name" label="Contact Name" />
            <TextField source="org.phone" label="Phone" />
            <TextField source="org.user.email" label="Email" />
            {isProduct && (
              <FunctionField
                label="Product Group"
                render={({
                  supplier_product_group,
                  supplier_product_group_id,
                }) =>
                  !supplier_product_group
                    ? ""
                    : `${supplier_product_group} - ID: ${supplier_product_group_id}`
                }
              />
            )}
            <FunctionField
              label="Goal"
              render={record => (record.goal ? `$${record.goal}` : "")}
            />
            <DateField showTime source="start_date" label="Site Open Date" />
            <DateField showTime source="end_date" label="Site Close Date" />
          </Tab>
          {isAdmin && (
            <Tab label="Management" path="management" className={classes.tab}>
              <Management />
            </Tab>
          )}
          {isProduct && isAdmin && (
            <Tab
              label="Profit Categories"
              path="profit_category_campaigns"
              className={classes.tab}
            >
              <ProfitCategoryList />
            </Tab>
          )}
          {(isAdmin || isDataEntry) && (
            <Tab label="Students" path="volunteer_list" className={classes.tab}>
              <AdminVolunteerList />
            </Tab>
          )}
          {(isAdmin || isDataEntry) && (
            <Tab
              label={isProduct ? "Sales" : "Donations"}
              path="donation_list"
              className={classes.tab}
            >
              <AdminCampaignDonationList />
            </Tab>
          )}
          {isProduct && isAdmin && (
            <Tab label="Products" path="products" className={classes.tab}>
              <CampaignProducts campaign_id={campaign_id} />
            </Tab>
          )}
          {campaign_donation_group_id && !isDataEntry && (
            <Tab
              label="Campaign Donation Group"
              path="campaign_donation_group"
              className={classes.tab}
            >
              <CampaignDonationGroup />
            </Tab>
          )}
          {isGivvr && !isProduct && !isDataEntry && (
            <Tab
              label="Dedication Options"
              path="dedication_options"
              className={classes.tab}
            >
              <DedicationOptions />
            </Tab>
          )}
          {has_showcase && !isDataEntry && (
            <Tab label="Showcase" path="showcase" className={classes.tab}>
              <Showcase />
            </Tab>
          )}
          {isAdmin && campaignManagementEnabled && (
            <Tab label="Org Profit" path="org_profit" className={classes.tab}>
              <OrgProfit />
            </Tab>
          )}
          {isAdmin && campaignManagementEnabled && Boolean(rep_id) && (
            <Tab
              label="Rep Commission"
              path="rep_commission"
              className={classes.tab}
            >
              <RepCommission />
            </Tab>
          )}
          {isAdmin && (
            <Tab
              label="Launch packet"
              path="launch_packet"
              className={classes.tab}
            >
              <LaunchPacket />
            </Tab>
          )}
          <Tab label="Prizes" path="prizes" className={classes.tab}>
            <PrizeTab />
          </Tab>
          {isAdmin && canManage && (
            <Tab
              label="Receipt Email"
              path="receipt_email"
              className={classes.tab}
            >
              <ReceiptEmailTab />
            </Tab>
          )}
          {communication_bundle_id && (
            <Tab
              label="Communications"
              path="communications"
              className={classes.tab}
            >
              <CampaignCommunicationList />
            </Tab>
          )}
        </TabbedShowLayout>
      </Show>
      <Toast />
    </Fragment>
  );
}

export default CampaignDetailsShow;

function PrizeTab({ record }) {
  return <VolunteerCampaignPrizesVolunteerList campaign_id={record.id} />;
}

function ReceiptEmailTab({ record }) {
  const { id, campaign_type_id } = record;
  const isProduct = isProductCampaign(campaign_type_id);

  return (
    <Fragment>
      {isProduct ? (
        <ProductCampaignReceiptTemplate
          urlSuffix={`${donationReceiptConfigLevels.CAMPAIGN}?campaign_id=${id}`}
        />
      ) : (
        <DonationCampaignReceiptTemplate
          urlSuffix={`${donationReceiptConfigLevels.CAMPAIGN}?campaign_id=${id}`}
        />
      )}
    </Fragment>
  );
}

async function campaignAgreement(campaignId) {
  const timezone = getUserTimezone();
  const res = await APIUtilClient.get(
    `/pdf/campaign-agreement?campaign_id=${campaignId}&timezone=${timezone}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  return downloadPdf(res, "campaign-agreement.pdf");
}

const styles = makeStyles(theme => ({
  tabs: {
    maxWidth: "calc(100vw - 85px)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
  sidebarOpen: {
    maxWidth: "calc(100vw - 270px)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: () => "100vw",
    },
  },
  tab: {
    width: "fit-content",
    maxWidth: "fit-content",
    minWidth: "fit-content",
  },
  noPaper: {
    "& .MuiPaper-elevation1": {
      overflow: "auto",
      boxShadow: "none",
    },
  },
  paper: {
    "& .MuiPaper-elevation1": {
      overflow: "auto",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    },
  },
}));
