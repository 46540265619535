import { IconButton, makeStyles } from "@material-ui/core";
import { DonationEventTickets, GenericObject } from "../../../../types";
import { sharedStyles } from "./sharedStyles";
import EditIcon from "@material-ui/icons/EditOutlined";
import { formatMoneyUnPadded, nonEmptyArray } from "../../../../lib";
import classNames from "classnames";
import { useState } from "react";
import { EditEventTickets } from "./EditEventTickets";
import { useSelector } from "react-redux";
import { getIsOrgOrEmp } from "../../../../../customState";

type Props = { donation: GenericObject; refreshData: () => Promise<void> };

export function EventTicketsCard({ donation, refreshData }: Props) {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const [showEdit, setShowEdit] = useState(false);
  const isOrgOeEmp = useSelector(getIsOrgOrEmp);
  const eventTickets = donation.eventTickets as DonationEventTickets;

  if (!nonEmptyArray(eventTickets)) return <></>;
  return (
    <div className={sharedClasses.card}>
      <div className={classes.headerRow}>
        <div className={sharedClasses.subheader}>Tickets</div>
        {isOrgOeEmp && (
          <div className={classes.edit}>
            <IconButton onClick={() => setShowEdit(true)}>
              <EditIcon color="primary" />
            </IconButton>
          </div>
        )}
      </div>

      {eventTickets.map((ticket, idx) => {
        const { id, event_name, seat, attendee, ticket_price } = ticket;
        const isLast = idx === eventTickets.length - 1;
        return (
          <div
            key={id}
            className={classNames(classes.row, isLast && classes.lastRow)}
          >
            <div className={classes.top}>
              <div>{event_name}</div>
              <div className={classes.price}>
                {formatMoneyUnPadded(ticket_price)}
              </div>
            </div>
            <div className={classes.figure}>{seat}</div>
            <div className={classes.figure}>Name: {attendee}</div>
          </div>
        );
      })}

      {showEdit && (
        <EditEventTickets
          onClose={() => setShowEdit(false)}
          refreshData={refreshData}
          eventTickets={eventTickets}
          donation_id={donation.id}
        />
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  headerRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  edit: {
    marginTop: -6,
    marginRight: -6,
  },
  row: {
    marginBottom: 16,
    paddingBottom: 18,
    borderBottom: "1px solid #EAEBF3",
  },
  lastRow: {
    borderBottom: "none",
    paddingBottom: 0,
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontWeight: 500,
    letterSpacing: 0.2,
    color: theme.palette.text.primary,
    paddingTop: 4,
  },
  price: {
    minWidth: "fit-content",
    paddingLeft: 16,
  },
  figure: {
    paddingTop: 6,
  },
}));
