import jwtDecode from "jwt-decode";

function parseUserToken(useToken) {
  const token = getAuthToken(useToken);
  let decoded = { data: {} };
  if (token) {
    try {
      const _decoded = jwtDecode(token);
      if (_decoded) decoded = _decoded;
    } catch (error) {
      console.error(error);
    }
  }

  const { data = {}, defaultCompanySeasonId, ...rest } = decoded;
  return {
    ...rest,
    ...data,
    defaultCompanySeasonId,
    season_id: defaultCompanySeasonId, // default the active global season to the default company season id
  };
}

function getAuthToken(useToken) {
  const token = localStorage.getItem("token");
  const superAdminCompanyToken = localStorage.getItem("superAdminCompanyToken");
  const companyIT = localStorage.getItem("companyImpersonationToken");
  const repIT = localStorage.getItem("repImpersonationToken");
  const orgIT = localStorage.getItem("orgImpersonationToken");
  const volunteerIT = localStorage.getItem("volunteerImpersonationToken");
  const isSuperAdminCompanyUser =
    sessionStorage.getItem("isSuperAdminCompanyUser") === "true";

  const authToken = useToken
    ? useToken
    : volunteerIT
    ? volunteerIT
    : orgIT
    ? orgIT
    : repIT
    ? repIT
    : companyIT
    ? companyIT
    : isSuperAdminCompanyUser
    ? superAdminCompanyToken
    : token;

  return authToken;
}

function loggedInCheck() {
  const token = localStorage.getItem("token");
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    const { exp } = decoded;
    if (exp * 1000 < new Date().getTime()) {
      return false;
    }
  } catch (_error) {
    return false;
  }
  return true;
}

function clearStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("orgImpersonationToken");
  localStorage.removeItem("volunteerImpersonationToken");
  localStorage.removeItem("companyImpersonationToken");
  localStorage.removeItem("superAdminCompanyToken");
  localStorage.removeItem("repImpersonationToken");
  sessionStorage.removeItem("isSuperAdminCompanyUser");
}

function changeVolunteerToken(newToken) {
  if (localStorage.getItem("volunteerImpersonationToken")) {
    localStorage.setItem("volunteerImpersonationToken", newToken);
  } else {
    localStorage.setItem("token", newToken);
  }
}

export {
  parseUserToken,
  getAuthToken,
  clearStorage,
  loggedInCheck,
  changeVolunteerToken,
};
