import { makeStyles, Tabs, Tab } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CampaignProductList } from "./components/CampaignProductList";
import { ReactAdminTabbedShowNewAppWrapper } from "../../components/general/ReactAdminTabbedShowNewAppWrapper";
import { ProductOrdering } from "./components/ProductOrdering";
import { EditCampaignCategoryNames } from "./components/EditCampaignCategoryNames";
import { PODArtifacts } from "./components/PODArtifacts";
import { APIAuthClient } from "../../lib";
import { APIRes } from "../../types";
import { setToast } from "../../state";
import { getIsCoOrCoAdmin } from "../../../customState";
import { UpdateConfigurableDefaultVariant } from "./components/UpdateConfigurableDefaultVariant";
enum Screens {
  List = "List",
  Ordering = "Ordering",
  EditCategories = "EditCategories",
  POD = "POD",
  DefaultVariant = "DefaultVariant",
  SoftDeletedList = "SoftDeletedList",
}

type Props = { campaign_id: any };
export function CampaignProducts({ campaign_id }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isCoOrCoAdmin = useSelector(getIsCoOrCoAdmin);
  const [activeTab, setActiveTab] = useState<Screens>(Screens.List);
  const [hasPodGroups, setHasPodGroups] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const url = `/campaign_product_pod_groups/has_groups_check?campaign_id=${campaign_id}`;
      const res = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = res;
      if (error) dispatch(setToast(errorMessage));
      setHasPodGroups(data.hasPodGroups);
    };
    if (campaign_id && isCoOrCoAdmin) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id, isCoOrCoAdmin]);

  return (
    <ReactAdminTabbedShowNewAppWrapper>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        classes={{ root: classes.tabs }}
      >
        <Tab label="Products" value={Screens.List} className={classes.tab} />
        <Tab
          label="Display order"
          value={Screens.Ordering}
          className={classes.tab}
        />
        {isCoOrCoAdmin && (
          <Tab
            label="Customize categories"
            value={Screens.EditCategories}
            className={classes.tab}
          />
        )}
        {hasPodGroups && isCoOrCoAdmin && (
          <Tab
            label="POD artifacts"
            value={Screens.POD}
            className={classes.tab}
          />
        )}
        <Tab
          label="Parent default variant"
          value={Screens.DefaultVariant}
          className={classes.tab}
        />
        <Tab
          label="Removed products"
          value={Screens.SoftDeletedList}
          className={classes.tab}
        />
      </Tabs>

      {activeTab === Screens.List && (
        <CampaignProductList campaign_id={campaign_id} softDeleted={false} />
      )}
      {activeTab === Screens.Ordering && (
        <ProductOrdering campaign_id={campaign_id} />
      )}
      {activeTab === Screens.EditCategories && (
        <EditCampaignCategoryNames campaign_id={campaign_id} />
      )}
      {activeTab === Screens.POD && hasPodGroups && (
        <PODArtifacts campaign_id={campaign_id} />
      )}
      {activeTab === Screens.DefaultVariant && (
        <UpdateConfigurableDefaultVariant campaign_id={campaign_id} />
      )}
      {activeTab === Screens.SoftDeletedList && (
        <CampaignProductList campaign_id={campaign_id} softDeleted />
      )}
    </ReactAdminTabbedShowNewAppWrapper>
  );
}

const styles = makeStyles(theme => ({
  tabs: {
    borderBottom: "1px solid #DBDEEE",
    margin: "0 0 18px 0",
    position: "sticky",
    top: 56,
    backgroundColor: "#F7F7F7",
    zIndex: 9,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      top: 56,
    },
  },
  tab: {
    maxWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));
