import {
  Button,
  makeStyles,
  TextField as MuiTextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { useSelector } from "react-redux";
import { useForm } from "react-final-form";
import {
  getCampaignVolunteerAutocomplete,
  getVolunteerLabel,
} from "../../../../state";
import { DonationDetailModes, GenericObject } from "../../../../types";
import { sharedStyles } from "./sharedStyles";
import { Fragment, useState } from "react";
import { NullableField, TextFieldWrapper } from "../../../../components";
import { useIsMobile } from "../../../../hooks/ui";
import { MobileUpdateVolunteer } from "../../campaignDonations/MobileUpdateVolunteer";

type Props = {
  donation: GenericObject;
  mode: DonationDetailModes;
  shouldRefreshListRef: React.MutableRefObject<boolean>;
  refreshData: () => void;
  isCompanyDonation?: boolean;
};
export function VolunteerCard({
  donation,
  mode,
  shouldRefreshListRef,
  refreshData,
  isCompanyDonation,
}: Props) {
  const { id, volunteerName = "", volunteer_id, solicited_by = "" } = donation;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const form = useForm();
  const isMobile = useIsMobile();
  const { volunteerLabelSing } = useSelector(getVolunteerLabel);
  const volunteers = useSelector(getCampaignVolunteerAutocomplete);
  const [showVolunteerUpdate, setShowVolunteerUpdate] = useState(false);
  const [selectedVolunteerId, setSelectedVolunteerId] = useState(volunteer_id);

  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>
        <div className={classes.headerWrapper}>
          <div>{volunteerLabelSing}</div>
          {isMobile && !isCompanyDonation && mode === DonationDetailModes.view && (
            <Button
              variant="text"
              color="primary"
              size="small"
              startIcon={<AutorenewIcon />}
              className={classes.updateBtn}
              onClick={() => setShowVolunteerUpdate(true)}
            >
              update {volunteerLabelSing}
            </Button>
          )}
        </div>
      </div>
      {mode === DonationDetailModes.view && (
        <Fragment>
          <div>{volunteerName ? volunteerName : "Unassigned"}</div>
          <div className={classes.solicitedWrapper}>
            <div className={classes.solicitedLabel}>Solicited by</div>
            <div>{solicited_by ? solicited_by : "-"}</div>
          </div>
        </Fragment>
      )}
      {mode === DonationDetailModes.edit && (
        <Fragment>
          <Autocomplete
            onChange={(_, value) => {
              form.change("volunteer_id", value ? value.id : null);
              setSelectedVolunteerId(value ? value.id : null);
            }}
            options={volunteers}
            getOptionLabel={o => {
              return o ? `${o.first_name} ${o.last_name} #${o.id}` : `${o}`;
            }}
            value={
              volunteers.find(option => option.id === selectedVolunteerId) ||
              null
            }
            getOptionSelected={(o: any, v: any) => {
              if (!v) return false;
              if (typeof v === "string") return `${o.id}` === v;
              return o.id === v.id;
            }}
            renderInput={params => (
              <MuiTextField {...params} label={volunteerLabelSing} />
            )}
          />

          <NullableField
            component={TextFieldWrapper}
            label="Solicited by"
            name="solicited_by"
            className={classes.solicitedInput}
          />
        </Fragment>
      )}
      {showVolunteerUpdate && (
        <MobileUpdateVolunteer
          onClose={() => setShowVolunteerUpdate(false)}
          donationId={id as any}
          solicited_by={solicited_by as any}
          currentVolunteerId={volunteer_id as any}
          shouldRefreshListRef={shouldRefreshListRef}
          refreshData={refreshData}
        />
      )}
    </div>
  );
}

const styles = makeStyles(_theme => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  updateBtn: {
    textTransform: "uppercase",
  },
  solicitedWrapper: {
    marginTop: 16,
    marginBottom: 16,
    display: "flex",
  },
  solicitedLabel: {
    fontWeight: 500,
    marginRight: 16,
  },
  input: {
    width: "100%",
  },
  solicitedInput: {
    width: "100%",
    marginTop: 24,
    marginBottom: 16,
  },
}));
