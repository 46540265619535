import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  List,
  BooleanInput,
  BooleanField,
  Pagination,
  FunctionField,
  TopToolbar,
  useNotify,
  downloadCSV,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { useSelector } from "react-redux";
import {
  makeStyles,
  ButtonGroup,
  Button,
  ThemeProvider,
} from "@material-ui/core";
import PrizeIcon from "@material-ui/icons/CardGiftcard";
import DownloadIcon from "@material-ui/icons/GetApp";
import { PrizeProgram } from "./PrizeProgram";
import { CustomizeProducts } from "./CustomizeProducts";
import {
  APIUtilClient,
  generateAuthHeader,
  isCompanyDataEntry,
} from "../../lib";
import { PrizeModal } from "../../newApp/components/prizes/PrizeModal";
import { theme } from "../../newApp/theme/theme";
const modes = {
  VOLUNTEERS: "VOLUNTEERS",
  DETAILS: "DETAILS",
  CUSTOMIZE: "CUSTOMIZE",
};

export function VolunteerCampaignPrizesVolunteerList({ campaign_id }) {
  const notify = useNotify();
  const [mode, setMode] = useState(modes.VOLUNTEERS);
  const [exporting, setExporting] = useState(false);
  const refetchListRef = useRef(() => {});
  const canManage = useSelector(state => state.user.canManage);
  const classes = styles({ isVolunteerMode: mode === modes.VOLUNTEERS });
  const basePath = `/campaigns/${campaign_id}/show`;

  const prizeExport = async () => {
    if (!campaign_id) return;
    setExporting(true);
    const res = await APIUtilClient.get(
      `/volunteer_campaign_prizes/export?campaign_id=${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) {
      notify(errorMessage, "warning");
      setExporting(false);
      return;
    }
    jsonExport(data, (_err, csv) => {
      downloadCSV(csv, `Campaign Prizes #${campaign_id}`);
    });
    setTimeout(() => setExporting(false), 2000);
  };

  const Actions = () => (
    <TopToolbar>
      <Button
        size="small"
        color="primary"
        variant="outlined"
        startIcon={<DownloadIcon />}
        onClick={prizeExport}
        disabled={exporting}
      >
        export
      </Button>
    </TopToolbar>
  );

  if (!campaign_id) return <></>;
  return (
    <div>
      <div className={classes.toggle}>
        <ButtonGroup>
          <Button
            color="primary"
            variant={mode === modes.VOLUNTEERS ? "contained" : "outlined"}
            className={classes.toggleBtn}
            onClick={() => setMode(modes.VOLUNTEERS)}
          >
            student list
          </Button>

          <Button
            color="primary"
            variant={mode === modes.DETAILS ? "contained" : "outlined"}
            className={classes.toggleBtn}
            onClick={() => setMode(modes.DETAILS)}
          >
            prize program details
          </Button>
          {canManage && (
            <Button
              color="primary"
              variant={mode === modes.CUSTOMIZE ? "contained" : "outlined"}
              className={classes.toggleBtn}
              onClick={() => setMode(modes.CUSTOMIZE)}
            >
              customize products
            </Button>
          )}
        </ButtonGroup>
      </div>

      {mode === modes.DETAILS && (
        <PrizeProgram
          campaign_id={campaign_id}
          refetchListRef={refetchListRef}
        />
      )}

      {mode === modes.CUSTOMIZE && (
        <CustomizeProducts campaign_id={campaign_id} />
      )}

      <List
        syncWithLocation={false}
        title=" "
        location={{}}
        resource="volunteer_campaign_prizes/volunteer_list"
        basePath={basePath}
        perPage={5000} // for this list we show all records, this is needed for RA
        sort={{ field: "id", order: "DESC" }}
        filter={{ campaign_id }}
        empty={false}
        exporter={false}
        component="div"
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[5000]} />} // for this list we show all records, this is needed for RA
        actions={<Actions />}
        classes={{
          root: classes.listRoot,
          main: classes.mainList,
          content: classes.listContent,
        }}
        filters={
          <Filter>
            <TextInput label="ID/Name" source="q" alwaysOn />
            <BooleanInput
              label="Missing Prizes"
              source="missing_prizes"
              alwaysOn
            />
            <BooleanInput
              label="Missing Picked Prizes"
              source="missing_picked_prizes"
              alwaysOn
            />
            <BooleanInput label="Over Prized" source="over_prized" alwaysOn />
          </Filter>
        }
      >
        <GridWrapper refetchListRef={refetchListRef} />
      </List>
    </div>
  );
}

function GridWrapper({ refetchListRef, refetch, ...props }) {
  const canManage = useSelector(state => state.user.canManage);
  const [activeVolunteerId, setActiveVolunteerId] = useState(null);
  const classes = styles();
  const role = useSelector(state => state.user.role);

  useEffect(() => {
    refetchListRef.current = refetch;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const closePrizeModal = refreshList => {
    setActiveVolunteerId(null);
    if (refreshList) refetch();
  };

  return (
    <Fragment>
      <Datagrid refetch={refetch} {...props} rowClick={null}>
        <TextField source="volunteer_id" label="ID" />
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField source="email" />
        <TextField source="NUMBER_OF_SALES" label="Number Of Sales" />
        <TextField source="DOLLAR_AMOUNT_RAISED" label="Amount Raised" />
        <TextField source="NUMBER_OF_ITEMS_SOLD" label="Items Sold" />
        <TextField source="NUMBER_OF_CONTACTS" label="Contacts" />
        <TextField
          source="NUMBER_OF_CONTACTS_WITH_SENT_EMAILS"
          label="Contacts With Sent Emails"
        />
        <TextField
          source="assignedPrizes"
          label="Assigned Prizes"
          sortable={false}
        />
        <TextField source="tierName" label="Tier" sortable={false} />
        <BooleanField
          source="missingPrizes"
          label="Missing Prizes"
          sortable={false}
        />
        <BooleanField
          source="missingPickedPrizes"
          label="Missing Picked Prizes"
          sortable={false}
        />
        <BooleanField
          source="overPrized"
          label="Over Prized"
          sortable={false}
        />
        {(canManage || isCompanyDataEntry(role)) && (
          <FunctionField
            label="Prizes"
            render={({ volunteer_id }) => (
              <PrizeIcon
                onClick={() => setActiveVolunteerId(volunteer_id)}
                className={classes.prizeIcon}
              />
            )}
          />
        )}
      </Datagrid>
      {activeVolunteerId && (
        <ThemeProvider theme={theme}>
          <PrizeModal
            callBack={closePrizeModal}
            volunteer_id={activeVolunteerId}
          />
        </ThemeProvider>
      )}
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  listRoot: {
    display: ({ isVolunteerMode }) => (isVolunteerMode ? "unset" : "none"),
  },
  listContent: {
    overflowX: "auto",
  },
  mainList: {
    maxHeight: "calc(100vh - 190px)",
    overflowY: "auto",
    overflowX: "auto",
  },
  toggle: { margin: "16px 0" },
  toggleBtn: { width: 200 },
  prizeIcon: { cursor: "pointer" },
}));
