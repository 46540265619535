import { makeStyles, Menu } from "@material-ui/core";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { APIRes, ListProps, ToastTypes } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationDialog } from "../../../components";
import {
  getCampaignId,
  getCanCustomizeCampaignProducts,
  getCanRemoveOrRestoreCampaignProducts,
  setToast,
} from "../../../state";
import { APIAuthClient } from "../../../lib";
import { BulkUpdateCustomPrice } from "./BulkUpdateCustomPrice";
const MENU_WIDTH = 246;
const BUTTON_WIDTH = 146;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: Element;
  listProps: ListProps<any>;
  softDeleted: boolean;
};
export function BulkActions({
  isOpen,
  onClose,
  anchorEl,
  listProps,
  softDeleted,
}: Props) {
  const { refreshList, selectedRowIds } = listProps;
  const dispatch = useDispatch();
  const campaign_id = useSelector(getCampaignId);
  const canRemove = useSelector(getCanRemoveOrRestoreCampaignProducts);
  const canCustomize = useSelector(getCanCustomizeCampaignProducts);
  const classes = styles();
  const [showBulkRemove, setShowBulkRemove] = useState(false);
  const [showBulkRestore, setShowBulkRestore] = useState(false);
  const [showBulkCustomizePrice, setShowBulkCustomizePrice] = useState(false);

  const bulkRemoveProducts = async (
    setShowSubmitting: Dispatch<SetStateAction<boolean>>,
    onClose: () => void,
  ) => {
    setShowSubmitting(true);
    const url = "/campaign_products/bulk_delete";
    const body = { campaign_id, productIds: selectedRowIds };
    const res = await APIAuthClient.put<any, APIRes>(url, body);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return setShowSubmitting(false);
    }
    dispatch(setToast("Products removed", ToastTypes.success));
    refreshList();
    onClose();
  };

  const bulkRestoreProducts = async (
    setShowSubmitting: Dispatch<SetStateAction<boolean>>,
    onClose: () => void,
  ) => {
    setShowSubmitting(true);
    const url = "/campaign_products/bulk_restore";
    const body = { campaign_id, productIds: selectedRowIds };
    const res = await APIAuthClient.put<any, APIRes>(url, body);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return setShowSubmitting(false);
    }
    dispatch(setToast("Products restored", ToastTypes.success));
    refreshList();
    onClose();
  };

  return (
    <Fragment>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuPaper }}
        className={classes.menu}
        open={isOpen}
        onClose={onClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: MENU_WIDTH - BUTTON_WIDTH,
        }}
      >
        <div className={classes.container}>
          {canRemove && (
            <Fragment>
              {!softDeleted && (
                <div
                  className={classes.row}
                  onClick={() => {
                    setShowBulkRemove(true);
                    onClose();
                  }}
                >
                  Remove from campaign
                </div>
              )}
              {softDeleted && (
                <div
                  className={classes.row}
                  onClick={() => {
                    setShowBulkRestore(true);
                    onClose();
                  }}
                >
                  Restore to campaign
                </div>
              )}
            </Fragment>
          )}
          {!softDeleted && canCustomize && (
            <div
              className={classes.row}
              onClick={() => {
                setShowBulkCustomizePrice(true);
                onClose();
              }}
            >
              Customize price
            </div>
          )}
        </div>
      </Menu>

      {showBulkRemove && (
        <ConfirmationDialog
          onClose={() => setShowBulkRemove(false)}
          title="Are you sure you want to remove selected products from this campaign?"
          subtitle="(If any of these products are configurable we will remove all their variants)"
          actionLabel="Remove"
          actionFunc={bulkRemoveProducts}
          redButton
        />
      )}
      {showBulkRestore && (
        <ConfirmationDialog
          onClose={() => setShowBulkRestore(false)}
          title="Are you sure you want to restore selected products to this campaign?"
          subtitle="(If any of these products are configurable we will restore all their variants)"
          actionLabel="Restore"
          actionFunc={bulkRestoreProducts}
        />
      )}
      {showBulkCustomizePrice && (
        <BulkUpdateCustomPrice
          onClose={() => setShowBulkCustomizePrice(false)}
          listProps={listProps}
        />
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  menu: {
    padding: 0,
  },
  menuPaper: {
    borderRadius: 8,
  },
  container: {
    width: MENU_WIDTH,
    position: "relative",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0",
      width: "100%",
    },
  },
  row: {
    width: "100%",
    height: 36,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    fontSize: 16,
    letterSpacing: 0.15,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      opacity: 0.8,
    },
  },
}));
