import { useState, useEffect } from "react";
import { Button, TextField, makeStyles } from "@material-ui/core";
import { APIAuthClient } from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";
import { ButtonSpinner } from "../../../components";
type Category = {
  id: number;
  defaultCategoryName: string;
  customCategoryName: string | null;
};

type Props = { campaign_id: any };
export function EditCampaignCategoryNames({ campaign_id }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (campaign_id) fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchCategories = async () => {
    const url = `/campaign_products/store?campaign_id=${campaign_id}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setCategories(data.categories);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        Customize category names for this campaign
      </div>

      {categories.map((category, index) => {
        return (
          <CategoryRow
            key={index}
            campaign_id={campaign_id}
            category={category}
            refresh={fetchCategories}
          />
        );
      })}
    </div>
  );
}

type CategoryProps = {
  campaign_id: any;
  category: Category;
  refresh: () => void;
};
function CategoryRow({ campaign_id, category, refresh }: CategoryProps) {
  const { id: category_id, defaultCategoryName, customCategoryName } = category;
  const classes = styles();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (customCategoryName) setName(customCategoryName);
  }, [customCategoryName]);

  const customizeCategory = async () => {
    setSubmitting(true);
    const url = "/campaign_product_category_names";
    const body = { campaign_id, category_id, name };
    const res = await APIAuthClient.post<any, APIRes>(url, body);
    const { error, errorMessage } = res;
    if (error) dispatch(setToast(errorMessage));
    else {
      refresh();
      dispatch(setToast("Changes saved", ToastTypes.success));
    }
    setSubmitting(false);
  };

  return (
    <div className={classes.category}>
      <TextField
        label="Default category name"
        value={defaultCategoryName}
        className={classes.defaultInput}
        disabled
      />
      <TextField
        label={name ? "Custom category name" : undefined}
        placeholder="Customize category name"
        value={name}
        className={classes.customizeInput}
        onChange={({ target }) => setName(target.value)}
      />

      <Button
        onClick={customizeCategory}
        color="primary"
        className={classes.save}
        disabled={Boolean(
          submitting ||
            !name ||
            name === customCategoryName ||
            name === defaultCategoryName,
        )}
      >
        Save
        <ButtonSpinner show={submitting} />
      </Button>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
    padding: 24,
    backgroundColor: "#FFFFFF",
  },
  header: {
    fontSize: 18,
    fontWeight: 600,
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "22px",
    },
  },
  category: {
    minWidth: "fit-content",
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  defaultInput: {
    width: 190,
    minWidth: 190,
  },
  customizeInput: {
    width: 250,
    minWidth: 250,
    marginLeft: 18,
  },
  save: {
    marginLeft: 16,
  },
}));
