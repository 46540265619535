import { Fragment, useEffect, useRef, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Button, Dialog, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FormApi } from "final-form";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { getIsOwner, getIsRep } from "../../../../customState";
import { integerOnly, productTypes, twoDecimalsOnly } from "../../../../lib";
import SaveIcon from "@material-ui/icons/Save";
import {
  ButtonSpinner,
  TextFieldWrapper,
  WYSIWYGField,
} from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { APIAuthClient, convertFileToB64 } from "../../../lib";
import { AssignDiscountCardToCampaignProduct } from "./AssignDiscountCardToCampaignProduct";
import { DiscountCardDetails } from "../../../pages/discountCardManagement/card/DiscountCardDetails";
import { theme } from "../../../theme/theme";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";

type Props = {
  record: GenericObject;
  onClose: (refreshList: boolean) => void;
};
export const EditCampaignProduct = ({ record, onClose: _onClose }: Props) => {
  const {
    campaignProductId,
    product_type,
    image,
    thumbnail_image,
    product_description,
    is_discount_card,
    campaign_id,
    id: product_id,
  } = record;
  const classes = styles();
  const dispatch = useDispatch();
  const shouldRefetchRef = useRef(false);
  const isOwner = useSelector(state => getIsOwner(state));
  const isRep = useSelector(getIsRep);
  const [initialValues, setInitialValues] = useState({});
  const [showAssignDiscountCard, setShowAssignDiscountCard] = useState(false);
  const [showDiscountCard, setShowDiscountCard] = useState(false);
  const [discountCardId, setDiscountCardId] = useState<any>(null);
  const showDigitalFields = isOwner && product_type !== productTypes.DIGITAL;

  useEffect(() => {
    const {
      product_name,
      product_price,
      digital_content_addition_id,
      digital_content_addition_code_qty,
      product_description,
      discount_card_id,
    } = record;
    setInitialValues({
      custom_name: product_name,
      custom_price: product_price,
      digital_content_addition_id,
      digital_content_addition_code_qty,
      product_description,
    });
    if (discount_card_id) setDiscountCardId(discount_card_id);
  }, [record]);

  const onClose = () => {
    _onClose(shouldRefetchRef.current);
  };

  const onSubmit = async (values: any, form: FormApi, complete: any) => {
    shouldRefetchRef.current = true;
    const {
      custom_price,
      custom_name,
      custom_description,
      customImageFile,
      customThumbnailImageFile,
      digital_content_addition_id,
      digital_content_addition_code_qty,
    } = values;
    const DF = form.getState().dirtyFields;

    const data: GenericObject = {
      digital_content_addition_id: digital_content_addition_id
        ? Number(digital_content_addition_id)
        : null,
      digital_content_addition_code_qty: digital_content_addition_code_qty
        ? Number(digital_content_addition_code_qty)
        : null,
    };
    if (custom_price && DF.custom_price) data.custom_price = custom_price;
    if (custom_name && DF.custom_name) data.custom_name = custom_name;
    if (custom_description && DF.custom_description) {
      data.custom_description = custom_description;
    }
    if (customImageFile) {
      data.customImageBase64 = await convertFileToB64(customImageFile);
    }
    if (customThumbnailImageFile) {
      data.customThumbnailImageBase64 = await convertFileToB64(
        customThumbnailImageFile,
      );
    }

    const url = `/campaign_products/${campaignProductId}`;
    const res = await APIAuthClient.put<any, APIRes>(url, data);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
  };

  if (!campaignProductId) return <Fragment />;
  return (
    <Fragment>
      <Dialog
        open={true}
        maxWidth={false}
        classes={{
          paper: classes.paper,
          paperWidthFalse: classes.paperWidthFalse,
        }}
      >
        <div className={classes.container}>
          <div className={classes.top}>
            <div className={classes.header}>Edit campaign product</div>
            <div className={classes.close}>
              <IconButton onClick={onClose} size="small">
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {is_discount_card && isRep && (
            <div className={classes.discountCardBtn}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  !discountCardId
                    ? setShowAssignDiscountCard(true)
                    : setShowDiscountCard(true)
                }
              >
                {!discountCardId ? "Assign" : "Manage"} Campaign Discount Card
              </Button>
            </div>
          )}

          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, pristine }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="custom_price"
                    label="Edit product price"
                    component={TextFieldWrapper}
                    validate={twoDecimalsOnly}
                    type="number"
                    fullWidth
                    className={classes.input}
                  />
                  <Field
                    name="custom_name"
                    label="Edit product name"
                    component={TextFieldWrapper}
                    fullWidth
                    className={classes.input}
                  />
                  <div className={classes.inputLabel}>Image</div>
                  <ImageDropzoneField
                    fieldName="customImageFile"
                    currImage={image as string}
                    noDelete
                  />
                  <div className={classes.inputSpacer} />
                  <div className={classes.inputLabel}>Thumbnail image</div>
                  <ImageDropzoneField
                    fieldName="customThumbnailImageFile"
                    currImage={thumbnail_image as string}
                    noDelete
                  />
                  <div className={classes.inputSpacer} />
                  {showDigitalFields && (
                    <Field
                      name="digital_content_addition_id"
                      label="Digital-Content addition ID"
                      component={TextFieldWrapper}
                      validate={integerOnly}
                      type="number"
                      fullWidth
                      className={classes.input}
                    />
                  )}
                  {showDigitalFields && (
                    <Field
                      name="digital_content_addition_code_qty"
                      label="Digital-Content addition code qty"
                      component={TextFieldWrapper}
                      validate={integerOnly}
                      type="number"
                      fullWidth
                      className={classes.input}
                    />
                  )}

                  <div className={classes.inputLabel}>Product description</div>
                  <WYSIWYGField
                    fieldName="custom_description"
                    currentValue={product_description as string | null}
                  />

                  <div className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={submitting || pristine}
                      startIcon={<SaveIcon />}
                    >
                      save changes
                      <ButtonSpinner show={submitting} />
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </Dialog>
      {showAssignDiscountCard && (
        <AssignDiscountCardToCampaignProduct
          onClose={() => setShowAssignDiscountCard(false)}
          campaign_id={campaign_id}
          product_id={product_id}
          setDiscountCardId={setDiscountCardId}
          setShowDiscountCard={setShowDiscountCard}
          shouldRefetchRef={shouldRefetchRef}
        />
      )}
      {showDiscountCard && discountCardId && (
        <ThemeProvider theme={theme}>
          <DiscountCardDetails
            onClose={() => setShowDiscountCard(false)}
            id={discountCardId}
            isCampaignCard
          />
        </ThemeProvider>
      )}
    </Fragment>
  );
};

const styles = makeStyles(theme => ({
  paper: {
    marginLeft: 8,
    marginRight: 8,
  },
  paperWidthFalse: {
    maxWidth: "100%",
  },
  container: {
    padding: 24,
    width: 600,
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  header: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: "center",
    paddingBottom: 32,
  },
  close: {
    marginRight: -8,
    marginTop: -8,
  },
  inputLabel: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    paddingBottom: "8px",
  },
  input: {
    marginBottom: 24,
  },
  inputSpacer: {
    height: 24,
  },
  buttonContainer: {
    paddingTop: 32,
  },
  discountCardBtn: {
    marginBottom: 28,
  },
}));
