import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  EmailField,
  DateField,
  FunctionField,
  downloadCSV,
  NumberInput,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { ResolveSubmissionCheckbox } from "../../components";
import { connect } from "react-redux";

const SubmitterFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search Submitters" source="q" alwaysOn />
      <NumberInput label="Submission ID" source="id" alwaysOn />
    </Filter>
  );
};

function SubmissionList(props) {
  const { dispatch: _rm, season_id, ...rest } = props;

  return (
    <List
      {...rest}
      perPage={25}
      title="Submissions"
      bulkActionButtons={false}
      sort={{ field: "created_at", order: "DESC" }}
      filter={season_id ? { season_id } : undefined}
      filters={<SubmitterFilter />}
      exporter={exporter}
      empty={false}
    >
      <Datagrid rowClick={null}>
        <TextField source="id" label="Submission ID" />
        <FunctionField
          sortBy="resolved"
          label="Resolve"
          render={record => <ResolveSubmissionCheckbox record={record} />}
        />
        <TextField
          source="campaign_id"
          label="From Campaign ID"
          sortable={true}
        />
        <DateField
          label="Submission Date"
          source="created_at"
          sortable={true}
        />
        <TextField source="name" sortable={true} />
        <EmailField source="email" sortable={true} />
        <TextField source="message" sortable={true} />
      </Datagrid>
    </List>
  );
}

export default connect(state => {
  const {
    user: { season_id },
  } = state;
  return { season_id };
})(SubmissionList);

function exporter(submissions) {
  const formattedSubmissions = submissions.map(s => {
    const { created_at, name, email, message, resolved } = s;
    const exportObj = {
      Date: new Date(created_at).toLocaleDateString(),
      Name: name,
      Email: email,
      Message: message,
      Resolved: resolved ? "yes" : "no",
    };
    return exportObj;
  });
  jsonExport(formattedSubmissions, (err, csv) => {
    downloadCSV(csv, "Submissions");
  });
}
