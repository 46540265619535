import { useState } from "react";
import { IconButton, Button, makeStyles } from "@material-ui/core";
import { Form } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, setToast } from "../../../state";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { APIAuthClient, nonEmptyArray } from "../../../lib";
import { ResponsiveModal } from "../../../components/ui/ResponsiveModal";
import { useDrawerTransition } from "../../../hooks/ui";
import { MultiFileDropzoneField } from "../../../components/ui/MultiFileDropzoneField";
import { ButtonSpinner } from "../../../components/ui/ButtonSpinner";
import { FailedUploads } from "./FailedUploads";

type Props = {
  refreshData: () => void;
  onClose: () => void;
};
export function CPTOrderFormUpload({ onClose: _onClose, refreshData }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [failedRows, setFailedRows] = useState<null | GenericObject[]>(null);
  const campaign_id = useSelector(getCampaignId);

  const onSubmit = async ({ files }) => {
    if (!nonEmptyArray(files)) {
      return dispatch(setToast("Please upload A valid JSON file"));
    }
    setDisableSubmit(true);
    const reader = new FileReader();
    reader.readAsText(files[0] as File);
    reader.onload = async () => {
      const url = "/donations/cpt_bulk_brochure_upload";
      const res = await APIAuthClient.post<any, APIRes>(url, {
        brochures: JSON.parse(reader.result as string),
        campaign_id,
      });
      const { error, errorMessage, data } = res;
      if (error) {
        setDisableSubmit(false);
        dispatch(setToast(errorMessage));
      } else if (!data.failedRows.length) {
        onClose();
        dispatch(
          setToast("Order Forms successfully uploaded", ToastTypes.success),
        );
        refreshData();
      } else {
        setFailedRows(data.failedRows);
      }
    };
    reader.onerror = console.error;
  };

  if (failedRows) {
    return (
      <FailedUploads
        onClose={() => {
          onClose();
          refreshData();
        }}
        failedRows={failedRows}
      />
    );
  }
  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div>CPT order form upload</div>
          <div className={classes.close}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div>
          <div className={classes.subheader}>
            Upload CPT generated JSON file
          </div>

          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className={classes.fileDrop}>
                    <MultiFileDropzoneField
                      fieldName="files"
                      accept={["application/json"]}
                      singleFileOnly
                    />
                  </div>

                  <div className={classes.uploadWrapper}>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={disableSubmit || submitting}
                    >
                      Upload
                      <ButtonSpinner show={submitting} />
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(() => ({
  container: {
    padding: 24,
    width: "750px",
    maxWidth: "100%",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontSize: 22,
    fontWeight: 600,
  },
  close: {
    marginTop: -6,
    marginRight: -6,
  },
  subheader: {
    fontSize: "18px",
    padding: "16px 0",
  },
  fileDrop: {
    marginTop: 24,
  },
  uploadWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 24,
  },
}));
