import React, { Fragment, useEffect, useState } from "react";
import { useNotify } from "react-admin";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  InputAdornment,
  Button,
  IconButton,
  FormControlLabel,
  makeStyles,
  TableFooter,
  TextField as MuiTextField,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/GetApp";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Form, Field, useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { TextField, Checkbox } from "final-form-material-ui";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  APIClient,
  APIUtilClient,
  generateAuthHeader,
  required,
  twoDecimalsOnly,
  composeValidators,
  formatMoney,
  formatDateOnlyString,
  downloadPdf,
  wholesaleInvoiceStatuses,
  getUserTimezone,
} from "../../../lib";
import { Input } from "./Input";

export function WholesaleInvoice({ record }) {
  const campaign_id = record.id;
  const notify = useNotify();
  const classes = styles();
  const [disableEdit, setDisableEdit] = useState(true);
  const [isPaid, setIsPaid] = useState(false);
  const [isInvoiceSent, setIsInvoiceSent] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [productTotal, setProductTotal] = useState(0);
  const [customAdjustmentTotal, setCustomAdjustmentTotal] = useState(0);
  const [fulfillmentChargeTotal, setFulfillmentChargeTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [actionDate, setActionDate] = useState("");
  const [statusChangeTriggered, setStatusChangeTriggered] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (campaign_id) fetchInvoiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  useEffect(() => {
    setGrandTotal(
      fulfillmentChargeTotal + productTotal + customAdjustmentTotal,
    );
  }, [fulfillmentChargeTotal, productTotal, customAdjustmentTotal]);

  const fetchInvoiceData = async () => {
    const res = await APIClient.get(
      `/wholesale_invoices/campaigns/${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setInitialValues(data);
    switch (data.status) {
      case wholesaleInvoiceStatuses.INVOICE_SENT:
        setIsInvoiceSent(true);
        setDisableEdit(true);
        break;
      case wholesaleInvoiceStatuses.PAID:
        setIsInvoiceSent(false);
        setIsPaid(true);
        break;
      default:
        setDisableEdit(false);
    }
  };

  const onSubmit = async values => {
    if (isInvoiceSent || isPaid) setDisableEdit(true);
    const {
      ship_to_home_per_order_charge,
      ship_to_home_per_item_charge,
      ship_to_home_shipping_charge,
      ship_to_school_per_student_order_charge,
      ship_to_school_per_item_charge,
      ship_to_school_shipping_charge,
      wholesale_invoice_adjustments = [],
      products,
      notes,
    } = values;

    const campaign_product_wholesale_prices = [];
    products.forEach(p => {
      const { campaign_product_wholesale_price_id, wholesale_price } = p;
      if (!campaign_product_wholesale_price_id) {
        return notify(
          "Missing record ID, please refresh your page.",
          "warning",
        );
      }
      campaign_product_wholesale_prices.push({
        id: campaign_product_wholesale_price_id,
        wholesale_price,
      });
    });
    const fields = {
      ship_to_home_per_order_charge,
      ship_to_home_per_item_charge,
      ship_to_home_shipping_charge,
      ship_to_school_per_student_order_charge,
      ship_to_school_per_item_charge,
      ship_to_school_shipping_charge,
    };
    // convert empty strings to null (money fields will fail validation without this)
    Object.keys(fields).forEach(
      k => (fields[k] = fields[k] === "" ? null : fields[k]),
    );
    const update = {
      ...fields,
      wholesale_invoice_adjustments,
      campaign_product_wholesale_prices,
      notes,
    };
    const res = await APIClient.put(
      `/wholesale_invoices/campaigns/${campaign_id}`,
      update,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    fetchInvoiceData();
    notify("info has been successfully updated");
  };

  const handleStatusChange = async () => {
    if (!actionDate) return notify("Please enter date!", "warning");
    setStatusChangeTriggered(true);
    const confirmed = window.confirm(
      "Are you sure you want to change the status?",
    );
    if (!confirmed) return setStatusChangeTriggered(false);
    const res = await APIClient.post(
      `/wholesale_invoices/campaigns/${campaign_id}/change_status`,
      {
        date: actionDate,
        status: !isInvoiceSent
          ? wholesaleInvoiceStatuses.INVOICE_SENT
          : wholesaleInvoiceStatuses.PAID,
      },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) notify(errorMessage, "warning");
    else {
      fetchInvoiceData();
      notify("Status has been successfully changed!");
      setActionDate("");
    }

    setStatusChangeTriggered(false);
  };

  const handleEditAfterStatusChange = async () => {
    const confirmed = window.confirm(
      "This Invoice has already been sent/paid, are you sure you want to edit?",
    );
    if (confirmed) setDisableEdit(false);
  };

  const exportPDF = async () => {
    setDownloading(true);
    const timezone = getUserTimezone();
    const res = await APIUtilClient.get(
      `/wholesale_invoices/campaigns/${campaign_id}/pdf?timezone=${timezone}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    setTimeout(() => {
      setDownloading(false);
    }, 1000);
    return downloadPdf(res, "campaign-wholesale-invoice.pdf");
  };

  return (
    <Fragment>
      <div className={classes.top}>
        <div className={classes.topMsg}>
          This report excludes items that are not fulfilled by this supplier
        </div>
        <Button
          variant="contained"
          onClick={exportPDF}
          endIcon={<DownloadIcon />}
          color="primary"
          disabled={downloading}
        >
          download PDF
        </Button>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, values }) => {
          const {
            status,
            invoice_sent_on,
            paid_on,
            wholesale_invoice_adjustments,
            totalItemCount,
            shipToHomeOrderCount,
            shipToHomeItemCount,
            ship_to_home_per_order_charge,
            ship_to_home_per_item_charge,
            ship_to_home_shipping_charge,
            shipToSchoolStudentOrdersCount,
            shipToSchoolItemCount,
            ship_to_school_per_student_order_charge,
            ship_to_school_per_item_charge,
            ship_to_school_shipping_charge,
            totalActualShipToHomeCost,
            ship_to_school_shipping_cost,
          } = values;
          return (
            <form onSubmit={handleSubmit}>
              <ProductChangeListener setProductTotal={setProductTotal} />
              <FulfillmentChargeListener
                setFulfillmentChargeTotal={setFulfillmentChargeTotal}
              />
              <CustomAdjustmentsListener
                setCustomAdjustmentTotal={setCustomAdjustmentTotal}
              />
              <div className={classes.generalInfo}>
                <TableContainer component={Paper} className={classes.table}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell className={classes.status}>
                          {status || ""}
                        </TableCell>
                      </TableRow>
                      {invoice_sent_on && (
                        <TableRow>
                          <TableCell>Invoice Sent On:</TableCell>
                          <TableCell>
                            {formatDateOnlyString(invoice_sent_on)}
                          </TableCell>
                        </TableRow>
                      )}
                      {paid_on && (
                        <TableRow>
                          <TableCell>Paid On:</TableCell>
                          <TableCell>{formatDateOnlyString(paid_on)}</TableCell>
                        </TableRow>
                      )}
                      {status && status !== wholesaleInvoiceStatuses.PAID && (
                        <TableRow>
                          <TableCell>
                            <MuiTextField
                              type="date"
                              value={actionDate}
                              onChange={e => setActionDate(e.target.value)}
                              label={`INVOICE ${
                                isInvoiceSent ? "PAID" : "SENT"
                              } ON`}
                              InputLabelProps={{ shrink: true }}
                              className={classes.actionDate}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              disabled={statusChangeTriggered}
                              color="primary"
                              onClick={handleStatusChange}
                            >
                              confirm
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>General Notes:</TableCell>
                        <TableCell>
                          <Input
                            name="notes"
                            disabled={disableEdit}
                            multiline={true}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <TableContainer component={Paper} className={classes.table}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Sku</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Ship To Home Qty</TableCell>
                      <TableCell align="center">Ship To School Qty</TableCell>
                      <TableCell align="center">Total Qty</TableCell>
                      <TableCell>Wholesale Price</TableCell>
                      <TableCell align="center">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <FieldArray name="products">
                    {({ fields }) => (
                      <TableBody>
                        {fields.map((row, index) => {
                          const {
                            id,
                            sku,
                            product_name,
                            qty,
                            shipToHomeQty,
                            shipToSchoolQty,
                            wholesale_price,
                          } = fields.value[index];
                          return (
                            <TableRow key={index}>
                              <TableCell>{id}</TableCell>
                              <TableCell>{sku}</TableCell>
                              <TableCell>{product_name}</TableCell>
                              <TableCell align="center">
                                {shipToHomeQty}
                              </TableCell>
                              <TableCell align="center">
                                {shipToSchoolQty}
                              </TableCell>
                              <TableCell align="center">{qty}</TableCell>
                              <TableCell>
                                <Input
                                  name={`${row}.wholesale_price`}
                                  type="number"
                                  isMoney
                                  disabled={disableEdit}
                                  validate={composeValidators(
                                    required,
                                    twoDecimalsOnly,
                                  )}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {multiplyAndFormat(qty, wholesale_price)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    )}
                  </FieldArray>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="right"
                        className={classes.totals}
                      >
                        TOTALS:
                      </TableCell>
                      <TableCell align="center" className={classes.totals}>
                        {shipToHomeItemCount}
                      </TableCell>
                      <TableCell align="center" className={classes.totals}>
                        {shipToSchoolItemCount}
                      </TableCell>
                      <TableCell className={classes.totals} align="center">
                        {totalItemCount}
                      </TableCell>
                      <TableCell />
                      <TableCell align="center" className={classes.totals}>
                        {formatMoney(productTotal)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} className={classes.table}>
                <Table size="small">
                  <caption>fulfillment charges</caption>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="center">Order Count</TableCell>
                      <TableCell>Per Order Amount</TableCell>
                      <TableCell align="center">Orders Charge</TableCell>
                      <TableCell align="center">Item Count</TableCell>
                      <TableCell>Per Item Amount</TableCell>
                      <TableCell align="center">Items Charge</TableCell>
                      <TableCell align="center">Shipping Costs</TableCell>
                      <TableCell>Shipping Charge</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Ship To Home</TableCell>
                      <TableCell align="center">
                        {shipToHomeOrderCount}
                      </TableCell>
                      <TableCell>
                        <Input
                          name="ship_to_home_per_order_charge"
                          type="number"
                          isMoney
                          disabled={disableEdit}
                          validate={twoDecimalsOnly}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {multiplyAndFormat(
                          shipToHomeOrderCount,
                          ship_to_home_per_order_charge,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {shipToHomeItemCount}
                      </TableCell>
                      <TableCell>
                        <Input
                          name="ship_to_home_per_item_charge"
                          type="number"
                          isMoney
                          disabled={disableEdit}
                          validate={twoDecimalsOnly}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {multiplyAndFormat(
                          shipToHomeItemCount,
                          ship_to_home_per_item_charge,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {formatMoney(totalActualShipToHomeCost)}
                      </TableCell>
                      <TableCell>
                        <Input
                          name="ship_to_home_shipping_charge"
                          type="number"
                          isMoney
                          disabled={disableEdit}
                          validate={twoDecimalsOnly}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ship To School</TableCell>
                      <TableCell align="center">
                        {shipToSchoolStudentOrdersCount}
                      </TableCell>
                      <TableCell>
                        <Input
                          name="ship_to_school_per_student_order_charge"
                          type="number"
                          isMoney
                          disabled={disableEdit}
                          validate={twoDecimalsOnly}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {multiplyAndFormat(
                          shipToSchoolStudentOrdersCount,
                          ship_to_school_per_student_order_charge,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {shipToSchoolItemCount}
                      </TableCell>
                      <TableCell>
                        <Input
                          name="ship_to_school_per_item_charge"
                          type="number"
                          isMoney
                          disabled={disableEdit}
                          validate={twoDecimalsOnly}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {multiplyAndFormat(
                          shipToSchoolItemCount,
                          ship_to_school_per_item_charge,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {formatMoney(ship_to_school_shipping_cost)}
                      </TableCell>
                      <TableCell>
                        <Input
                          name="ship_to_school_shipping_charge"
                          type="number"
                          isMoney
                          disabled={disableEdit}
                          validate={twoDecimalsOnly}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} className={classes.table}>
                <FieldArray name="wholesale_invoice_adjustments">
                  {({ fields }) => (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={3}>CUSTOM ADJUSTMENTS</TableCell>
                          <TableCell>
                            <Button
                              color="primary"
                              onClick={() => fields.push({ is_credit: false })}
                              startIcon={<AddIcon />}
                              disabled={disableEdit}
                            >
                              add
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {fields.map((row, index) => {
                          const isCredit = fields.value[index].is_credit;
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <FormControlLabel
                                  control={
                                    <Field
                                      component={Checkbox}
                                      name={`${row}.is_credit`}
                                      type="checkbox"
                                      disabled={disableEdit}
                                    />
                                  }
                                  label="Is Credit"
                                />
                              </TableCell>
                              <TableCell>
                                <Input
                                  name={`${row}.memo`}
                                  label="Memo"
                                  validate={required}
                                  disabled={disableEdit}
                                />
                              </TableCell>
                              <TableCell>
                                <Field
                                  variant="standard"
                                  component={TextField}
                                  name={`${row}.amount`}
                                  label="Amount"
                                  type="number"
                                  validate={composeValidators(
                                    required,
                                    twoDecimalsOnly,
                                  )}
                                  disabled={disableEdit}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {`$ ${isCredit ? "-" : "+"}`}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => fields.remove(index)}
                                  size="small"
                                  disabled={disableEdit}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </FieldArray>
              </TableContainer>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting || disableEdit}
              >
                save changes
              </Button>

              <TableContainer component={Paper} className={classes.table}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        TOTALS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Product Charges</TableCell>
                      <TableCell align="center">
                        {formatMoney(productTotal)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Ship To Home Orders Charge</TableCell>
                      <TableCell align="center">
                        {multiplyAndFormat(
                          shipToHomeOrderCount,
                          ship_to_home_per_order_charge,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ship To Home Items Charge</TableCell>
                      <TableCell align="center">
                        {multiplyAndFormat(
                          shipToHomeItemCount,
                          ship_to_home_per_item_charge,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ship To Home Shipping Charge</TableCell>
                      <TableCell align="center">
                        {formatMoney(ship_to_home_shipping_charge)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ship To School Orders Charge</TableCell>
                      <TableCell align="center">
                        {multiplyAndFormat(
                          shipToSchoolStudentOrdersCount,
                          ship_to_school_per_student_order_charge,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ship To School Items Charge</TableCell>
                      <TableCell align="center">
                        {multiplyAndFormat(
                          shipToSchoolItemCount,
                          ship_to_school_per_item_charge,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ship To School Shipping Charge</TableCell>
                      <TableCell align="center">
                        {formatMoney(ship_to_school_shipping_charge)}
                      </TableCell>
                    </TableRow>

                    {Array.isArray(wholesale_invoice_adjustments) &&
                      wholesale_invoice_adjustments.map((a, index) => {
                        const { is_credit, amount, memo } = a;
                        if (!amount || !memo) return null;
                        return (
                          <TableRow key={index}>
                            <TableCell>{memo}</TableCell>
                            <TableCell align="center">
                              {formatMoney(amount, is_credit)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    <TableRow>
                      <TableCell>Grand Total</TableCell>
                      <TableCell align="center">
                        {formatMoney(grandTotal)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <div className={classes.editContainer}>
                {(isInvoiceSent || isPaid) && disableEdit && (
                  <Button
                    variant="contained"
                    onClick={handleEditAfterStatusChange}
                    className={classes.editButton}
                  >
                    edit
                  </Button>
                )}
              </div>
            </form>
          );
        }}
      />
    </Fragment>
  );
}

function ProductChangeListener({ setProductTotal }) {
  const form = useForm();
  const { products } = form.getState().values;
  return (
    <OnChange name="products">
      {() => {
        const total = products.reduce((ttl, curr) => {
          const { wholesale_price, qty } = curr;
          return ttl + Number(wholesale_price) * Number(qty);
        }, 0);
        setProductTotal(total);
      }}
    </OnChange>
  );
}

function CustomAdjustmentsListener({ setCustomAdjustmentTotal }) {
  const form = useForm();
  const { wholesale_invoice_adjustments: adjustments } = form.getState().values;
  return (
    <OnChange name="wholesale_invoice_adjustments">
      {() => {
        if (!Array.isArray(adjustments)) return;
        const total = adjustments.reduce((ttl, curr) => {
          const { is_credit, amount, memo } = curr;
          if (!amount || !memo) return ttl;
          let useAmt;
          if (is_credit) useAmt = Number(-amount);
          else useAmt = Number(amount);
          return ttl + useAmt;
        }, 0);
        setCustomAdjustmentTotal(total);
      }}
    </OnChange>
  );
}

function FulfillmentChargeListener({ setFulfillmentChargeTotal }) {
  const form = useForm();
  const {
    shipToHomeOrderCount,
    shipToHomeItemCount,
    ship_to_home_per_order_charge,
    ship_to_home_per_item_charge,
    ship_to_home_shipping_charge,
    shipToSchoolStudentOrdersCount,
    shipToSchoolItemCount,
    ship_to_school_per_student_order_charge,
    ship_to_school_per_item_charge,
    ship_to_school_shipping_charge,
  } = form.getState().values;
  const fulfillmentChargeFields = [
    "ship_to_home_per_order_charge",
    "ship_to_home_per_item_charge",
    "ship_to_home_shipping_charge",
    "ship_to_school_per_student_order_charge",
    "ship_to_school_per_item_charge",
    "ship_to_school_shipping_charge",
  ];
  return (
    <Fragment>
      {fulfillmentChargeFields.map(field => (
        <OnChange key={field} name={field}>
          {() => {
            const total =
              Number(shipToHomeOrderCount) *
                Number(ship_to_home_per_order_charge) +
              Number(shipToHomeItemCount) *
                Number(ship_to_home_per_item_charge) +
              Number(shipToSchoolStudentOrdersCount) *
                Number(ship_to_school_per_student_order_charge) +
              Number(shipToSchoolItemCount) *
                Number(ship_to_school_per_item_charge) +
              Number(ship_to_home_shipping_charge) +
              Number(ship_to_school_shipping_charge);
            setFulfillmentChargeTotal(total);
          }}
        </OnChange>
      ))}
    </Fragment>
  );
}

function multiplyAndFormat(num1 = 0, num2 = 0) {
  return formatMoney(Number(num1) * Number(num2));
}

const styles = makeStyles(
  _theme => ({
    top: {
      display: "flex",
      justifyContent: "space-between",
    },
    topMsg: {
      fontSize: 16,
      marginTop: 6,
      color: "red",
    },
    table: {
      margin: "24px 0",
      width: "fit-content",
      minWidth: 300,
    },
    generalInfo: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    status: {
      textTransform: "uppercase",
    },
    actionDate: {
      marginTop: 6,
    },
    editContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "24px",
    },
    editButton: {
      backgroundColor: "#FF0000",
      color: "#FFFFFF",
    },
    totals: {
      fontSize: 14,
      fontWeight: 600,
    },
  }),
  { classNamePrefix: "wholesaleInvoice" },
);
