import { makeStyles, IconButton, Button, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";
import { ButtonSpinner, ResponsiveModal } from "../../../../components";
import { useIsMobile, useDrawerTransition } from "../../../../hooks/ui";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getCampaignId, getIsLoadingDownload } from "../../../../state";
import { fetchAndDownloadCsv } from "../../../../exports/fetchAndDownloadMethods";

type Props = {
  onClose: () => void;
};

export function CampaignVolunteerListContactsDateFilteredDownload({
  onClose: _onClose,
}: Props) {
  const classes = styles();
  const isMobile = useIsMobile();
  const campaignId = useSelector(getCampaignId);
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const isLoading = useSelector(getIsLoadingDownload);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const download = async () => {
    const start_date = startDate ? startDate : "";
    const end_date = endDate ? endDate : "";
    const queryStr = `start_date=${start_date}&end_date=${end_date}&campaign_id=${campaignId}`;
    const url = `/volunteers/campaign_volunteer_list_download?${queryStr}`;
    const fileName = getFileName(start_date, end_date, campaignId);
    await fetchAndDownloadCsv(url, fileName, onClose);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={() => {}}>
      <div className={classes.container}>
        <div className={classes.top}>
          {isMobile && (
            <div className={classes.close}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <div className={classes.header}>Contacts report</div>
        </div>

        <div className={classes.content}>
          <div className={classes.section}>
            <div className={classes.label}>
              Filter contacts count by contacts created in this date range
              (optional).
            </div>
            <div className={classes.inputs}>
              <TextField
                label="Start Date"
                type="datetime-local"
                value={startDate}
                InputLabelProps={{ shrink: true }}
                onChange={e => setStartDate(e.target.value)}
                className={classes.leftInput}
              />
              <TextField
                label="End Date"
                type="datetime-local"
                value={endDate}
                InputLabelProps={{ shrink: true }}
                onChange={e => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={classes.bottom}>
          <Button
            variant="text"
            color={isMobile ? "primary" : "default"}
            onClick={onClose}
            disabled={isLoading}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            className={classes.actionBtn}
            disabled={isLoading}
            onClick={download}
          >
            Download
            <ButtonSpinner show={isLoading} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

function getFileName(startDate: any, endDate: any, campaignId: any) {
  let fileName = `Contacts - Campaign #${campaignId}`;
  const start = startDate
    ? format(new Date(startDate), "MMMM d yyyy h_mma")
    : "";
  const end = endDate ? format(new Date(endDate), "MMMM d yyyy h_mma") : "";
  switch (true) {
    case Boolean(startDate && endDate):
      fileName = `Contacts - ${start} thru ${end} - Campaign #${campaignId}`;
      break;
    case Boolean(startDate):
      fileName = `Contacts - from ${start} - Campaign #${campaignId}`;
      break;
    case Boolean(endDate):
      fileName = `Contacts - thru ${end} - Campaign #${campaignId}`;
      break;
  }
  return fileName;
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  top: {
    width: "100%",
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    borderBottom: "1px solid #DBDEEE",
  },
  close: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 12,
    paddingBottom: 2,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    padding: "24px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 0,
    },
  },
  content: {
    flex: 1,
    padding: "24px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 0 16px",
    },
  },
  section: {
    marginBottom: 24,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: 18,
    lineHeight: "22px",
    textAlign: "center",
  },
  inputs: {
    paddingTop: 8,
    display: "flex",
  },
  leftInput: {
    marginRight: 16,
  },
  bottom: {
    width: "100%",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    padding: 24,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      padding: "16px 16px 8px 16px",
      alignItems: "center",
    },
  },
  actionBtn: {
    minWidth: 120,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      marginLeft: 0,
      marginBottom: 8,
    },
  },
}));
