import {
  IconButton,
  makeStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { APIRes, GenericObject } from "../../../types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ChangeEvent, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignId,
  getOrgVolunteerAutocomplete,
  getVolunteerLabel,
  orgVolunteerAutocomplete,
  setToast,
} from "../../../state";
import { APIAuthClient } from "../../../lib";
const MIN_2_WORDS = /(\w+\s+[^-])+\S+/;

type Props = {
  donationId: number;
  currentVolunteerId?: null | number;
  refreshList: () => any;
};
export function UpdateVolunteer({
  donationId,
  currentVolunteerId,
  refreshList,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const campaignId = useSelector(getCampaignId);
  const volunteers = useSelector(getOrgVolunteerAutocomplete);
  const { volunteerLabelLCSingular, volunteerLabelSing } = useSelector(
    getVolunteerLabel,
  );
  const [volunteerName, setVolunteerName] = useState("");
  const [volunteerId, setVolunteerId] = useState<null | string>(null);
  const [showActions, setShowActions] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setCurrVolunteer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVolunteerId, volunteers]);

  const setCurrVolunteer = () => {
    if (!currentVolunteerId) {
      setVolunteerName("");
      setVolunteerId(null);
      setNotFound(false);
      return;
    }
    const v = volunteers.find(({ id }) => id === currentVolunteerId);
    if (v) {
      const { id, name } = v;
      setVolunteerName(name as string);
      setVolunteerId(`${id}`);
      setNotFound(false);
    } else {
      // if for some reason the volunteer on the sale is not in the current list (which shouldn't happen) - show an error
      if (volunteers.length) {
        setNotFound(true);
      } else {
        setVolunteerName("");
        setVolunteerId(null);
        setNotFound(false);
      }
    }
  };

  const updateShowActions = (newValue: any) => {
    if (
      (newValue?.id && currentVolunteerId === newValue?.id) ||
      (!currentVolunteerId && !newValue)
    ) {
      setShowActions(false);
    } else {
      setShowActions(true);
    }
  };

  const onSelect = (e: ChangeEvent<any>, newValue: any) => {
    if (e) e.stopPropagation();
    updateShowActions(newValue);

    if (!newValue) {
      setVolunteerName("");
      setVolunteerId(null);
    } else if (typeof newValue === "string") {
      setVolunteerName(newValue);
      setVolunteerId(null);
    } else {
      setVolunteerName(newValue.name);
      setVolunteerId(`${newValue.id}`);
    }
  };

  const onInputChange = (
    e: ChangeEvent<any>,
    newValue: string,
    action: string,
  ) => {
    if (e) e.stopPropagation();
    if (action === "reset") return;
    updateShowActions(newValue);
    setVolunteerName(newValue);
  };

  const onCancel = (e: ChangeEvent<any>) => {
    e.stopPropagation();
    setCurrVolunteer();
    setShowActions(false);
  };

  const onSave = async (e: ChangeEvent<any>) => {
    e.stopPropagation();
    setSubmitting(true);
    if (
      !volunteerId &&
      volunteerName &&
      !MIN_2_WORDS.test(volunteerName.trim())
    ) {
      dispatch(
        setToast(`${volunteerLabelSing} must have a first and last name`),
      );
      setSubmitting(false);
      return;
    }

    const data: GenericObject = {
      campaignId,
      volunteerId: Number(volunteerId),
      volunteerName,
    };
    if (!volunteerId && !volunteerName) {
      data.remove = true;
      data.volunteerName = null;
    }
    const res = await APIAuthClient.put<any, APIRes>(
      `/donations/${donationId}/update-volunteer`,
      data,
    );
    setSubmitting(false);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return;
    }
    refreshList();
    if (!volunteerId) dispatch(orgVolunteerAutocomplete());
  };

  if (notFound) {
    return (
      <div className={classes.error}>
        Refresh to view {volunteerLabelLCSingular}
      </div>
    );
  }
  return (
    <div className={classes.container} onClick={e => e.stopPropagation()}>
      <Autocomplete
        className={classNames(
          classes.input,
          (showActions || submitting) && classes.narrowInput,
        )}
        inputValue={volunteerName}
        value={volunteerId}
        freeSolo
        selectOnFocus
        onInputChange={onInputChange}
        onChange={onSelect}
        options={volunteers}
        forcePopupIcon
        onClick={e => e.stopPropagation()}
        getOptionSelected={(o: any, v: any) => {
          if (!v) return false;
          if (typeof v === "string") return `${o.id}` === v;
          return o.id === v.id;
        }}
        getOptionLabel={(option: any) => option.name || option}
        disabled={submitting}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={`Search or add ${volunteerLabelLCSingular}`}
            fullWidth
            onClick={e => e.stopPropagation()}
            disabled={submitting}
          />
        )}
      />
      {showActions && !submitting && (
        <IconButton
          size="small"
          className={classes.cancel}
          onClick={onCancel}
          disabled={submitting}
        >
          <CloseIcon className={classes.icon} fontSize="small" />
        </IconButton>
      )}
      {showActions && !submitting && (
        <IconButton
          size="small"
          className={classes.save}
          onClick={onSave}
          disabled={submitting}
        >
          <CheckIcon className={classes.icon} fontSize="small" />
        </IconButton>
      )}
      {submitting && <CircularProgress color="primary" size={22} />}
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: 270,
    justifyContent: "space-between",
    height: "100%",
    minHeight: 52,
    marginTop: -6,
    marginBottom: -6,
    marginRight: -18,
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 18,
    cursor: "auto",
  },
  input: {
    width: 270,
    backgroundColor: "#FFFFFF",
    "& *": {
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
      },
    },
  },
  narrowInput: {
    width: 190,
  },
  cancel: {
    color: "#ED2A2A",

    "&:hover": {
      backgroundColor: "#FFFFFF !important",
    },
  },
  save: {
    color: "#1AC846",
    "&:hover": {
      backgroundColor: "#FFFFFF !important",
    },
  },
  icon: {
    fontSize: 20,
    "&:hover": {
      backgroundColor: "#FFFFFF !important",
    },
  },
  error: {
    color: "#ED2A2A",
  },
}));
